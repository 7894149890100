import { React, useState,useEffect, useCallback } from "react";
import { useNavigate} from "react-router-dom";
import Navbars from "../navbar";
import leftarrow from '../images/arrow-left-01.png';
import Select, { components } from 'react-select';
import eye from '../images/eye-01.png';
import eyegrey from '../images/eye-01pass.png';
import eyeslashgrey from '../images/eye-slash-01passw.png';
import eyeslash from '../images/eye_slash-01.png';
import { profileupdateotpapi, profileupdateauthapi,passwordverifyapi} from "../api/helper/urlhelper";
import axiosInstance from "../api/utils/axiosutils";
import { useCountryData } from "../api/hooks/mobilehook";
import { countrystyle,extractLoginUser ,handleApiError, handleInputChangeotp, handlePaste, selectWidth, useFetchUserProfile, validateAndSetMobileChange} from "../api/utils/utils";
import WithToast from "../api/hooks/withtoasthook";
import useMenuHeight from "../api/hooks/useMenuHeight";
const profileupdateotpapiurl = profileupdateotpapi();
const profileupdateauthapiurl = profileupdateauthapi();
const passwordverifyapiurl = passwordverifyapi();
function Mobilenumberchange({showErrorToast}) {
  const login_user = extractLoginUser();
  const {userProfile,fetchProfile} = useFetchUserProfile();
  useEffect(()=>{
    fetchProfile();
  },[fetchProfile]);
  const {
    mccId, dialCode, countryCode, countryOptions,mobile_length,mobile_starting_digits,isLoading,setMccId,setDialCode,setCountryCode,setMobile_length,setMobile_starting_digits,
    loadCountryByIp,empty_values
  } = useCountryData();
  useEffect(()=>{
      const path = window.location.pathname;
      const split_path = path.split('/');
      if(split_path[5] === 'mobilenumberchange'){
        empty_values()
      } 
    },[]);
  const [isSaveButtonEnabled, setIsSaveButtonEnabled] = useState(true);
  const [isSaveButtonEnablednext, setIsSaveButtonEnablednext] = useState(false);
  const [isSaveButtonEnablednextotp, setIsSaveButtonEnablednextotp] = useState(false);
  const [showmobileedits, setShowmobileedits] = useState(false);
  const [mobileChange, setMobileChange] = useState('');
  const [error422, setError422] = useState('');
  const [numberinvalid,setNumberinvalid] = useState(false);
  const [isLoading4, setIsLoading4] = useState(false);
  const [showmobileotppage, setShowmobileotppage] = useState(false);
  const [showemailpasswordedit , setShowmobilepasswordedit] = useState(true);
  const [otpchange, setOtpchange] = useState("");
  const [password , setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
const handlePhoneNumberChange = (value) => {
  validateAndSetMobileChange(value, {
    setMobileChange: setMobileChange,
    setError: setError422,
    setNumberInvalid: setNumberinvalid,
    mobile_length: mobile_length,             
    mobile_starting_digits: mobile_starting_digits 
  });
};

const handlePaste1 = (e) => {
    const pastedText = e.clipboardData.getData('text');
    let numericPastedText = pastedText.replace(/[^0-9]/g, '');
    const dialCodeWithoutPlus = dialCode.replace('+', '');
    if (pastedText.startsWith('+') && numericPastedText.startsWith(dialCodeWithoutPlus)) {
        numericPastedText = numericPastedText.slice(dialCodeWithoutPlus.length);
    }
    validateAndSetMobileChange(numericPastedText, {
      setMobileChange: setMobileChange,
      setError: setError422,
      setNumberInvalid: setNumberinvalid,
      mobile_length: mobile_length,             
      mobile_starting_digits: mobile_starting_digits 
    });
    e.preventDefault();
};
const handleCountryChange = (selectedOption) => {
    const selectedCountryData = countryOptions.find(
        (country) => country.value === selectedOption.value
    );
    if (selectedCountryData) {
      const { mcc_id, dial_code, country_code, mobile_length, mobile_starting_digits } = selectedCountryData;
  
      setMccId(mcc_id);
      setDialCode(`+${dial_code}`);
      setCountryCode(country_code);
      setMobile_length(mobile_length);
      setMobile_starting_digits(mobile_starting_digits);
  
      // Apply validation to existing mobile number
      validateAndSetMobileChange(mobileChange, {
        setMobileChange,
        setError: setError422,
        setNumberInvalid: setNumberinvalid,
        mobile_length,
        mobile_starting_digits,
      });
    }
};


  const customSingleValue = ({ data }) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <img
        src={data.flag}
        alt=""
        style={{ width: '25px', height: 'auto', marginLeft: '10px' }}
      />
      {/* {data.label} */}
    </div>
  );

  const customOption = (props) => {
    return (
      <components.Option {...props}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img
            src={props.data.flag}
            alt=""
            style={{ width: '25px', height: 'auto', marginRight: '10px' }}
          />
          {props.data.label}
        </div>
      </components.Option>
    );
  };
  const back = () => {
    navigate(`/u/${login_user}/profile`);
  }
  const cancel = () => {
    navigate(`/u/${login_user}/profile`);
  }
  const check = useCallback(() => {
    const valid = (
      password !== ''
    )
    setIsSaveButtonEnabled(valid);
  },[password]);
  useEffect(()=>{
    check();
  },[check])

const toggleVisibility = () => {
  setShowPassword(!showPassword);
}
const goBack = () => {
  navigate(`/u/${login_user}/profile`);
}
const cancelPasswordChange = () => {
  navigate(`/u/${login_user}/profile`);
}

const goToNextStep = async() => {
  const data = {
    password : password,
  }
  try{
    setIsLoading4(true);
  const response = await axiosInstance.post(passwordverifyapiurl,data);
  if (response.status === 200) {
    const password_verify = response.data.data.password_verify;
    localStorage.setItem('password_verify', password_verify); 
    await loadCountryByIp(true);
    setIsLoading4(false);
    setError422('');
    setShowmobilepasswordedit(false);
    setShowmobileedits(true);
    setShowmobileotppage(false);
}
  }
  catch(error){
    handleApiError(error, setIsLoading4, setError422, false, showErrorToast);
  }
} 


const handleKeyPress = (e) => {
  // Allow only numeric characters (0-9) and some specific control keys
  const allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Home', 'End'];

  if (!/^\d$/.test(e.key) && !allowedKeys.includes(e.key)) {
    e.preventDefault();
  }
};
const checks = useCallback(() => {
  const valid = (
    mobileChange !== '' && numberinvalid === true
  );
  setIsSaveButtonEnablednext(valid);
 },[mobileChange,numberinvalid]);
 useEffect(()=>{
  checks();
 },[checks]);
  const next = async () => {
    // if(mobileChange.length < 9 || mobileChange.length > 15) {
    //   setError422('The mobile must be between 9 and 15 digits')
    //   return false;
    // }else{
    //   setError422('')
    // }
    const password_verify = localStorage.getItem('password_verify');
    const data = {
      mobile: mobileChange,
      mcc_id : mccId,
      password_verify_token : password_verify ,
      verify: 'mobile'
    }
    try {
      setIsLoading4(true);
      const response = await axiosInstance.post(profileupdateotpapiurl, data);
      if (response.status === 200) {
        const otptoken = response.data.data.token;
        localStorage.setItem('otp_token', otptoken);
        setIsLoading4(false);
        setError422('');
        setShowmobileedits(false);
        setShowmobileotppage(true);
        // fetchCountryCode2();
      }
    }
    catch (error) {
      handleApiError(error, setIsLoading4, setError422, false, showErrorToast);
    }
  }
  const checkotp = useCallback(() => {
    const valid = (
      otpchange !== ''
    );
    setIsSaveButtonEnablednextotp(valid);
  },[otpchange]);
  useEffect(()=>{
    checkotp();
  },[checkotp]);

  const handleChange = (e) => {
    const value = e.target.value;
    setError422('');
    setOtpchange(value);
  };
  const onPaste1 = (e) => {
    handlePaste(e, setOtpchange); 
    setError422('');
  };
  const save = async() => {
    const password_verify = localStorage.getItem('password_verify');
  if (!otpchange.trim()) {
    return;
  }
  const otptoken = localStorage.getItem('otp_token')
  const data = JSON.stringify({
    otp : otpchange,
    token : otptoken,
    password_verify_token : password_verify,
  })
  try{
    setIsLoading4(true);
  const response = await axiosInstance.post(profileupdateauthapiurl,data);
  if (response.status === 200) {
    setIsLoading4(false);
    setError422('');
    navigate(`/u/${login_user}/profile`);
}    
  }
  catch(error){
    if(error.response){
       if(error.response.status === 422){
        const error_code = error.response.data.code;
        if (error_code === 'otp_retry') {
          navigate(`/u/${login_user}/profile`);
          setIsLoading4(false);
        }else{
          handleApiError(error, setIsLoading4, setError422, false, showErrorToast);
        }
       } else{
        handleApiError(error, setIsLoading4, setError422, false, showErrorToast);
       }
    }else{
    handleApiError(error, setIsLoading4, setError422, false, showErrorToast);
    }
    }  
  }
  const [selwidth, setSelWidth] = useState(selectWidth(dialCode));
  const menuHeight = useMenuHeight(200, 400);

  // Update the selection width whenever dialCode changes
  useEffect(() => {
    setSelWidth(selectWidth(dialCode));
  }, [dialCode]);

  return (
    <>
    {isLoading4 && (
        <>
          <div className="loaderover">
            <div className="loader "></div>
          </div>
        </>
      )}
       <div>
        <div className="container-fluid" style={{ height: '100px' }}>
          <Navbars />
        </div>
        <div className="name_div">
        {showemailpasswordedit && (
         <>
          <div className="name_div1">
            <div className="d-flex align-items-center">
              <img src={leftarrow} onClick={goBack} alt="leftarrow" className="name_arrow "></img>
              <h5 className="nameh5">Edit Mobile</h5>
            </div>
            <div className="name-input name-inputimage">
            <p style={{ fontWeight: '500', fontSize: '16px', lineHeight: '30px', color: '#292929' }}>To modify Mobile, Kindly enter your password</p>
            <div className="header-profile-user border-dark user mx-auto " style={{ width: '80px', height: '80px', borderRadius: '50%', overflow: 'hidden' }}>
            <img id="login_img_for_profile" src={userProfile?.image} alt="Profile" loading="lazy"className=" homeprofile mb-3" style={{ width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center center' }}/></div>
            <p style={{ fontWeight: '500', fontSize: '20px', lineHeight: '30px', color: '#292929' }}>{userProfile?.name}</p>
            <div className="text-start" style={{width:'100%'}}>
                <label htmlFor="password" className="mb-2" style={{ fontWeight: '500', fontSize: '16px', lineHeight: '24px', color: '#000000' }}>Current Password</label>
              <div className="password " style={{ position: 'relative' }}>
                <input type={showPassword ? 'text' : 'password'} value={password} onChange={(e)=>{setPassword(e.target.value);setError422('')}} className={`form-control docform ${password ? 'docform-active' : ''}`}  id="userpassword" placeholder="Enter Your Current Password" name="password"/>
                <p className="toggle-password" onClick={toggleVisibility} style={{ position: 'absolute', marginRight: '15px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}>
                  {showPassword ? (
                    password ? (
                    <img src={eye} className='eye' width='19.5px' height='19.5px' alt="Show Password"/>
                    ) : (
                      <img src={eyegrey} className='eye' width='19.5px' height='19.5px' alt="Show Password"/>
                    )
                  ) : (
                    password ? (
                    <img src={eyeslash} className='eye' width='19.5px' height='19.5px' alt="Hide Password" />
                    ) : (
                      <img src={eyeslashgrey} className='eye' width='19.5px' height='19.5px' alt="Hide Password" />
                    )
                  )}
                </p>
              </div>
              </div>
              <div className="error-message error-messageprofile">{error422}</div>
              <div className="name_button_div" style={{width:'100%'}}>
                <button type="button" onClick={cancelPasswordChange} className="profilecancel me-4">
                  Cancel
                </button>
                <button type="button" onClick={goToNextStep} style={{ backgroundColor: isSaveButtonEnabled ? '#02CD8D' : '#EAEAEA', color: isSaveButtonEnabled ? '#FFF' : '#333' , cursor: isSaveButtonEnabled ? 'pointer' : 'not-allowed' }} disabled={!isSaveButtonEnabled} className={`profilesave`}>
                  Next
                </button>
              </div>
            </div>
            </div>
            </>
            )}
            {showmobileedits && (
            <>
             <div className="name_div1">
            <div className="d-flex align-items-center">
              <img src={leftarrow} onClick={goBack} alt="leftarrow" className="name_arrow "></img>
              <h5 className="nameh5">Change Mobile</h5>
            </div>
            <div className="name-input name-inputimage name-inputimageemail ">
            <div >
            <label
        htmlFor="inputField"
        className="form-label"
        style={{
          fontWeight: '500',
          fontSize: '16px',
          lineHeight: '24px',
          color: '#000000',
        }}
      >
        Mobile Number
      </label>
      <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
        <div style={{ position: 'absolute', left: 0, zIndex: 1 }}>
          <Select
            options={countryOptions}
            onChange={handleCountryChange}
            value={countryOptions.find(option => option.value === countryCode)}
            components={{ SingleValue: customSingleValue, Option: customOption }}
            placeholder=""
            isLoading={isLoading} // Pass the loading state to Select
            styles={countrystyle('82px',menuHeight)}
          />
        </div>
        <div style={{ height: '30px', width: '2px', backgroundColor: '#ccc', margin: '0 8px', position: 'absolute', left: `${selwidth}px`, top: '50%', transform: 'translateY(-50%)' }}></div>
        <p style={{ position: 'absolute', left:'78px', top: '51%', transform: 'translateY(-50%)', cursor: 'pointer',color:'hsl(0, 0%, 20%)',fontSize:'14px',lineHeight:'1.5',marginBottom:'0px',fontWeight:'400' }}>{dialCode}</p>
        <input
          type="text"
          className={`form-control docform ${mobileChange ? 'docform-active' : ''}`}
          id="mobile"
          style={{ paddingLeft:`${selwidth + 18}px`, width: '100%' }}
          placeholder="Mobile Number"
          value={mobileChange}
          name="mobile"
          onPaste={(e) => handlePaste1(e)}
          onKeyPress={handleKeyPress}
          onChange={(e) => handlePhoneNumberChange(e.target.value)}
        />
      </div>
      </div>
                <div className="error-message error-messageprofile">{error422}</div>
                <div className="name_button_div">
                <button type="button" onClick={cancel} className="me-3  profilecancel">
                  Cancel
                </button>
                <button type="button" onClick={next} style={{ backgroundColor: isSaveButtonEnablednext ? '#02CD8D' : '#EAEAEA', color: isSaveButtonEnablednext ? '#FFF' : '#333' , cursor: isSaveButtonEnablednext ? 'pointer' : 'not-allowed' }} disabled={!isSaveButtonEnablednext} className={`profilesave`}>
                  Next
                </button>
              </div>
                </div>
            </div>
            </>
            )}
            {showmobileotppage && (
              <>
                <div className="name_div1">
            <div className="d-flex align-items-center">
              <img src={leftarrow} onClick={back} alt="leftarrow" className="name_arrow "></img>
              <h5 className="nameh5">Verify Mobile</h5>
            </div>
            <div className="name-input name-inputimage name-inputimageemail ">
            <div>
                  <label htmlFor="inputField"  className="form-label" style={{fontWeight:'500',fontSize:'16px',lineHeight:'24px',color:'#000000'}}>Verification Code</label>
                  <input type="text"  value={otpchange} onChange={handleChange} maxLength={6} onPaste={onPaste1} onKeyPress={handleInputChangeotp} className={`form-control docform ${otpchange ? 'docform-active' : ''}`}  id="inputField" placeholder="Enter Verification Code" />
                </div>
                <div className="error-message error-messageprofile">{error422}</div>
                <div className="name_button_div">
                <button type="button" onClick={cancel} className="me-3  profilecancel">
                  Cancel
                </button>
                <button type="button" onClick={save} style={{ backgroundColor: isSaveButtonEnablednextotp ? '#02CD8D' : '#EAEAEA', color: isSaveButtonEnablednextotp ? '#FFF' : '#333' , cursor: isSaveButtonEnablednextotp ? 'pointer' : 'not-allowed' }} disabled={!isSaveButtonEnablednextotp} className={`profilesave`}>
                  Save
                </button>
              </div>
              </div>
            </div>
              </>
             )}
            </div>
            </div>
    </>
  );
}
export default WithToast(Mobilenumberchange);