import transparent from '../../images/11-01.jpg';
import { toast } from "react-toastify";
import { accountsdomain } from '../url';
import dayjs from 'dayjs';
const accountsdomainurl = accountsdomain();
export const Error401 = () => {
  const lod4 = extractLoginUser();
  window.location.href = `/refresh?X-Authuser=${encodeURIComponent(lod4)}`;
  return lod4;
}

// maskedEmailUtil.js
export const getMaskedEmail = (email) => {
    if (!email) {
      return null;
    }
    const [username, domain] = email.split('@');
    const maskedUsername = username.charAt(0) + '*'.repeat(username.length - 1);  
    return `${maskedUsername}@${domain}`;
  };

 export const getMaskedPhoneNumber = (phoneNumber) => {
    if (!phoneNumber) {
      return null;
    }
  
    const lastTwoDigits = phoneNumber.slice(-2);
  
    return `**********${lastTwoDigits}`;
  };
  
  // dateUtils.js
export const formatDateForInput = (date) => {
  if (!date) return '';

  const dateObject = new Date(date);
  const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
  const year = dateObject.getFullYear();
  return `${year}-${month}-01`;
};

export const formatDateForExpiry = (date) => {
  if (!date) return '';

  const dateObject = new Date(date);
  dateObject.setMonth(dateObject.getMonth() + 1, 0);

  const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
  const year = dateObject.getFullYear();
  const lastDayOfMonth = dateObject.getDate();

  return `${year}-${month}-${lastDayOfMonth}`;
};
// imageUtils.js

export const convertImageToBase64 = (file, setImage, setFileSize, setFileType) => {
  const reader = new FileReader();

  reader.onloadend = () => {
    setImage([reader.result]);
    setFileSize(file.size);
    const fileNameWithoutExtension = file.name.split('.').slice(0, -1).join('.');
    setFileType(fileNameWithoutExtension);
  };

  if (file) {
    reader.readAsDataURL(file);
  }
};

export const parseFileExtension = (fileName) => {
  const dotIndex = fileName.lastIndexOf('.');
  return dotIndex !== -1 ? fileName.slice(dotIndex) : '';
};

export const formatFileSize = (sizeInBytes) => {
  const sizeInKB = sizeInBytes / 1024;
  const sizeInMB = sizeInKB / 1024;

  if (sizeInMB >= 1) {
    return `${sizeInMB.toFixed(2)} MB`;
  } else {
    return `${sizeInKB.toFixed(2)} KB`;
  }
};
export const handleFileChange = (
  e,
  setImage,
  setFileSize,
  setFileType,
  setProgress,
  setProgressColor,
  setUploading,
  setFormat,
  setDocname
) => {
  const selectedFile = e.target.files[0];
  const fileName = selectedFile.name;
  const fileFormat = fileName.substring(fileName.lastIndexOf('.') + 1);
  setFormat(fileFormat);
  const fileNameWithoutExtension = fileName.replace(/\.[^/.]+$/, "");
  const updatedDocname = `${fileNameWithoutExtension}`;
  setDocname(updatedDocname);
  convertImageToBase64(
    selectedFile,
    (image) => {
      // Callback function for setting the image state
      setImage(image);
    },
    (size) => setFileSize(size), // Callback function for setting file size
    (type) => setFileType(type), // Callback function for setting file type
  );

  const totalSteps = 100;
  const processingSteps = 70;
  const stepInterval = 100;
  let currentStep = 0;
  setUploading(true);
  const progressInterval = setInterval(() => {
    currentStep += 50;
    const currentProgress = (currentStep / totalSteps) * 100;
    setProgress(currentProgress);
    if (currentStep === totalSteps) {
      clearInterval(progressInterval);
      setTimeout(() => {
        setProgressColor("#28a745");
        setUploading(true);
        // setEditImage(true);
      }, 500);
    } else if (currentStep === processingSteps) {
      setUploading(true);
    }
  }, stepInterval);
};

export const handleDragOver = (e) => {
  e.preventDefault();
};
export const handleDragOver1 = (e) => {
  e.preventDefault();
};

export const handleDrop = (e, handleFileDrop,handleError, acceptPDF = true) => {
  e.preventDefault();

  const droppedFiles = e.dataTransfer.files;
  if (droppedFiles.length > 0) {
    const selectedFile = droppedFiles[0];
    const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg'];
    
    if (acceptPDF) {
      allowedTypes.push('application/pdf');
    }

    if (allowedTypes.includes(selectedFile.type)) {
      handleFileDrop  (selectedFile);
    } else {
      handleError('Only JPG, JPEG, PNG' + (acceptPDF ? ', and PDF' : '') + ' files are allowed.');
    }
  }
};
export const handleDrop1 = (e, handleEditFileDrop, handleEditError, acceptPDF = true) => {
  e.preventDefault();

  const droppedFiles = e.dataTransfer.files;

  if (droppedFiles.length > 0) {
    const selectedFile = droppedFiles[0];
    const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg'];
    
    if (acceptPDF) {
      allowedTypes.push('application/pdf');
    }

    if (allowedTypes.includes(selectedFile.type)) {
      handleEditFileDrop  (selectedFile);
    } else {
      handleEditError('Only JPG, JPEG, PNG' + (acceptPDF ? ', and PDF' : '') + ' files are allowed.');
    }
  }
};
export const handleEditfilechange = (
  e,
  setEditImage,
  setFileSize,
  setFileType,
  setEditProgress,
  setEditProgressColor,
  setEditImageDetails,
  setFormat,
  setEditDocName
) => {
  const selectedFile1 = e.target.files[0];
  const fileName = selectedFile1.name;
  const fileFormat = fileName.substring(fileName.lastIndexOf('.') + 1);
  setFormat(`.${fileFormat}`);
  const fileNameWithoutExtension = fileName.replace(/\.[^/.]+$/, "");
  const updatedDocname = `${fileNameWithoutExtension}`;
  setEditDocName(updatedDocname);

  convertImageToBase64(
    selectedFile1,
    (image) => {
      setEditImage(image);
    },
    (size) => setFileSize(size),
    (type) => setFileType(type)
  );
  setEditProgressColor('#28a745');
    const totalSteps = 100;
    const processingSteps = 70;
    const stepInterval = 100;
    let currentStep = 0;
    setEditImageDetails(false);
    const progressInterval = setInterval(() => {
      currentStep += 50;
      const currentProgress = (currentStep / totalSteps) * 100;
      setEditProgress(currentProgress);
      if (currentStep === totalSteps) {
        clearInterval(progressInterval);
        setTimeout(() => {
          setEditProgressColor('#28a745');
          setEditImageDetails(false);
        }, 500);
      } else if (currentStep === processingSteps) {
        setEditImageDetails(false);
      }
    }, stepInterval);
};

export const truncateModel = (model) => {
  if (model && model.length) {
    return model.length > 12 ? model.substring(0, 15) + '...' : model;
  } else {
    return ''; // or any default value you want to return when model is null or undefined
  }
};
export const truncateModel1 = (model) => {
  if (model && model.length) {
    return model.length > 7 ? model.substring(0, 7) + '...' : model;
  } else {
    return ''; // or any default value you want to return when model is null or undefined
  }
};

export function removePrefix(originalString, prefixToRemove) {
  return originalString.replace(prefixToRemove, '');
}

export function getFormattedDate(dateString) {
  const date = new Date(dateString);
  const day = date.getDate();
  const month = date.getMonth() + 1; // Add 1 because months are 0-based
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
}
export function getImageBackgroundColor  (image)  {
  // Assuming image is an object with a property like "url" representing the image URL or data URI
  const imageUrl = image.url || '';

  if (imageUrl.toLowerCase().endsWith('.png')) {
    // Set background color for PNG images with transparency
    return 'transparent';
  } else {
    // Set a default background color for other image formats
    return `url(${transparent})`; // You can change this to any color you prefer
  }
};
export const removeallcookies = async () => {
  // Clear all cookies
  document.cookie.split(";").forEach(cookie => {
    document.cookie = cookie
      .replace(/^ +/, "")
      .replace(/=.*/, `=;expires=${new Date().toUTCString()};path=/`);
  });
}

export const extractLoginUser = () => {
  const routePath = window.location.pathname;
  const regex = /\/u\/([^/]+)/; // Match the last part after the last slash

  const match = routePath.match(regex);
  if (match) {
      return match[1]; // Return the matched value
  }
  return null; // Return null if no match is found
}


export const getQueryParameter = (paramName) => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  return urlParams.get(paramName);
}

export const mergeAllQueryParameters = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  // Extract and store the value of the 'redirecturl' parameter
  const redirectUrl = urlParams.get('redirecturl');

  // Create an array of entries, excluding the 'redirecturl' parameter
  const filteredParams = Array.from(urlParams.entries())
    .filter(([key]) => key !== 'redirecturl') // Filter out the 'redirecturl' parameter
    .map(([key, value]) => `${key}=${value}`) // Format the parameters
    .join('&'); // Join them with '&'
    
  const result = redirectUrl ? `${redirectUrl}${filteredParams}` : filteredParams;
  return result;
};

function redirectTo(url, params) {
  const queryString = Object.keys(params)
    .map(key => `${key}=${params[key]}`)
    .join('&');
  const redirectUrl = `${url}?${queryString}`;
  console.log('Redirecting to:', redirectUrl); 
  window.location.href = redirectUrl;
}

// Define the redirectToWithAuthUser function
export const redirectToWithAuthUser = (redirectUrl, X_AuthUser) => {
  const queryParams = {
    X_AuthUser: X_AuthUser
  };
  redirectTo(redirectUrl, queryParams);
}
export const handleKeyPress = (e) => {
  // Allow only numeric characters (0-9) and some specific control keys
  const allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Home', 'End'];

  if (!/^\d$/.test(e.key) && !allowedKeys.includes(e.key)) {
    e.preventDefault();
  }
};
// utils.js or helper.js (where you store utility functions)
export const handlePaste = (e, callback, maxDigits = 6) => {
  const pastedText = e.clipboardData.getData('text/plain');

  // Remove non-numeric characters
  const numericText = pastedText.replace(/\D/g, '');

  // Take only the first `maxDigits` (default is 6)
  const limitedText = numericText.slice(0, maxDigits);

  // Call the provided callback function with the processed value
  if (callback && typeof callback === 'function') {
    callback(limitedText);
  }

  // Prevent default paste behavior
  e.preventDefault();
};

export const handleKeyDown = (e) => {
  const allowedKeys = ["Backspace", "ArrowLeft", "ArrowRight", "Delete", "/", "Tab"];
  if (!allowedKeys.includes(e.key)) {
    e.preventDefault();
  }
};

export const showErrorToast = (errorMessage, toastId, setIs422ErrorToastActive) => {
  if (!toast.isActive(toastId)) {
    setIs422ErrorToastActive(true); // Set the state indicating the error toast is active
    toast.error(errorMessage, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      toastId: toastId,
      onClose: () => setIs422ErrorToastActive(false) // Reset the state when the toast is closed
    });
  }
}

export const transformString = (str) => {
  if (!str) return '';
  return str.replace(/\s+/g, '-').toLowerCase();
};

export const customStyles1 = (hasValue) => ({
  control: (provided,state) => ({
    ...provided,
    borderColor: hasValue ? 'black' : '#DCDCDC',
    height:'40px',
    fontSize:'14px',
    boxShadow: state.isFocused ? 'none' : 'none',
    '&:hover': {
      borderColor: hasValue ? 'black' : '#DCDCDC',
    },
  }),placeholder: (provided) => ({
    ...provided,
    color: '#ABABAB',
    width: '100%',
    fontSize:'13px',
    fontWeight:'500',
  }),
  loadingIndicator: (provided) => ({
    ...provided,
    color: 'red',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: hasValue ? 'black' : '#DCDCDC',
    svg: {
      fill: hasValue ? 'black' : '#B9B9B9',
    },
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
});


export const disableFutureDates = (current) => {
  return current && current > dayjs().endOf('month');
};

// Function to disable past and current dates for the expiry date
export const disablePastDates = (referenceDate) => (current) => {
  return current && current <= dayjs(referenceDate).startOf('month');
};
export const CustomSuffixIcon = ({isActive}) => (
  <span className="ant-picker-suffix-icon">
    <svg viewBox="64 64 896 896" fill={isActive ? 'hsl(0, 0%, 20%)' : '#DCDCDC'} focusable="false" data-icon="calendar" width="1em" height="1em"  aria-hidden="true"><path d="M880 184H712v-64c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v64H384v-64c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v64H144c-17.7 0-32 14.3-32 32v664c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V216c0-17.7-14.3-32-32-32zm-40 656H184V460h656v380zM184 392V256h128v48c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8v-48h256v48c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8v-48h128v136H184z"></path></svg>
  </span>
);
// handleApiError.js
// export const handleApiError = (error, setIsLoading=null, setError422 = null, handle422AsToast = true, showErrorToast) => {
//   if (error.response) {
//     if (error.response.status === 401) {
//       const error_code = error.response.data.code;
//       if (error_code === 'token_expired' || error_code === 'already_logged_out' ) {
//         Error401();
//       } else {
//         if(typeof showErrorToast === 'function'){
//         showErrorToast(error.response.data.mobile_error, '401Error');
//         }
//       }
//     } else if (error.response.status === 422) {
//       if (handle422AsToast) {
//         showErrorToast(error.response.data.mobile_error, '422Error');
//       } else if (setError422) {
//         setError422(error.response.data.mobile_error);
//       }
//     } else if (error.response.status === 500) {
//       showErrorToast(error.response.data.mobile_error, '500Error');
//     } else {
//       showErrorToast('An unexpected error occurred.', 'UnexpectedError');
//     }
//   }
//   if (typeof setIsLoading === 'function') {
//     setIsLoading(false);
//   }
// };
export const clearCookiesByNames =(cookieNames = [])=> {
  console.log('stalin');
  
  cookieNames.forEach(cookieName => {
    // Set the cookie expiration date to a past date to effectively delete the cookie
    document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${window.location.hostname};`;
  });
}

export const handleApiError = (error, setIsLoading = null, setError422 = null, handle422AsToast = true, showErrorToast) => {
  if (typeof showErrorToast !== 'function') {
    console.error('showErrorToast must be a function');
    return;
  }
 
  if (error.response) {
    const { status, data } = error.response;

    switch (status) {
      case 401:
        const errorCode = data.error_code;
        if (errorCode === 'token_expired' || errorCode === 'already_logged_out') {
          Error401(); // Handle unauthorized errors
        }else if(errorCode === 'session_error'){
          window.location.href = accountsdomainurl
        }else if(errorCode === 'auth_clear'){
          clearCookiesByNames(['__SID', '__MUID', '__OTID']);
        }else {
          showErrorToast(data.mobile_error || 'Unauthorized access.', '401Error');
        }
        break;

      case 422:
        if (handle422AsToast) {
          showErrorToast(data.mobile_error || 'Validation error.', '422Error');
        } else if (typeof setError422 === 'function') {
          setError422(data.mobile_error || 'Validation error.');
        }
        break;

      case 500:
        showErrorToast(data.mobile_error || 'Internal server error.', '500Error');
        break;

      default:
        showErrorToast(data.mobile_error || 'An unexpected error occurred.','UnexpectedError');
        break;
    }
  } else {
    showErrorToast('Network error or server is unreachable.', 'NetworkError');
  }

  if (typeof setIsLoading === 'function') {
    setIsLoading(false);
  }
};

export const generateUniqueKey = (prefix) => {
  return `${prefix}-${new Date().getTime()}-${Math.random().toString(36).substr(2, 9)}`;
};


