// // import './App.css';
// import { BrowserRouter as Router, Routes, Route , useLocation,Navigate} from 'react-router-dom';
// import { useEffect } from 'react';
// import Datechange from "./profilesection/datechange";
// import ContentSection from "./dashboard";
// import Emailchange from "./profilesection/emailchange";
// import Genderchange from "./profilesection/genderchange";
// import Namechange from "./profilesection/namechange";
// import Profilechange from "./profilesection/profilechange";
// import Services from "./service";
// import Sidebar from "./sidebar";
// import Sidebarminor from "./sidebarminor";
// import Passwordsecurity from "./passwordsecuritysection/passwordsecurity";
// import Twofactor from "./passwordsecuritysection/twofactor";
// import Twofactorauth from "./twofactorauth";
// import Navbars from "./navbar";
// import Twofactortext from './twofactortext';
// import Twofactorpassword from './twofactorpassword';
// import Twofactoranother from './twofactoranother';
// import Twofactorconfirmcode from './twofactorconfirm';
// import Twofactoremail from './twofactoremail';
// import Twofactoremailauth from './twofactoremailauth';
// import Review from './review';
// import Twofactoremailconfirmcode from './twofactoremailconfirm';
// import Reviewratings from './reviewratings';
// import { ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import Emergencyinfo from './profilesection/emergencyinfo';
// import Refreshpage from './refresh pages/refreshpage';
// import Documents from './documentssection/documents';
// import Acdamichistorydocument from './documentssection/acdamicdocument';
// import Workhistorydocument from './documentssection/workhistorydocument';
// import Vechicledocument from './documentssection/vechicledocument';
// import Languagechange from './profilesection/languagechange';
// import Mobilenumberchange from './profilesection/mobilenumberchange';
// import Passworchange from './passwordsecuritysection/passwordchange';
// import Recoveryemail from './passwordsecuritysection/recoveryemail';
// import Recoveryphone from './passwordsecuritysection/recoveryphone';
// import Updateemergencyinfo from './profilesection/updateemergencyinfo';
// import AddAddress from './profilesection/useraddresssection/useraddaddress/addaddress';
// import Updateaddress from './profilesection/useraddresssection/userupdateaddress/updateaddress';
// import Bussinessaddress from './profilesection/bussinessaddresssection/bussinessaddaddress/addaddress';
// import Bussinessupdateaddress from './profilesection/bussinessaddresssection/bussinessupdateaddress/updateaddress';
// import { extractLoginUser } from './api/utils/utils';
// import Parental from './parental';
// import Addparental from './addparental';
// import Childdetails from './childdetails'
// import Detailsconfigure from './detailsconfigure';
// import Main from './components/Main';
// import Connectaccounts from './passwordsecuritysection/security/connectaccounts';
// import Connectdevices from './passwordsecuritysection/security/connectdevices';
// import Appearanceseprate from './passwordsecuritysection/appearance/appearanceseprate';
// import Appearance from './passwordsecuritysection/appearance/appearance';
// import PrivateRoute from './routes/privateroute';

// function ScrollToTop() {
//   const { pathname } = useLocation();

//   useEffect(() => {
//     window.scrollTo(0, 0);
//     // makeAjaxCall();
//   }, [pathname]);

//   return null;
// }
// function App() {
//   const login_user = extractLoginUser();
//   return (
//     <>

//       <ToastContainer/>
//       <Router>
//         <ScrollToTop/>
//           <Routes>
//           <Route exact path='/' element={<Navigate to="/u/0/services" replace />} />
//           {/* <Route path='*' element={<Navigate to="/" />}/> */}
//           <Route path="/u/:user/profile" element={<ContentSection login_user={login_user}/>} />
//           <Route path="/u/:user/profilechange" element={<Profilechange />} />
//           <Route path="/u/:user/namechange" element={<Namechange />} />
//           <Route path="/u/:user/datechange" element={<Datechange />} />
//           <Route path="/u/:user/languagechange" element={<Languagechange/>} />
//           <Route path="/u/:user/genderchange" element={<Genderchange />} />
//           <Route path="/u/:user/emailchange" element={<Emailchange />} />
//           <Route path="/u/:user/mobilenumberchange" element={<Mobilenumberchange />} />
//           <Route path="/u/:user/services" element={<PrivateRoute><Services /></PrivateRoute>} />
//           <Route path='/sidebar' element={<Sidebar/>}/>
//           <Route path='/sidebarminor' element={<Sidebarminor/>}/>
//           <Route path='/u/:user/documents' element={<Documents/>} />
//           {/* <Route path='/u/:user/security' element={<Passwordsecurity />} /> */}
//           <Route path='/u/:user/security' element={<PrivateRoute><Passwordsecurity /></PrivateRoute>}/>
//           <Route path='/u/:user/passwordchange' element={<Passworchange />} />
//           <Route path='/u/:user/twofactortext' element={<Twofactortext />} />
//           <Route path='/u/:user/twofactor' element={<Twofactor />} />
//           <Route path='/u/:user/twofactorauth' element={<Twofactorauth />} />
//           <Route path='/u/:user/recoveryemail' element={<Recoveryemail/>} />
//           <Route path='/u/:user/recoveryphone' element={<Recoveryphone />} />
//           <Route path='/navbars'element={<Navbars/>}/>
//           <Route path='/u/:user/twofactorpassword' element={<Twofactorpassword />} />
//           <Route path='/u/:user/twofactoranother' element={<Twofactoranother />} />
//           <Route path='/u/:user/twofactorconfirm' element={<Twofactorconfirmcode />} />
//           <Route path='/u/:user/twofactoremail' element={<Twofactoremail />} />
//           <Route path='/u/:user/twofactoremailauth' element={<Twofactoremailauth />} />
//           <Route path='/u/:user/reviews' element={<Review />} />
//           <Route path='/u/:user/addaddress' element={<AddAddress login_user={login_user}/>} />
//           <Route path='/u/:user/twofactoremailconfirm' element={<Twofactoremailconfirmcode />} />
//           <Route path='/u/:user/reviewratings' element={<Reviewratings/>}/>
//           <Route path='/u/:user/emergencyinfo' element={<Emergencyinfo/>}/>
//           <Route path='/u/:user/documents/workhistory/:userId/document'element={<Workhistorydocument/>}/>
//           <Route path='/u/:user/documents/vechicle/:userId/document'element={<Vechicledocument/>}/>
//           <Route path='/u/:user/documents/acdamichistory/:userId/document'element={<Acdamichistorydocument/>}/>
//           <Route path='/refresh' element={<Refreshpage/>}/>
//           <Route path='/u/:user/update/:dualdynamic/emergencyinfo' element={<Updateemergencyinfo/>}/>        
//           <Route path='/u/:user/update/:dynamic/address' element={<Updateaddress login_user={login_user}/>}/>
//           <Route path='/u/:user/bussiness/address'element={<Bussinessaddress login_user={login_user}/>}/>
//           <Route path='/u/:user/bussiness/update/:dynamic/address' element={<Bussinessupdateaddress login_user={login_user}/>}/>
//           <Route path='/u/:user/security/connectedaccounts' element={<Connectaccounts login_user={login_user}/>}/>
//           <Route path='/u/:user/security/connecteddevices' element={<Connectdevices login_user={login_user}/>}/>
//           <Route path='/u/:user/security/appearance' element={<Appearance login_user={login_user}/>}/>
//         <Route path='/u/:user/parental' element={<Parental></Parental>}/>
//         <Route path='/u/:user/addparental' element={<Addparental></Addparental>}/>
//         <Route path='/u/:user/childdetails' element={<Childdetails></Childdetails>}/>
//         <Route path='/u/:user/detailsconfigure' element={<Detailsconfigure></Detailsconfigure>}/>
//         <Route path='/u/:user/parentelControl' element={<Main/>}/>

//         </Routes>
//       </Router>

//     </>
//   );
// }


// export default App;

import { BrowserRouter as Router, Routes, Route, useLocation, Navigate } from 'react-router-dom';
import { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Import components
import Datechange from "./profilesection/datechange";
import ContentSection from "./dashboard";
import Emailchange from "./profilesection/emailchange";
import Genderchange from "./profilesection/genderchange";
import Namechange from "./profilesection/namechange";
import Profilechange from "./profilesection/profilechange";
import Services from "./service";
import Sidebar from "./sidebar";
import Sidebarminor from "./sidebarminor";
import Passwordsecurity from "./passwordsecuritysection/passwordsecurity";
import Twofactor from "./passwordsecuritysection/twofactor";
import Twofactorauth from "./twofactorauth";
import Navbars from "./navbar";
import Twofactortext from './twofactortext';
import Twofactorpassword from './twofactorpassword';
import Twofactoranother from './twofactoranother';
import Twofactorconfirmcode from './twofactorconfirm';
import Twofactoremail from './twofactoremail';
import Twofactoremailauth from './twofactoremailauth';
import Review from './review';
import Twofactoremailconfirmcode from './twofactoremailconfirm';
import Reviewratings from './reviewratings';
import Emergencyinfo from './profilesection/emergencyinfo';
import Refreshpage from './refresh pages/refreshpage';
import Documents from './documentssection/documents';
import Acdamichistorydocument from './documentssection/acdamicdocument';
import Workhistorydocument from './documentssection/workhistorydocument';
import Vechicledocument from './documentssection/vechicledocument';
import Languagechange from './profilesection/languagechange';
import Mobilenumberchange from './profilesection/mobilenumberchange';
import Passworchange from './passwordsecuritysection/passwordchange';
import Recoveryemail from './passwordsecuritysection/recoveryemail';
import Recoveryphone from './passwordsecuritysection/recoveryphone';
import Updateemergencyinfo from './profilesection/updateemergencyinfo';
import AddAddress from './profilesection/useraddresssection/useraddaddress/addaddress';
import Updateaddress from './profilesection/useraddresssection/userupdateaddress/updateaddress';
import Bussinessaddress from './profilesection/bussinessaddresssection/bussinessaddaddress/addaddress';
import Bussinessupdateaddress from './profilesection/bussinessaddresssection/bussinessupdateaddress/updateaddress';
import { extractLoginUser } from './api/utils/utils';
import Parental from './parental';
import Addparental from './addparental';
import Childdetails from './childdetails';
import Detailsconfigure from './detailsconfigure';
import Main from './components/Main';
import Connectaccounts from './passwordsecuritysection/security/connectaccounts';
import Connectdevices from './passwordsecuritysection/security/connectdevices';
import Appearanceseprate from './passwordsecuritysection/appearance/appearanceseprate';
import Appearance from './passwordsecuritysection/appearance/appearance';
import PrivateRoute from './routes/privateroute';
import Accountdetails from './passwordsecuritysection/security/accountdetails';
import Acdamicdocumentlist from './documentssection/acdamichistorysection.js/acdamicdocumentsection/acdamicdocumentlist';
import Workdocumentlist from './documentssection/workhistorysection/workhdocummentsection/workdocumentlist';
import Vechicledocumentlist from './documentssection/vechiclesection/vechicledocumensection/vechicledocumentlist';
import Contactsupport from './passwordsecuritysection/security/contactsupport';
import Banaccount from './passwordsecuritysection/security/banaccount';

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  const login_user = extractLoginUser();
  
  return (
    <>
      <ToastContainer />
      <Router>
        <ScrollToTop />
        <Routes>
          {/* Redirect root to services */}
          <Route exact path='/' element={<Navigate to="/u/0/services" replace />} />

          {/* Profile Routes */}
          <Route path="/u/:user/profile" element={<PrivateRoute><ContentSection login_user={login_user} /></PrivateRoute>} />
          <Route path="/u/:user/profilechange" element={<PrivateRoute><Profilechange /></PrivateRoute>} />
          <Route path="/u/:user/namechange" element={<PrivateRoute><Namechange /></PrivateRoute>} />
          <Route path="/u/:user/datechange" element={<PrivateRoute><Datechange /></PrivateRoute>} />
          <Route path="/u/:user/languagechange" element={<PrivateRoute><Languagechange /></PrivateRoute>} />
          <Route path="/u/:user/genderchange" element={<PrivateRoute><Genderchange /></PrivateRoute>} />
          <Route path="/u/:user/emailchange" element={<PrivateRoute><Emailchange /></PrivateRoute>} />
          <Route path="/u/:user/mobilenumberchange" element={<PrivateRoute><Mobilenumberchange /></PrivateRoute>} />

          {/* Services */}
          <Route path="/u/:user/services" element={<PrivateRoute><Services /></PrivateRoute>} />

          {/* Sidebar */}
          <Route path='/sidebar' element={<Sidebar />} />
          <Route path='/sidebarminor' element={<Sidebarminor />} />

          {/* Documents */}
          <Route path='/u/:user/documents' element={<PrivateRoute><Documents login_user={login_user}/></PrivateRoute>} />
          <Route path='/u/:user/documents/workhistory/:userId/document' element={<PrivateRoute><Workdocumentlist login_user={login_user}/></PrivateRoute>} />
          <Route path='/u/:user/documents/vechicle/:userId/document' element={<PrivateRoute><Vechicledocumentlist login_user={login_user} /></PrivateRoute>} />
          <Route path='/u/:user/documents/acdamichistory/:userId/document' element={<PrivateRoute><Acdamicdocumentlist login_user={login_user} /></PrivateRoute>} />

          {/* Security */}
          <Route path='/u/:user/security' element={<PrivateRoute><Passwordsecurity /></PrivateRoute>} />
          <Route path='/u/:user/passwordchange' element={<PrivateRoute><Passworchange /></PrivateRoute>} />
          <Route path='/u/:user/recoveryemail' element={<PrivateRoute><Recoveryemail /></PrivateRoute>} />
          <Route path='/u/:user/recoveryphone' element={<PrivateRoute><Recoveryphone /></PrivateRoute>} />
          <Route path='/u/:user/security/accountdetails' element={<PrivateRoute><Accountdetails login_user={login_user}/></PrivateRoute>}/>
          <Route path='/u/:user/security/connectedaccounts' element={<PrivateRoute><Connectaccounts login_user={login_user} /></PrivateRoute>} />
          <Route path='/u/:user/security/connecteddevices' element={<PrivateRoute><Connectdevices login_user={login_user} /></PrivateRoute>} />
          <Route path='/u/:user/security/appearance' element={<PrivateRoute><Appearance login_user={login_user} /></PrivateRoute>} />
          <Route path='/u/:user/security/contact/support' element={<PrivateRoute><Contactsupport login_user={login_user}/></PrivateRoute>}/>
          <Route path='/u/:user/security/ban' element={<PrivateRoute><Banaccount login_user={login_user}/></PrivateRoute>}/>

          {/* Two-Factor Authentication */}
          <Route path='/u/:user/twofactortext' element={<PrivateRoute><Twofactortext /></PrivateRoute>} />
          <Route path='/u/:user/twofactor' element={<PrivateRoute><Twofactor /></PrivateRoute>} />
          <Route path='/u/:user/twofactorauth' element={<PrivateRoute><Twofactorauth /></PrivateRoute>} />
          <Route path='/u/:user/twofactorpassword' element={<PrivateRoute><Twofactorpassword /></PrivateRoute>} />
          <Route path='/u/:user/twofactoranother' element={<PrivateRoute><Twofactoranother /></PrivateRoute>} />
          <Route path='/u/:user/twofactorconfirm' element={<PrivateRoute><Twofactorconfirmcode /></PrivateRoute>} />
          <Route path='/u/:user/twofactoremail' element={<PrivateRoute><Twofactoremail /></PrivateRoute>} />
          <Route path='/u/:user/twofactoremailauth' element={<PrivateRoute><Twofactoremailauth /></PrivateRoute>} />
          <Route path='/u/:user/twofactoremailconfirm' element={<PrivateRoute><Twofactoremailconfirmcode /></PrivateRoute>} />

          {/* Reviews */}
          <Route path='/u/:user/reviews' element={<PrivateRoute><Review /></PrivateRoute>} />
          <Route path='/u/:user/reviewratings' element={<PrivateRoute><Reviewratings /></PrivateRoute>} />

          {/* Emergency Info */}
          <Route path='/u/:user/emergencyinfo' element={<PrivateRoute><Emergencyinfo login_user={login_user}/></PrivateRoute>} />
          <Route path='/u/:user/update/:dualdynamic/emergencyinfo' element={<PrivateRoute><Updateemergencyinfo login_user={login_user}/></PrivateRoute>} />

          {/* Addresses */}
          <Route path='/u/:user/addaddress' element={<PrivateRoute><AddAddress login_user={login_user} /></PrivateRoute>} />
          <Route path='/u/:user/update/:dynamic/address' element={<PrivateRoute><Updateaddress login_user={login_user} /></PrivateRoute>} />

          {/* Parental Controls */}
          <Route path='/u/:user/parental' element={<PrivateRoute><Parental /></PrivateRoute>} />
          <Route path='/u/:user/addparental' element={<PrivateRoute><Addparental /></PrivateRoute>} />

        </Routes>
      </Router>
    </>
  );
}

export default App;
