// import React from 'react';
// import ReactDOM from 'react-dom/client';
// import './index.css';
// import App from './App';
// import reportWebVitals from './reportWebVitals';
// import { Provider } from 'react-redux';
// import { createStore } from 'redux';
// import rootReducer from './api/reducers';
// const store = createStore(rootReducer);
// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   // <React.StrictMode>
//     <App />
//   // </React.StrictMode>
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
// import React from 'react';
// import ReactDOM from 'react-dom';
// import './index.css';
// import App from './App';
// import reportWebVitals from './reportWebVitals';
// import { Provider, } from 'react-redux';
// import store from './api/store';


// ReactDOM.render(
//   <Provider store={store}>
//     <React.StrictMode>
//       <App />
//     </React.StrictMode>
//   </Provider>,
//   document.getElementById('root')
// );
import React from 'react';
import { createRoot } from 'react-dom/client'; // Import createRoot from react-dom
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './api/redux/store';
import { IpProvider } from './api/hooks/iphook';
import { ToastProvider } from './api/hooks/toasthook';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { CountryDataProvider } from './api/hooks/mobilehook';
import { UploadProvider } from './api/hooks/uploadhook';
import { CountryDatasProvider } from './api/hooks/mobilecountryhook';

// Use createRoot instead of ReactDOM.render
const root = document.getElementById('root');
const rootInstance = createRoot(root);
rootInstance.render(
  <Provider store={store}>
    {/* <React.StrictMode> */}
      <GoogleOAuthProvider clientId='996477016859-fcus6csqsr79o26uq1b41mgg9rop5ps5.apps.googleusercontent.com'>
      <ToastProvider>
      <IpProvider>
      <CountryDataProvider> 
      <CountryDatasProvider>   
      <UploadProvider>      
      <App />
      </UploadProvider>
      </CountryDatasProvider>
      </CountryDataProvider>
      </IpProvider>
      </ToastProvider>
      </GoogleOAuthProvider>
    {/* </React.StrictMode> */}
  </Provider>
);
// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
