import React, { useCallback, useEffect,useRef,useState } from 'react';
import Documentheaderfooter from '../../component/documentheaderfooter';
import { useEmployentTypeOptions, useWorkTypeOptions } from '../../api/hooks/redux-hooks/useReduxActions';
import CustomDatePicker from '../../component/customdatepicker';
import dayjs from 'dayjs';
import Select from 'react-select';
import { customStyles1, disableFutureDates, disablePastDates, extractImageString, formatDateForExpiry, formatDateForInput, formatFileSize, handleApiError, HandleCalls, parseFileExtension } from '../../api/utils/utils';
import { citylistapi, countryselectapi, statelistapi } from '../../api/helper/urlhelper';
import axiosInstance from '../../api/utils/axiosutils';
import Fileuploader from '../../component/fileuploader';
import { useUpload } from '../../api/hooks/uploadhook';
import { workStore } from '../../api/helper/apiurlhelper';
const countryselect = countryselectapi();
const statelistapiurl = statelistapi();
const citylistapiurl = citylistapi();
function Workhistoryedit({values,show,setIsLoading4,imageviewer,setListvalues,workhistorysdocument,showErrorToast}) {
    const [formvalues,setFormvalues] = useState({jobtitle:'',companyname:'',startdate:null,enddate:null,worktype:null,employementtype:null,country:null,state:null,city:null,countryoption:[],stateoption:[],cityoption:[],countryloading:false,cityloading:false,stateloading:false,isenabled:false,workid:''});
    const [error,setError] = useState({frontimageerror:'',expiryerror:'',countryerror:'',stateerror:''});
    const [error422,setError422] = useState('');
    const [fileSize, setFileSize] = useState(0);
    const {uploadFile} = useUpload();
    const [format, setFormat] = useState('');
    const [docname,setDocname] = useState('');
    const [uploading, setUploading] = useState(false);
    const [progress, setProgress] = useState(0);
    const [progressColor, setProgressColor] = useState("#28a745");
    const [image, setImage] = useState('');  
    const { workTypeOptions,loading,loadWorkTypes } = useWorkTypeOptions({showErrorToast,});  
    const { employmentTypeOptions,loading1,loadEmployementTypes } = useEmployentTypeOptions({showErrorToast,});  
    const workref = useRef({country: null,city:null,state:null});
    const fileInputRef = useRef(null);
    useEffect(()=>{
     if(values){
         const formateedstart = dayjs(values.start,'YYYY-MM-DD') || '';
         const formateeend = dayjs(values.end,'YYYY-MM-DD') || '';
        setFormvalues((prev)=>({...prev,
          workid : values.id || '',  
          jobtitle:values.designation || '',
          companyname:values.company || '' ,
          startdate:formateedstart,
          enddate:formateeend,
          worktype:values.experience_location_type 
          ? { label: values.experience_location_type.name, value: values.experience_location_type.id }
          : null,
          employementtype:values.employment_type
          ? { label: values.employment_type.name, value: values.employment_type.id }
          : null,
          country:values.country ? {label:values.country.name , value:values.country.id} : null,
          state:values.state ? {label:values.state.name , value:values.state.id} : null,
          city:values.city ? {label:values.city.name , value:values.city.id} : null
          }))
           const imageUrl = `${values.image}`;
            setImage(imageUrl);
            const filesize = formatFileSize(imageUrl.filesize);
            setFileSize(filesize);
            const filename = imageUrl.split('/').pop();
            setDocname(extractImageString(filename));
            setProgress(100);
            setFormat(parseFileExtension(imageUrl))
            setUploading(true);
     }
    },[values]);
    const fetchstate = useCallback(async () => {
        try {
          const workcountryid = formvalues.country.value;
          if(workcountryid){
          setFormvalues((prev)=>({...prev,stateloading:true}))
          const response = await axiosInstance.get(`${statelistapiurl}?country_id=${workcountryid}`,);
          if (response.status === 200) {
            const statelist = response.data.data;
            setFormvalues((prev)=>({...prev,stateloading:false,stateoption:statelist.map(company => ({ value: company.id, label: company.name }))}))
          }
        }
        }
        catch (error) {
          setFormvalues((prev)=>({...prev,stateloading:false}))
          handleApiError(error,null,null,true,showErrorToast);
        }
      },[formvalues.country]);
      const fetchcity = useCallback(async () => {
        try {
            const stateId = formvalues.state.value;
            if(stateId){
            setFormvalues((prev)=>({...prev,cityloading:true}));
          const response = await axiosInstance.get(`${citylistapiurl}?state_id=${stateId}`);
          if (response.status === 200) {
            const citylist = response.data.data;
            setFormvalues((prev)=>({...prev,cityloading:false,cityoption:citylist.map(company => ({ value: company.id, label: company.name }))}))
          }
        }
        }
        catch (error) {
          setFormvalues((prev)=>({...prev,cityloading:true}));
          handleApiError(error,null,null,true,showErrorToast);
        }
      },[formvalues.state]);
      useEffect(() => {
        if (formvalues.country) {
          fetchstate();
        }
      }, [formvalues.country,fetchstate]);
      useEffect(() => {
        if (formvalues.state) {
          fetchcity();
        }
      }, [formvalues.state,fetchcity]);
      const fetchCountries = async () => {
        try {
          setFormvalues((prev)=>({...prev,countryloading:true}));
          const response = await axiosInstance.get(countryselect);
          const data = response.data;
          if (data.message === 'Success') {
            const countryList = data.data;
            setFormvalues((prev)=>({...prev,countryloading:false,countryoption:countryList.map(company => ({ value: company.id, label: company.name }))}));
          }
        } catch (error) {
          setFormvalues((prev)=>({...prev,countryloading:false}));
          handleApiError(error,null,null,true,showErrorToast);
        }
      };
    const fetchdata = async() => {
        loadWorkTypes();
        loadEmployementTypes();
        await fetchCountries();
        // await fetchstate();
        // await fetchcity();
    }  
    useEffect(()=>{
        if(show){
            fetchdata();
        }
      },[show,]);  
      const handleChange = (e) => {
          const { name, value } = e.target;
          setFormvalues((prevValues) => ({
            ...prevValues,
            [name]: value,
          }));
      }   
      const handleOpenChange = (open) => {
          setFormvalues((prev)=>({...prev,isopen:open}));
        }; 
        const handleExpiryClick = (e) => {
          if (!formvalues.startdate) {
            setError((prevState) => ({
              ...prevState,
              expiryerror:'Please select Date of Issue first'
            }));
            setFormvalues((prev)=>({...prev,isopen:false}));
          } else {
            setError((prevState) => ({
              ...prevState,
              expiryerror:''
            }));
            setFormvalues((prev)=>({...prev,isopen:true}));
          }
        };
        const handleworktype = (selectedOption) => {
          setFormvalues((prev)=>({...prev,worktype:selectedOption}));
          setError422('')
        }
      const handleemployeetype = (selectedOption) => {
      setFormvalues((prev)=>({...prev,employementtype:selectedOption}));
      setError422('');
      }
    
      const focusOnInput = (inputName) => {
      const inputRef = workref.current[inputName];
      if (inputRef) {
          inputRef.focus();
      }
      };  
      const handlestatefocus = () => {
          if (!formvalues.country) {
            setError((prevState) => ({
              ...prevState,
              countryerror: 'Please select a country first.',
            }));
            focusOnInput('country');
          } else {
            setError((prevState) => ({
              ...prevState,
              countryerror: '',
            }));
          }
        };
        const handlecityfocus = () => {
          if (!formvalues.country) {
            setError((prevState) => ({
              ...prevState,
              countryerror: 'Please select a country first.',
              stateerror: '',
            }));
            focusOnInput('country'); 
          } else if (!formvalues.state) {
            setError((prevState) => ({
              ...prevState,
              countryerror: '',
              stateerror: 'Please select a state first.',
            }));
            focusOnInput('state'); 
          } else {
            setError((prevState) => ({
              ...prevState,
              countryerror: '',
              stateerror: '',
            }));
          }
        };
        const handlecountry = (selectedOption) => {
          setFormvalues((prev)=>({...prev,
            country:selectedOption,stateoption:[],state:''
          }));
          setError((prevState) => ({
            ...prevState,
            countryerror:''
          }))
          setError422('');
        }
        const handlestate = (selectedOption) => {
          setFormvalues((prev)=>({...prev,
              state:selectedOption,cityoption:[],city:''
          }));
          setError((prevState) => ({
            ...prevState,
            stateerror:''
          }))
          setError422('');
        }
        const handlecity = (selectedOption) => {
          setFormvalues((prev)=>({...prev,
          city:selectedOption
          }));
          setError422('');
        }
        const handleFrontview = (image) => {
          setListvalues((prev) => ({
              ...prev, selectimage: [image],editshow:false
          }));
          imageviewer('edit');
        } 
        const handleclose = () => {
          setListvalues((prev)=>({...prev,editshow:false,editvalues:[]}));  
          setFormvalues((prev)=>({...prev,
           companyname:'',jobtitle:'',startdate:null,enddate:null,country:null,state:null,city:null,employementtype:null,worktype:null,countryoption:[],cityoption:[],stateoption:[]
          }))      
          setImage('');
          setDocname('');
          setProgress(0);
          setUploading(false);
          setFormat('');
        }
        const checkFormValidity = () => {
                const isValid = (
                  formvalues.jobtitle.trim() !== '' &&
                  formvalues.companyname.trim() !== '' &&
                  formvalues.startdate !== '' &&
                  formvalues.enddate !== '' &&
                  formvalues.startdate !== null &&
                  formvalues.enddate !== null &&
                  formvalues.country !== '' &&
                  formvalues.country !== null &&
                  image !== ''  
                );
                setFormvalues((prev)=>({...prev,isenabled:isValid}));
              };
              useEffect(() => {
                checkFormValidity();
              }, [formvalues.jobtitle, formvalues.companyname, formvalues.startdate, formvalues.enddate, formvalues.country, image]);
        const handledocumentsave = async() => {
        setIsLoading4(true);    
        let newImagePath = image;
        if (typeof newImagePath === "object" && newImagePath instanceof File) {
          const uploadedImage = await uploadFile(image,'workhistory',setIsLoading4);
          newImagePath = uploadedImage; 
        }
          let data = {
          id : formvalues.workid, 
          company: formvalues.companyname,
          designation: formvalues.jobtitle,
          start: formatDateForInput(formvalues.startdate),
          end: formatDateForExpiry(formvalues.enddate),
          country_id: formvalues.country.value,
          image: newImagePath,
        }
        if (formvalues.employementtype) {
          data.employment_type_id = formvalues.employementtype.value;
        }
        if(formvalues.worktype){
          data.experience_location_type_id = formvalues.worktype.value
        }
        if(formvalues.city){
          data.city_id = formvalues.city.value;
        }
        if(formvalues.state){
          data.state_id = formvalues.state.value;
        }
        const after_successcall = async() => {
          handleclose();
          await workhistorysdocument();
        }
        await HandleCalls({apiCall:()=>workStore(data,false),successCall:after_successcall,showErrorToast:showErrorToast,handle422AsToast:false,setError422:setError422});
        setIsLoading4(false)
      }  
  return (
   <>
      <Documentheaderfooter show={show} title='Add Work History' onClose={handleclose} save={handledocumentsave} enabled={formvalues.isenabled}>
    <div className='row docpoprow'>
    <div className="col-sm-6 col-12">
    <label className="docpoplabel1">Job title/Designation<span className="docpopstar">*</span></label>
     <input type='text' name='jobtitle' placeholder='Enter Job Title' value={formvalues.jobtitle} onChange={handleChange} className={`form-control docform ${formvalues.jobtitle ? 'docform-active' : ''}`}></input> 
    </div>   
    <div className="col-sm-6 col-12">
    <label className="docpoplabel1">Company Name<span className="docpopstar">*</span></label>
    <input type='text' name='companyname' placeholder='Enter Company Name' value={formvalues.companyname} onChange={handleChange} className={`form-control docform ${formvalues.companyname ? 'docform-active' : ''}`}></input> 
    </div>   
    </div>
    <div className='row docpoprow'>
    <div className='col-sm-6 col-12'>
    <label className="docpoplabel">Date of Issue<span className="docpopstar">*</span></label>
    <CustomDatePicker value={formvalues.startdate} disabledDate={disableFutureDates} onChange={(date)=>{setFormvalues((prev)=>({...prev,startdate:date,enddate:null}));setError((prev)=>({...prev,expiryerror:'',}));setError422('');}}/>
    {error.expiryerror && <p className="error-message">{error.expiryerror}</p>}
    </div>
    <div className='col-sm-6 col-12'>
    <label className="docpoplabel">Expiry Date</label>
    <CustomDatePicker value={formvalues.enddate} open={formvalues.isopen} onClick={handleExpiryClick} onOpenChange={handleOpenChange} disabledDate={disablePastDates(dayjs())} onChange={(date)=>{setFormvalues((prev)=>({...prev,enddate:date,}));setError422('');}}/>
    </div>
    </div> 
    <div className="row docpoprow">
    <div className="col-sm-6 col-12">
        <label className="docpoplabel1">Work type</label>
        <Select options={workTypeOptions} isLoading={loading} value={formvalues.worktype}  isSearchable={false} placeholder="Select Work type" onChange={handleworktype} styles={customStyles1(Boolean(formvalues.worktype))}  />
    </div>
    <div className="col-sm-6 col-12">
        <label className="docpoplabel1">Employment Type</label>
        <Select options={employmentTypeOptions} isLoading={loading1} value={formvalues.employementtype}  isSearchable={false} placeholder="Select Employment type"  onChange={handleemployeetype} styles={customStyles1(Boolean(formvalues.employementtype))}  />
    </div>
    </div>
    <div className="row docpoprow">
    <div className="col-sm-6 col-xl-4 col-12">
        <label className="docpoplabel1">Country<span className="docpopstar">*</span></label>
        <Select options={formvalues.countryoption} isLoading={formvalues.countryloading} ref={(el) => workref.current.country = el} value={formvalues.country}  isSearchable={true} placeholder="Select Country" onChange={handlecountry} styles={customStyles1(Boolean(formvalues.country))}  />
        {error.countryerror && <div className="error-message">{error.countryerror}</div>}
    </div>
    <div className="col-sm-6 col-xl-4 col-12">
        <label className="docpoplabel1">State</label>
        <Select options={formvalues.stateoption} isLoading={formvalues.stateloading} ref={(el) => workref.current.state = el}  value={formvalues.state} onFocus={handlestatefocus} isSearchable={true} placeholder="Select State" onChange={handlestate} styles={customStyles1(Boolean(formvalues.state))}  />
        {error.stateerror && <div className="error-message">{error.stateerror}</div>}
    </div>
    <div className="col-xl-4 col-12">
        <label className="docpoplabel1">City</label>
        <Select options={formvalues.cityoption} isLoading={formvalues.cityloading} ref={(el) => workref.current.city = el} value={formvalues.city}  isSearchable={true} onFocus={handlecityfocus} placeholder="Select City" onChange={handlecity} styles={customStyles1(Boolean(formvalues.city))}  />
    </div>
    </div> 
    <Fileuploader  handleFrontview={handleFrontview}  docname={docname} acceptPDF={false} fileInputRef={fileInputRef} setImage={setImage} format={format} fileSize={fileSize} image={image} progress={progress} setProgress={setProgress} progressColor={progressColor} setProgressColor = {setProgressColor} setFileSize={setFileSize} uploading={uploading} setUploading={setUploading} setFormat={setFormat} setDocname={setDocname} setError422={setError422} setImageerror={(message) =>setError((prev) => ({...prev,frontimageerror: message}))} Acceptformat={['jpg','jpeg','png',]}/>
      {error.frontimageerror && <p className="error-message">{error.frontimageerror}</p>}
      {error422 && <div className="error-message">{error422}</div>}
   </Documentheaderfooter>
   </>
  )
}

export default Workhistoryedit