import { acdamichistorydeleteapi, acdamichistorydocumentdeleteapi, acdamichistorydocumentgetapi, acdamichistorydocumentstoreapi, acdamichistorydocumentupdateapi, acdamichistorygetapi, acdamichistoryupdateapi, acdamiclevelapi, acdamicstoreapi, country_codeapi,countryselectapi,documentsgeneraldeleteapi,documentsgeneralgetapi,documentsgeneralupdateapi,documentstoreapi,documenttypesapi,fileuploadapi,getemergencyrelationlistapi,getuserbussinesslocationapi,getuserdeleteapi,getuserlocationapi,getusersessionapi,GoogleMapurl,ipapi,locationstoreapi,locationupdateapi,modulesproviderapi, otpupdatesendapi, profileremoveapi, profileupdateapi, recoverygetapi, recoveryupdateapi, removeusersessionapi, sidapi, storeemergencyapi, Updateemergencyapi, vechicledocumentdeleteapi, vechicledocumentgetapi, vechicledocumentstoreapi, vechicledocumentupdateapi, vechiclegetapi, workemploymenttypeapi, workhistorydeleteapi, workhistorydocumentdeleteapi, workhistorydocumentgetapi, workhistorydocumentstoreapi, workhistorydocumentupdateapi, workhistorygetapi, workhistorystoreapi, workhistoryupdateapi, worklocationtypeapi } from "./urlhelper";
import axiosInstance from "../utils/axiosutils";
import axios from "axios";
const googlemapkey = GoogleMapurl();
const countrycodeurl = country_codeapi();
const sidapiurl = sidapi();
const modulesproviderapiurl = modulesproviderapi();
const getuserbussinesslocationapiurl = getuserbussinesslocationapi();
const getuserlocationapiurl = getuserlocationapi();
const deletelocationurl = getuserdeleteapi();
const locationstoreapiurl = locationstoreapi()
const locationupdateapiurl = locationupdateapi();
const otpupdatesendapiurl = otpupdatesendapi();
const recoveryupdateapiurl = recoveryupdateapi();
const recoverygetapiurl = recoverygetapi();
const getusersessionapiurl = getusersessionapi();
const removeusersessionapiurl = removeusersessionapi();
const profileupdateapiurl = profileupdateapi();
const profileremoveapiurl = profileremoveapi();
const fileuploadapiurl = fileuploadapi();
const storeemergencyapiurl = storeemergencyapi();
const getemergencyrelationlistapiurl = getemergencyrelationlistapi();
const Updateemergencyapiurl = Updateemergencyapi();
const countryselectapiurl = countryselectapi();
const documentsgeneralgetapiurl = documentsgeneralgetapi();
const documenttypesapiurl = documenttypesapi();
const documentstoreapiurl = documentstoreapi();
const documentsgeneralupdateapiurl = documentsgeneralupdateapi();
const documentsgeneraldeleteapiurl = documentsgeneraldeleteapi();
const workhistorygetapiurl = workhistorygetapi();
const worklocationtypeapiurl = worklocationtypeapi();
const workemploymenttypeapiurl = workemploymenttypeapi();
const workhistorystoreapiurl = workhistorystoreapi();
const workhistoryupdateapiurl = workhistoryupdateapi();
const workhistorydeleteapiurl = workhistorydeleteapi();
const acdamichistorygetapiurl = acdamichistorygetapi();
const acdamiclevelapiurl = acdamiclevelapi();
const acdamicstoreapiurl = acdamicstoreapi();
const acdamichistoryupdateapiurl = acdamichistoryupdateapi();
const acdamichistorydeleteapiurl = acdamichistorydeleteapi();
const acdamichistorydocumentdeleteapiurl = acdamichistorydocumentdeleteapi();
const acdamichistorydocumentgetapiurl = acdamichistorydocumentgetapi();
const acdamichistorydocumentstoreapiurl = acdamichistorydocumentstoreapi();
const acdamichistorydocumentupdateapiurl = acdamichistorydocumentupdateapi();
const workhistorydocumentgetapiurl = workhistorydocumentgetapi();
const workhistorydocumentdeleteapiurl = workhistorydocumentdeleteapi();
const workhistorydocumentstoreapiurl = workhistorydocumentstoreapi();
const workhistorydocumentupdateapiurl = workhistorydocumentupdateapi();
const vechicledocumentstoreapiurl = vechicledocumentstoreapi();
const vechicledocumentgetapiurl = vechicledocumentgetapi();
const vechicledocumentupdateapiurl = vechicledocumentupdateapi();
const vechicledocumentdeleteapiurl = vechicledocumentdeleteapi();
const vechiclegetapiurl = vechiclegetapi();
const ipapiurl = ipapi();
export const mcc_list_ip = async() => {
   return await axiosInstance.get(countrycodeurl);
}
export const modules_provider = async() => {
   return await axiosInstance.get(modulesproviderapiurl);
}
export const bussiness_location = async() => {
   return await axiosInstance.get(getuserbussinesslocationapiurl);
}
export const user_location_get = async() => {
   return await axiosInstance.get(getuserlocationapiurl);
}
export const user_location_delete = async(data) => {
   return await axiosInstance.post(deletelocationurl,data);
}
export const user_location_store = async(data) => {
   return await axiosInstance.post(locationstoreapiurl,data);
}
export const user_location_update = async(data) => {
   return await axiosInstance.post(locationupdateapiurl,data)
}
export const fetchgeocode = async(latLng) => {
   return await axios.get('https://maps.googleapis.com/maps/api/geocode/json', {
      params: {
        latlng: `${latLng.lat},${latLng.lng}`,
        key: googlemapkey
      }
    })
}
export const updateSendOtp = async(data)=>{
   return await axiosInstance.post(otpupdatesendapiurl,data);
} 
export const updateRecoveryVerify = async(data)=>{
   return await axiosInstance.post(recoveryupdateapiurl,data);
}
export const recoveryGetDetails = async()=>{
   return await axiosInstance.post(recoverygetapiurl);
}
export const getUserSessionDetails = async(params)=>{
   return await axiosInstance.get(getusersessionapiurl,{ params });
}
export const getaccounts = async()=>{
   return await axiosInstance.get(sidapiurl);
}
export const removesession = async(data)=>{
   return await axiosInstance.post(removeusersessionapiurl,data);
}
export const profileUpdate = async(data) => {
   return await axiosInstance.post(profileupdateapiurl,data);
}
export const profileRemove = async() => {
   return await axiosInstance.post(profileremoveapiurl,null);
}
export const fileUpload = async(data) => await axiosInstance.post(fileuploadapiurl,data,{
   headers: {
       "Content-Type": "multipart/form-data",
   }
});
export const getip = async() => await axiosInstance.get(ipapiurl);
export const storeEmergency = async(data,isadd) => {
  if(isadd){
   return await axiosInstance.post(storeemergencyapiurl,data); 
  }else{
   return await axiosInstance.post(Updateemergencyapiurl,data); 
  }
}
export const getEmergencyRelationList = async() => await axiosInstance.get(getemergencyrelationlistapiurl);
export const countryList = async() => await axiosInstance.get(countryselectapiurl);
export const documentGeneralList = async(params) => await axiosInstance.get(documentsgeneralgetapiurl,{ params });
export const documentTypesList = async(data) => await axiosInstance.get(`${documenttypesapiurl}?country_id=${data}`);
export const documentstore = async(data,isadd) => {
  if(isadd){
   return await axiosInstance.post(documentstoreapiurl,data);
  }else{
   return await axiosInstance.post(documentsgeneralupdateapiurl,data);
  }
}
export const documentgeneraldelete = async(data) => await axiosInstance.post(`${documentsgeneraldeleteapiurl}?user_document_id=${data}`, {},);
export const workHistoryList = async() => await axiosInstance.get(workhistorygetapiurl);  
export const workLocationList = async() => await axiosInstance.get(worklocationtypeapiurl);
export const workEmployementType = async() => await axiosInstance.get(workemploymenttypeapiurl);
export const workStore = async(data,isadd) => {
   if(isadd){
      return await axiosInstance.post(workhistorystoreapiurl,data);
   }else{
      return await axiosInstance.post(workhistoryupdateapiurl,data);
   }
}
export const workdelete = async(data) => await axiosInstance.post(`${workhistorydeleteapiurl}?id=${data}`, {});
export const acdamicHistoryList = async() => await axiosInstance.get(acdamichistorygetapiurl); 
export const acdamicLevelList = async() => await axiosInstance.get(acdamiclevelapiurl);
export const acdamicstore = async(data,isadd) => {
  if(isadd){
   return await axiosInstance.post(acdamicstoreapiurl,data);
  }else{
   return await axiosInstance.post(acdamichistoryupdateapiurl,data);
  }
}
export const acdamicdelete = async(data) => await axiosInstance.post(`${acdamichistorydeleteapiurl}?id=${data}`, {},);
export const acdamicDocumentList = async() => await axiosInstance.get(acdamichistorydocumentgetapiurl);
export const acdamicDocumentDelete = async(data) => await axiosInstance.post(`${acdamichistorydocumentdeleteapiurl}?user_education_document_id=${data}`, {},);
export const acdamicDocumentStore = async(data,isadd) =>{
   if(isadd){
    return await axiosInstance.post(acdamichistorydocumentstoreapiurl,data);
   }else{
    return await axiosInstance.post(acdamichistorydocumentupdateapiurl,data);
   }
} 
export const workDocumentList = async() => await axiosInstance.get(workhistorydocumentgetapiurl);
export const workDocumentDelete = async(data) => await axiosInstance.post(`${workhistorydocumentdeleteapiurl}?user_experience_document_id=${data}`, {},);
export const workDocumentStore = async(data,isadd) =>{
   if(isadd){
    return await axiosInstance.post(workhistorydocumentstoreapiurl,data);
   }else{
    return await axiosInstance.post(workhistorydocumentupdateapiurl,data);
   }
} 
export const vechicleDocumentList = async(data) => await axiosInstance.get(`${vechicledocumentgetapiurl}?user_vehicle_id=${data}`);
export const vechicleDocumentDelete = async(data) => await axiosInstance.post(vechicledocumentdeleteapiurl,data);
export const vechicleDocumentStore = async(data,isadd) =>{
   if(isadd){
    return await axiosInstance.post(vechicledocumentstoreapiurl,data);
   }else{
    return await axiosInstance.post(vechicledocumentupdateapiurl,data);
   }
} 
export const vechicleGetList = async() => await axiosInstance.get(vechiclegetapiurl);