import React, { useEffect, useRef, useState } from 'react';
import Navbars from '../../../navbar';
import back from '../../../images/arrow-left-01.png';
import { useNavigate, useParams } from 'react-router-dom';
import adddocuemnt from '../../../images/general-id/Add_document.svg';
import { confirmDialog, generateUniqueKey, HandleCalls } from '../../../api/utils/utils';
import WithToast from '../../../api/hooks/withtoasthook';
import { acdamicDocumentDelete, acdamicDocumentList } from '../../../api/helper/apiurlhelper';
import Documentcard from '../../../component/documentcard';
import SkeletonLoader from '../../skeletonloader';
import Button from '../../../component/savebutton';
import Acdamicdocumentadd from './acdamicdocumentadd';
import Loader from '../../../component/loader';
import ImageViewer from '../../../component/imageviewer';
import PDFViewer from '../../pdfviewer';
import { publicDomain } from '../../../api/helper/urlhelper';
import Acdamicdocumentedit from './acdamicdocumentedit';
function Acdamicdocumentlist({login_user,showErrorToast}) {
  const documentRef = useRef(null);
  const navigate = useNavigate();
  const {userId} = useParams();
  const [isLoading4,setIsLoading4] =  useState(false);
  const [formvalues,setFormvalues] = useState({documents:[],uniquekey:0,isLoading:false,show:false,editshow:false,editvalues:[],selectimage:[],selectdoc:'',ispdfvisible:false,ispreviewvisible:false});
   const getdocuments = async() => {
      setFormvalues((prev)=>({...prev,isLoading:true}));
      const after_successcall = async(response) => {
      const doc = response.data.data;
      const matchingDoc = doc.find(item => String(item.id) === String(userId));
      if (!matchingDoc) {
        navigate(`/u/${login_user}/documents`);
      } else {
        setFormvalues((prev)=>({...prev,documents:[matchingDoc]}));
      }  
      setFormvalues((prev)=>({...prev,acdamichistory:response.data.data}));
      }
      await HandleCalls({apiCall:acdamicDocumentList,successCall:after_successcall,showErrorToast:showErrorToast,});
      setFormvalues((prev)=>({...prev,isLoading:false}));
      }
      useEffect(()=>{
      getdocuments();
      },[]);
      const showimageviewer = (show,viewer) => {
        if(viewer === 'image'){
        setFormvalues((prev)=>({...prev,ispreviewvisible:true,ischeck:show}));
        }else{
        setFormvalues((prev)=>({...prev,ispdfvisible:true,ischeck:show}));
        }
      }
      const closeimageviewer = () => {
        if(formvalues.ischeck === 'add'){
          setFormvalues((prev)=>({...prev,show:true}));
        }else if(formvalues.ischeck === 'edit'){
          setFormvalues((prev)=>({...prev,editshow:true}));
        }
        setFormvalues((prev)=>({...prev,ispreviewvisible:false,ispdfvisible:false}));
      }
      const handleclose = () => {
        setFormvalues((prev)=>({...prev,show:false}));
      }
      const handledeletedocument = async(doc) => {
        const user_document_id1 = doc.id;
        const result = await confirmDialog('Are you sure?','You won\'t be able to revert this!');
            if(result.isConfirmed){
               const after_successcall = async() => {
                  await getdocuments();
                }
              setIsLoading4(true);
              await HandleCalls({apiCall:()=>acdamicDocumentDelete(user_document_id1),successCall:after_successcall,showErrorToast:showErrorToast}); 
              setIsLoading4(false)
            }
      }
      const handelgeneraledit = (doc) => {
        if(doc){
           setFormvalues((prev)=>({...prev,editvalues:doc,editshow:true}));
        }
      }
      const handleEyeClickimage = (document) => {
        const lastDoc = document.user_education_document_map[document.user_education_document_map.length - 1];
        const filePath = lastDoc.path;
        if (filePath.endsWith('.pdf')) {
            setFormvalues((prev) => ({
                ...prev,
                selectdoc: `${publicDomain()}/${filePath}`,
                uniquekey: generateUniqueKey('list'),
            }));
            showimageviewer('', 'pdf');
        } else {
            setFormvalues((prev) => ({
                ...prev,
                selectimage: [filePath], 
            }));
            showimageviewer('', 'image');
        }
    };
    
      const adddocument = () => {
        setFormvalues((prev)=>({...prev,show:true}));
      }
  return (
    <> 
       {isLoading4 && <Loader/>}
       <Navbars/>
       <div className="container-fluid">
       <div className="workhistorydocs">
       <div className="document1" ref={documentRef}>
       <div className="docnav1">
            <img src={back} onClick={()=>navigate(`/u/${login_user}/documents`)} className="me-3" alt="arrow" width='19.5px' height="15.5px" style={{cursor:'pointer'}}></img>
            <p className="workhistdocp">Documents</p>
        </div>
        {formvalues.documents[0]?.user_education_documents.length > 0 ? (
           <> 
            <Documentcard documents={formvalues.documents[0]?.user_education_documents} handelgeneraledit={handelgeneraledit} handleEyeClickimage={handleEyeClickimage} handledeletedocument={handledeletedocument}/>
            <div className='text-end buttonwidthdoc'>
            <Button label='New' icon={true} active={true} defaultclassname='docaddnew mt-3' onClick={adddocument}></Button>
            </div>
           </> 
        ):(
            <div className="d-flex mt-5 justify-content-center align-items-center flex-column generalnew ">
            {formvalues.isLoading ? (
               <>
               <SkeletonLoader documentRef={documentRef}/>
             </>
            ) : (
              <>
                <img src={adddocuemnt} alt="document" className="docorangeimage"></img>
                <h6 className="addnewgeneralh6 mt-2">New Document</h6>
                <p className="generalp generalp1">Upload and manage your academic documents effortlessly—store certificates, transcripts, training records, and other educational credentials from your school, college, or professional career. Keep all your academic achievements safe and accessible in one place</p>
                <Button defaultclassname='addnewgeneralbutton mt-2' label='Add Document' active={true} onClick={adddocument}/>
              </>
            )}
          </div> 
        )}
       </div>
       </div>
       </div>
       <Acdamicdocumentadd formvalues={formvalues} fetchdata={getdocuments} setIsLoading4={setIsLoading4} imageviewer={showimageviewer} closeimageviewer={closeimageviewer} showErrorToast={showErrorToast} show={formvalues.show} handleclose={handleclose} setFormvalues={setFormvalues}/>
       <Acdamicdocumentedit formvalues={formvalues} values={formvalues.editvalues} fetchdata={getdocuments} setIsLoading4={setIsLoading4} imageviewer={showimageviewer} closeimageviewer={closeimageviewer} showErrorToast={showErrorToast} show={formvalues.editshow} handleclose={handleclose} setFormvalues={setFormvalues}/>
       {formvalues.selectimage && 
      <ImageViewer isPreviewVisible={formvalues.ispreviewvisible} selectedImage1={formvalues.selectimage} onClose={closeimageviewer} isMultiImage={false}/>
      }
      {formvalues.selectdoc && <PDFViewer key={formvalues.uniquekey}  show={formvalues.ispdfvisible}  fileUrl={formvalues.selectdoc}  onClose={closeimageviewer} />}
    </>
  )
}

export default WithToast(Acdamicdocumentlist);