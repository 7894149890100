import React,{useState} from 'react';
import Cardheader from '../../component/carddesign';
import Commonheader from '../../component/commonheader';
import { useNavigate } from 'react-router-dom';
import dark from '../../images/dark_1-01.svg';
import light from '../../images/light_1-01.svg';
import system from '../../images/System_2-01.svg';
function Appearance({login_user}) {
    const navigate = useNavigate();
    const [selectedTheme, setSelectedTheme] = useState("system"); 
  return (
    <>
     <Commonheader/>
     <Cardheader text='Appearance' back={()=>navigate(`/u/${login_user}/security`)} footer={false}>
       <div className="d-flex align-items-center justify-content-evenly">
          {[
            { id: "dark", img: dark, label: "Dark" },
            { id: "light", img: light, label: "Light" },
            { id: "system", img: system, label: "System" },
          ].map((theme) => (
            <div key={theme.id} className="text-center">
              <img src={theme.img} alt={theme.label} className="mb-2 appearanceimage"/>
              <div className='mb-2 fw-medium'>{theme.label}</div>
              <div className='d-flex align-items-center justify-content-center'>
                <input
                  type="radio"
                  id={theme.id}
                  name="theme"
                  value={theme.id}
                  checked={selectedTheme === theme.id}
                  onChange={() => setSelectedTheme(theme.id)}
                  className="theme-radio"
                />
              </div>
            </div>
          ))}
        </div>
        <p className='accountcolorp mt-3 fw-medium'>If ‘system’ is selected, EVzone app will automatically adjust your appearance based on your device’s system settings.</p>
     </Cardheader>
    </>
  )
}

export default Appearance