import React, { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Commonheader from '../../component/commonheader';
import Cardheader from '../../component/carddesign';
import Button from '../../component/savebutton';
import ReusableModal from '../../component/reusablemodal';
import email from '../../images/Group 1171275973.svg';
import phonecall from '../../images/phone-call_5068731 1.svg'
import timecircle from '../../images/times-circle-11.svg';
import deleted from '../../images/Group 1171275974.svg';
const Accountdetails = ({ login_user }) => {
  const navigate = useNavigate();
  const goBack = useCallback(() => navigate(`/u/${login_user}/security`), [navigate, login_user]);
  const [showscreen , setShowscreen] = useState({first:true,second:false,third:false,showmodal:false,active_button:false,four:false});
  const [state,setState] = useState({selectvalue:'',selectreason:'',othertext:'',deletevalue:''});
  const back = () => {
    if (showscreen.first) {
      goBack();
    } else if (showscreen.third) {
      setShowscreen((prev) => ({ ...prev, third: false, second: true }));
    } else if (showscreen.second) {
      setShowscreen((prev) => ({ ...prev, second: false, first: true }));
    }
  };
  const options = [
    {
      id: 'deactivate',
      title: 'Deactivate account',
      description: 'Your profile won’t be shown on EVzone and active orders will be cancelled.',
    },
    {
      id: 'delete',
      title: 'Delete account',
      description: 'Deleting your account is permanent and irreversible. You won’t be able to retrieve the files or information from your orders on EVzone.',
    },
  ];
  const secondoptions = [
    {
        id:'deactivate',
        title :'What happens when you deactivate your account?',
        desc  : 'Your profile won’t be shown on EVzone anymore. Active orders will be cancelled, not including delivered orders.',
        points: ['Any usernames and email addresses associated with this account cannot be reused on new accounts in the future.','Closing your account doesn’t automatically remove the information on the account. Feedback comments, messages, and public pictures remain.'],
        fottertitle : 'If you would like to reactivate your account, please reach out to us at support@evzone.com.'
    },{
        id:'delete',
        title : 'What happens when you delete your account?',
        desc  : 'The deletion process is permanent and cannot be reversed. It might take up to 30 days to complete the process.',
        points: ['Any usernames associated with this account cannot be reused on new accounts in the future.','We recommend reviewing your orders and messages before you request it so that you can retrieve any files and information you might need in the future.'],
        fottertitle:''
    }
  ]
  const reasons = [
    "Unsubscribe from EVzone emails",
    "I want to change my username",
    "I have another EVzone account",
    "I can’t find what I need on EVzone",
    "EVzone is complicated or hard to use",
    "Negative experience with seller/s",
    "I’m unhappy with EVzone’s policies",
    "Other",
  ];
  const thirdoption = [
    {
      id:'deactivate',
      title:'Your Account Has Been Deactivated',
      name:'Hello, John!',
      sort_desc:'We’re sorry to see you go.',
      desc:'Your account is currently deactivated. To regain access to your data and services, simply click the button below to reactivate.',
      email:'johndoe@example.com',
      mobile:'(555) 123-4567',
      date:'WEDNESDAY,MARCH 7,2025',
    },
    {
      id:'delete',
      title:'Your Account Has Been Deleted',
      name:'Hello, John!',
      sort_desc:'We’re sorry to see you go.',
      desc:`Your account was deleted, and all associated data and services are no longer accessible. If this was a mistake or if you'd like to reconsider, please contact our support team for further assistance.`,
      email:'johndoe@example.com',
      mobile:'(555) 123-4567',
      date:'WEDNESDAY,MARCH 7,2025',
    },
  ]
  const thirdactive = useCallback(() => {
    const value = state.selectreason === 'Other' ? state.othertext.trim() !== '' : !!state.selectreason;
    setShowscreen((prev) => ({ ...prev, active_button: value }));
  }, [state.selectreason, state.othertext]);
  
  useEffect(() => {
    thirdactive();
  }, [thirdactive]);
  return (
    <>
      {!showscreen.four && 
      <>
      <Commonheader />
      <Cardheader text={state.selectvalue === ''
    ? "Deactivation and deletion"
    : state.selectvalue === 'deactivate' && (showscreen.second === true || showscreen.third === true)
    ? "Deactivate account" : state.selectvalue === 'delete' && (showscreen.second === true || showscreen.third === true) ? 'Delete account' 
    : "Deactivation and deletion"} back={back} footer={false}>
        {showscreen.first && (
         <>
        <h5 className="fw-bold">Deactivating or deleting your EVzone account</h5>
        <p className="fs-6">
          If you want to temporarily close your account, you can deactivate it. If you want to permanently remove your data from EVzone, you can delete your account.
        </p>
        {options.map(({ id, title, description }) => (
          <div key={id} className={`p-3 rounded mb-3  ${state.selectvalue === id ? 'accountselectborder' : 'border'}`}>
            <div className="d-flex gap-3">
              <input type="radio" id={id} className='theme-radio' name="account_action" checked={state.selectvalue === id} onChange={()=>setState((prev)=>({...prev,selectvalue:id}))} value={id} />
              <div className='accountdetails_check'>
                <h6 className='fw-bold'>{title}</h6>
                <p className="mb-0">{description}</p>
              </div>
            </div>
          </div>
        ))}
        <Button label={state.selectvalue === 'deactivate' ? 'Continue to Account Deactivation' : state.selectvalue === 'delete' ? 'Continue to Account Deletion' : 'Continue'} enabled={state.selectvalue} onClick={()=>setShowscreen((prev)=>({...prev,second:true,first:false}))} activeclassname='accountdetailsbutton_active cursor_pointer' disabledclassname='accountdetailsbutton_disabled cursor_notallowed' defaultclassname='accountdetailsbutton mt-2' />
        </>
        )}
        {showscreen.second && (
         <>
          {secondoptions
              .filter((option) => option.id === state.selectvalue)
              .map(({ id, title, desc, points, footerTitle }) => (
              <>  
          <div key={id}>  
         <h5>{title}</h5>
         <p>{desc}</p>
         <ul>
         {points.map((point, index) => (
            <li key={index} className='mb-3'>{point}</li>
         ))}
         </ul>
         {footerTitle && <h5>{footerTitle}</h5>}
         </div>
         </>
            ))}
        <Button label={state.selectvalue === 'deactivate' ? 'Continue to Account Deactivation' : 'Continue to Account Deletion' } onClick={()=>setShowscreen((prev)=>({...prev,third:true,second:false,first:false}))} defaultclassname='accountdetailsbutton accountdetailsbutton_active cursor_pointer  mt-2' />
         </>   
        )}
        {showscreen.third && (
          <>
          <h5 className='mb-4'>I’m leaving because</h5>
          {reasons.map((reason, index) => (
            <div key={index} className="d-flex align-items-center gap-3 mb-3">
                <input type="radio"  id={`reason-${index}`}  className="theme-radio" name="account_action" value={reason} checked={state.selectreason === reason} onChange={()=>setState((prev)=>({...prev,selectreason:reason}))} />
                <label className='font_weight500' htmlFor={`reason-${index}`}>{reason}</label>
            </div>
            ))}
             {state.selectreason === "Other" && (
             <>   
             <label className='font_weight500 mb-2' htmlFor='othertextarea'>Tell us more (optional)</label>   
             <textarea className="form-control resize_textarea mb-2"  placeholder="Help us become better..." value={state.othertext} onChange={(e) => setState((prev) => ({ ...prev, othertext: e.target.value }))}
            />
            </>
        )}
        <Button label={state.selectvalue === 'deactivate' ? 'Deactivate Account' : 'Delete Account' } enabled={showscreen.active_button} onClick={()=>setShowscreen((prev)=>({...prev,showmodal:true,third:true,second:false,first:false}))} activeclassname='accountdetailsbutton_active cursor_pointer' disabledclassname='accountdetailsbutton_disabled cursor_notallowed' defaultclassname='accountdetailsbutton mt-2' />
          </>  
        )}
      </Cardheader>
      </>
      }  
      <ReusableModal show={showscreen.showmodal} size='md' handleClose={()=>setShowscreen((prev)=>({...prev,showmodal:false}))}>
       {state.selectvalue === 'deactivate' ? (
       <> 
       <h5>Deactivate EVzone account?</h5>
       <p>Your profile will not be visible on EVzone And any active orders will be canceled.</p>
       <p>Any usernames or email addresses associated with this account cannot be reused on future accounts.</p>
       </>
       ) : (
        <>
        <h5>Delete EVzone account</h5>
        <p>This action is permanent. All account data will be deleted and cannot be recovered.</p>
        <p>Usernames and email addresses from this account cannot be used for future accounts.</p>
        <p>To delete your EVzone account, type DELETE.</p>
        <input type='text' placeholder='DELETE' className={`form-control account_input mb-3 ${state.deletevalue ? 'account_input_active' : ''}`} value={state.deletevalue.toUpperCase()} onChange={(e) => setState((prev) => ({ ...prev, deletevalue: e.target.value }))}></input>
        </>
       )}
        <div className='d-flex gap-3 align-items-center justify-content-end'>
        <p className='mb-0 cursor_pointer' onClick={()=>setShowscreen((prev)=>({...prev,showmodal:false}))}>Cancel</p>
        <p className={`mb-0 cursor_pointer ${state.selectvalue === 'deactivate' ? 'connectdevicegreen' : 'accountdeletecolor fw-bold'}`} onClick={()=>setShowscreen((prev)=>({...prev,four:true,showmodal:false,third:false,second:false,first:false}))}>{state.selectvalue === 'deactivate' ? 'Deactivate Account' : 'Delete Account'} </p>
       </div>
      </ReusableModal>
      {showscreen.four && (
      <div className='pad-5'>
       <div className='bgbackcolor d-flex align-items-center justify-content-center mb-3'>
        <img src={state.selectvalue === 'deactivate' ? timecircle : deleted} alt='circle'></img>
        </div>
        {thirdoption.filter(item => state.selectvalue === item.id).map((item, index) => (
        <>  
        <h5 key={index} className='fw-bold mb-3'>{item.title}</h5>
        <h6 className='fw-bold '>{item.name}</h6>
        <p className='mb-3'>{item.sort_desc}</p>
        <p className='colordecription'>{item.desc}</p>
        <div className='d-flex align-items-center gap-3 mb-3'>
         <img src={email} alt='email' className='emailimg'></img>
         <p className=' bluecolur fw-bold mb-0'>{item.email}</p> 
        </div>
        <div className='d-flex align-items-center gap-3 mb-3'>
         <img src={phonecall} alt='email' className='emailimg'></img>
         <p className=' redcolur fw-bold mb-0'>{item.mobile}</p> 
        </div>
        <p >{state.selectvalue === 'deactivate' ? 'Date of Deactivation' : 'Date of Deletion'} :<strong> {item.date}</strong></p>
        <p>Reason : <strong className='redcolur'>{state.selectreason === 'Other' ? state.othertext : state.selectreason}</strong></p>
        <div className='d-flex align-items-center gap-3'>
          {state.selectvalue === 'deactivate' ? (
          <>  
          <Button defaultclassname='accountdetailsbutton1 rounded accountdetailsbuttonlogout' label='Logout' onClick={goBack}></Button>
          <Button defaultclassname='accountdetailsbutton1 rounded accountdetailsbuttonactivate' label='Reactivate' onClick={goBack}></Button>
          </>
        ) : (
         <>
         <Button defaultclassname='accountdetailsbutton1 rounded accountdetailsbuttonlogout' label='Signup' onClick={goBack}></Button>
         <Button defaultclassname='accountdetailsbutton1 rounded accountdetailsbuttonactivatered' label='Exit' onClick={goBack}></Button>
         </>
        )}
        </div>
        <div className='d-flex align-items-center mt-3 gap-2 colordecription'>Need help?  <p className='colordecription bluecolur mb-0'><Link to={`/u/${login_user}/security/contact/support`}>Contact Support</Link></p></div>
        </>
        ))}
      </div>
      )}
    </>
  );
};

export default Accountdetails;
