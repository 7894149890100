import React from 'react'
import rightarrow from "../images/Vector 337.svg";
function Boxcontainer({children,heardervalue,forward,arrow=false,newclass=''}) {
  return (
    <>
    <div className='p-4 box-container_div'>
    <div className={`d-flex justify-content-between align-items-center ${arrow ? '' : ''}`}>  
    <h4 className={`heading ${arrow ? 'mb-0' : 'mb-3'}`}>{heardervalue}</h4>
    {arrow &&(
    <img src={rightarrow} alt='arrow' className={`cursor_pointer ${newclass}`} onClick={forward} ></img>)}
    </div>
        {children}
        </div>
    </>
  )
}

export default Boxcontainer