import React from 'react';
import leftarrow from '../images/arrow-left-01.png';
import Button from './savebutton';
function Cardheader({children,back=()=>{},text,cancel=()=>{},next=()=>{},isenabled=true,footer=true,div_classname='name-input d-flex flex-column justify-content-start'}) {
  return (
    <>
    <div className="name_div">
    <div className="name_div1">
            <div className="d-flex align-items-center">
              <img src={leftarrow} onClick={back} alt="leftarrow" className="name_arrow "></img>
              <h5 className="nameh5">{text}</h5>
            </div>
            <div className={div_classname}>
             {children} 
              {footer && (
             <div className="name_button_div">
                 <Button onClick={cancel} defaultclassname='profilecancel me-3' label="Cancel" active={true}/> 
                 <Button onClick={next} defaultclassname="profilesave" enabled={isenabled} disabledclassname="profilesave_disabled" activeclassname="profilesave_active" label="Save"/>
              </div>
              )}   
              </div>
           </div> 
    </div>
    </>
  )
}

export default Cardheader