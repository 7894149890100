import React from 'react';
import file from "../images/job_details_icons/Document_green-01.png";
import { getFormattedDate} from '../api/utils/utils';
import edit from '../images/job_details_icons/edit_1-01.png';
import eye from "../images/job_details_icons/eye_green-01.png";
import cancel from '../images/add_document/cancel-02.png';

function Documentcard({documents, handleEyeClickimage, handledeletedocument, handelgeneraledit}) {

  return (
    <>
    {documents?.map((document, index) => ( 
      <div key={index} className="docbox">
        <div className="d-flex justify-content-between align-items-center" style={{gap:'20px'}}>
          <div className="d-flex align-items-center divshow140130 ">
            <img src={file} alt="file" width="60px" className="me-3" height="auto" />
            <div style={{width:'100%', overflow:'hidden'}}>
              <p className="docviewp truncated-text">Document Type</p>
              <p className="docviewp2 truncated-text">
                {document?.document_country?.document_type?.name || document?.document_type_name || document?.vehicle_document_country?.name}
              </p>
            </div>
          </div>
          <div className="divshow140130" style={{overflow:'hidden',}} >
            <p className="docviewp truncated-text">Document name</p>
            <p className="docviewp2 truncated-text">
              {document.document_name}
            </p>
          </div>
          <div className="divshow140120" style={{overflow:'hidden',}} >
            <p className="docviewp truncated-text">Date of Issue</p>
            <p className="docviewp2 truncated-text">
              {getFormattedDate(document.issued_at)}
            </p>
          </div>
          <div className="divshow140120" style={{overflow:'hidden',}}>
            <p className="docviewp truncated-text">Expire Date</p>
            <p className="docviewp2 truncated-text">
              {document.expired_at ? getFormattedDate(document.expired_at) : '\u00A0'}
            </p>
          </div>
          <div className="d-flex align-items-center justify-content-end divshow140150end" style={{width:'20%'}}>
          <div className="divshow140150" >
            <button className="docedit me-3" onClick={() => handelgeneraledit(document)}>
              <img className="me-3" src={edit} alt="edit" width="18px" height="auto"/>
              Edit
            </button>
            </div>
            <div className="d-flex flex-column justify-content-end align-items-end divshow140150" >
              <img
                className="mb-2 documenteyeview"
                src={eye}
                onClick={() => handleEyeClickimage(document)}
                alt="eye"
              />
              <img
                className="documenteyeview"
                src={cancel}
                onClick={() => handledeletedocument(document)}
                alt="file"
                width="29.89px"
                height="auto"
              />
            </div>
          </div>
        </div>
      </div>
    ))}
    </>
  )
}

export default Documentcard;