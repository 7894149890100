import React from 'react';
function Authloader() {
  return (
    <>
     <div className="loader-container-auth">
     <div className="loader-auth"></div>
     </div>
    </>
  )
}

export default Authloader;