import React from 'react'
import ReusableModal from './reusablemodal'
import Button from './savebutton'
import closecircle from '../images/times-circle.svg'
function Documentheaderfooter({show,onClose,title,children,save=()=>{},enabled=true}) {
  return (
    <>
    <ReusableModal show={show} handleClose={onClose}>
     <div className="d-flex align-items-center justify-content-between docpop1">
            <h6 className="docpoph6">{title}</h6>
            <img src={closecircle} onClick={onClose} alt="close" className="docpopclose"></img>
     </div>
     {children}
     <div className='d-flex align-items-center justify-content-end mt-4 mb-2'>
        <Button label='Cancel' onClick={onClose} active={true} defaultclassname='docpopcancel me-3'></Button>
        <Button label='Save' onClick={save} defaultclassname='docpopsave' enabled={enabled}  activeclassname='docpopsave_active' disabledclassname='docpopsave_disabled' ></Button>
      </div>
     </ReusableModal>
    </>
  )
}

export default Documentheaderfooter