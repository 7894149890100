import { fetchRelationListPending, fetchRelationListFulfilled, fetchRelationListRejected } from '../slices/relationSlice';
import { getEmergencyRelationList} from '../../../api/helper/apiurlhelper';
import { HandleCalls} from '../../utils/utils'; 

export const fetchRelations = (showErrorToast) => async (dispatch,getState) => {
    const { relation } = getState();
    if (relation.relationOptions.length > 0) return;
  try{
  dispatch(fetchRelationListPending());
  const response = await  HandleCalls({apiCall: () => getEmergencyRelationList(),showErrorToast: showErrorToast});
  if (response.status === 200) {
    const mappedRelations = response.data.data.map((relation) => ({
      value: relation.id,
      label: relation.name,
    }));
    dispatch(fetchRelationListFulfilled(mappedRelations));
  }
  }catch(error){
    dispatch(fetchRelationListRejected(error.response?.data || error.message));
  }
};
