import { configureStore } from '@reduxjs/toolkit';
import userProfile from './userProfiles/reducers';
import relationReducer from './reducers/relationreducer';
import documentTypeReducer from './reducers/documentTypeReducer';
import workTypeReducer from './reducers/workTypeReducer';
import employementTypeReducer from './reducers/employementTypeReducer';
import acdamicLevelReducer from './reducers/acdamicLevelReducer';

const store = configureStore({
  reducer:{
  userProfile: userProfile,
  relation: relationReducer,
  documenttypes: documentTypeReducer,
  workType: workTypeReducer,
  employmentType: employementTypeReducer,
  academicLevels: acdamicLevelReducer,
  }
});
export default store;

