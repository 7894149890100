import React from "react";
import { DatePicker } from "antd";
import { CustomSuffixIcon } from "../api/utils/utils";

const CustomDatePicker = ({
  value,
  onChange,
  placeholder = "MM/YY",
  disabledDate,
  picker = "month",
  format = "MM/YYYY",
  onClick,
  open, 
  onOpenChange, 
  customStyles = {},
  onKeyDown
}) => {
  return (
    <DatePicker
      value={value}
      {...(onClick && { onClick })}
      {...(open !== undefined && { open })} 
      {...(onOpenChange && { onOpenChange })} 
      disabledDate={disabledDate}
      onChange={onChange}
      picker={picker}
      placeholder={placeholder}
      format={format}
      className={`${value ? "ant-picker-active" : "ant-picker"}`}
      style={{
        borderColor: value ? "black" : "#d9d9d9",
        boxShadow: "none",
        padding: ".375rem .75rem",
        borderRadius: ".25rem",
        ...customStyles
      }}
      getPopupContainer={(trigger) => trigger.parentNode}
      suffixIcon={<CustomSuffixIcon isActive={value}/>}
      onKeyDown={onKeyDown}
    />
  );
};

export default CustomDatePicker;
