import { createContext, useContext} from "react";
import { fileUpload } from "../../api/helper/apiurlhelper";
import { HandleCalls} from "../utils/utils";
import WithToast from "./withtoasthook";
const UploadContext = createContext();

export const UploadProvider = WithToast(({ children,showErrorToast }) => {
//   const [isLoading4,setIsLoading4] = useState(false);
  const switchtype = (type) => {
    let path, prefix;
    switch(type){
      case 'profile' : 
      path='user/profile';
      prefix='image';
      break;
      case 'national' : 
      path='user/national';
      prefix='doc';
      break;
      case 'general' : 
      path = 'user/general';
      prefix = 'doc';
      break;
      case 'workhistory':
      path = 'user/work';
      prefix= 'doc';
      break;
      case 'acdamichistory':
      path = 'user/acdamic';
      prefix= 'doc';
      break;
      case 'emergency':
      path = 'user/emergency';
      prefix = 'image'
      default : 
      path = 'user/profile';
      prefix = 'image';
    }
    return { path, prefix }; 
  }
  const uploadFile = async (image,type="profile",setIsLoading4) => {
    if (!image) return null;
      const module = "core";
      const types = switchtype(type);
      const formData = new FormData();
      formData.append("file", image);
      formData.append("module", module);
      formData.append("path", types.path);
      formData.append("prefix", types.prefix);

      const response = await HandleCalls({apiCall:()=>fileUpload(formData),showErrorToast:showErrorToast,setIsLoading:setIsLoading4});
      return response?.data?.data?.path || null;
  };

  return (
    <UploadContext.Provider value={{uploadFile}}>
      {children}
    </UploadContext.Provider>
  );
});

export const useUpload = () => {
  return useContext(UploadContext);
};
