import { createSlice } from '@reduxjs/toolkit';

const workTypeSlice = createSlice({
  name: 'workType',
  initialState: {
    workTypeOptions: [],
    loading: false,
    error: null,
  },
  reducers: {
    fetchWorkTypeListPending: (state) => {
      state.loading = true;
      state.error = null;
    },
    fetchWorkTypeListFulfilled: (state, action) => {
      state.loading = false;
      state.workTypeOptions = action.payload;
    },
    fetchWorkTypeListRejected: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  fetchWorkTypeListPending,
  fetchWorkTypeListFulfilled,
  fetchWorkTypeListRejected,
} = workTypeSlice.actions;

export default workTypeSlice.reducer;
