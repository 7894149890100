import React from 'react';
import Boxcontainer from '../../component/boxcontainer';
import { useNavigate } from 'react-router-dom';
import { extractLoginUser } from '../../api/utils/utils';

function Appearanceseprate() {
    const navigate = useNavigate();
    const login_user = extractLoginUser();
  return (
    <>
      <Boxcontainer heardervalue='Appearance' forward={(()=>navigate(`/u/${login_user}/security/appearance`))} arrow={true}>
      </Boxcontainer>
    </>
  )
}

export default Appearanceseprate