import React, { useEffect, useState } from 'react';
import WithToast from '../../api/hooks/withtoasthook';
import linux from '../../images/142_Linux computer(s) 1.svg';
import windows from '../../images/144_Windows computer(s) 1.svg';
import macos from '../../images/143_Mac device(s) 1.svg';
import mobile from '../../images/Vector.svg';
import groupimg from '../../images/Group.svg';
import { getUserSessionDetails, removesession } from '../../api/helper/apiurlhelper';
import {HandleCalls, handleCalls } from '../../api/utils/utils';
import Loader from '../../component/loader';
import { useNavigate } from 'react-router-dom';
import Boxcontainer from '../../component/boxcontainer';
import Cardheader from '../../component/carddesign';
import Commonheader from '../../component/commonheader';
function Connectdevices({showErrorToast,login_user}) {
      const [sessionget, setSessionget] = useState([]);
      const [isLoading4,setIsLoading4] = useState(false);
      const navigate = useNavigate();
      const [arrowexpand, setArrowexpand] = useState(false); 
    const selectimage = (type) =>{
     switch(type){
        case 'linux' : 
        return linux;
        case 'windows' : 
        return windows;
        case 'macos' : 
        return macos;
        case 'android' : 
        return mobile; 
        case 'ios' : 
        return mobile; 
        default:
        return'';    
     }
    }
    const sessiondetails = async(setIsLoading4,page = 1,limit=3) => {
       const response = await handleCalls({apiCall:()=>getUserSessionDetails({ page,limit }),showErrorToast:showErrorToast,...(setIsLoading4 && { setIsLoading4 })});
        if(response?.status === 200){
            const session = response.data.data.values;
            setSessionget(session);
        }
    }
    useEffect(()=>{
        if(window.location.pathname.includes('connecteddevices')){
            setArrowexpand(true);
            sessiondetails('',1,100);
          }else{
           setArrowexpand(false);
           sessiondetails();
        }
    },[]);
    const signout = async(id) =>{
        setIsLoading4(true);
        const data = {
          session_id : id
        }
        const response = await HandleCalls({apiCall:()=>removesession(data),showErrorToast:showErrorToast,setIsLoading:setIsLoading4});
        if(response?.status === 200){
            await sessiondetails(setIsLoading4);
        }
        setIsLoading4(false);
      }
      const Session_list = () => {
       return(
        sessionget.length > 0 &&   
            sessionget.map((item, index) => (        
    <div key={index} className='d-flex align-items-center justify-content-between border mb-3 rounded p-3'>
        <div className='d-flex align-items-center gap-3 accounts_width accounts_width90'>
       <div className='connectdevice58'>   
       <img src={selectimage(item.request_agent === 'WEB' ? item.useragent.platform : item.userdevice.device_type)} alt='model'></img>
       </div>
       <div className='text-start connect_accounts_80'>
        <h6 className='mb-0 elipse connectdevicegreen'>{item.request_agent === 'WEB' ? item.useragent.platform + ' ' + (item.useragent.platform === ('windows' || 'linux') ? 'computer' : 'device' ) : item.userdevice.device_type + " " + 'phone'}</h6>
        <p className='mb-0 elipse'>{item.country.name}</p>
        {item.request_agent === 'WEB' && ( 
        <p className='mb-0 elipse'>{item.useragent.browser}</p>
        )}
        </div> 
        </div>
        <div className='accounts_width10 text-end'>
         <img src={groupimg} alt='group' onClick={()=>signout(item.id)} className='cursor_pointer'></img> 
        </div>
    </div>
    ))
       )
      }
  return (
    <>
     {isLoading4 && (<Loader/>)}
     {!arrowexpand  ? ( 
     <Boxcontainer heardervalue="Connected devices" forward={()=>navigate(`/u/${login_user}/security/connecteddevices`)}   arrow={true} >
     <p className='fw-500 fs-6 accountcolorp'>You’re signed in on these devices or have been in the last 28 days. There might be multiple sessions from the same device. <span className='findout'>Find out more</span></p>
     <div className='mt-3'>
      <Session_list/>
     </div>
     </Boxcontainer>
     ) : (
        <>
       <Commonheader/>  
      <Cardheader text='Connected devices' back={()=>navigate(`/u/${login_user}/security`)} footer={false}>
          <Session_list/>
      </Cardheader>
      </>  
     )}   
    </>
  )
}

export default WithToast(Connectdevices);