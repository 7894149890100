import React, { useEffect, useState,useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { extractLoginUser ,getLocalStorageItem,handleBeforeInput,handleCalls,handlePaste, setLocalStorageItem, validateEmailValue} from "../api/utils/utils";
import WithToast from "../api/hooks/withtoasthook";
import Loader from "../component/loader";
import Commonheader from "../component/commonheader";
import Cardheader from "../component/carddesign";
import { updateRecoveryVerify, updateSendOtp } from "../api/helper/apiurlhelper";
function Recoveryemail({showErrorToast}) {
  const login_user = extractLoginUser();
  const [state, setState] = useState({isEmailValid: false,isOtpValid: false,showEmailScreen: true,showOtpScreen: false,});
  const [formvalues,setFormvalues] = useState({otp:'',email:''});
  const [error422, setError422] = useState('');
  const [isLoading4, setIsLoading4] = useState(false);
  const navigate = useNavigate();
  const handlekey = (e) => {
    handleBeforeInput(e);
  }
  const back = () => {
    navigate(`/u/${login_user}/security`);
    setError422('');
  }
  const validateEmail = useCallback(() => {
      setState((prev) => ({ ...prev, isEmailValid : formvalues.email !== '' }));
  }, [formvalues.email]);
  useEffect(validateEmail, [validateEmail]);
  const next = async () => {
    const emailPattern = validateEmailValue(formvalues.email);
    setError422(emailPattern ? '' : 'Please enter a valid email address.');
    if(!emailPattern) return;
    const data = {
      email: formvalues.email,
    }
    const after_successcall = (response) => {
      setError422('');
      const recovery_token = response.data.data.token;
      setLocalStorageItem('recovery_token',recovery_token);
      setState((prev)=>({
        ...prev,showEmailScreen:false
      }))
    }
    handleCalls({apiCall:()=>updateSendOtp(data),successCall:after_successcall,setIsLoading:setIsLoading4,setError422:setError422,showErrorToast:showErrorToast,handle422AsToast:false});
  }
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormvalues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
    setError422('');
  };
  const backs = () => {
    setFormvalues((prev)=>({
      ...prev,otp:''
    }))
    setState((prev)=>({
      ...prev,showEmailScreen:true
    }));
  }
  const validateOtp = () => {
    setState((prev) => ({ ...prev, isOtpValid: formvalues.otp !== "" }));
  };

  useEffect(validateOtp, [formvalues.otp]);
  const confirmcode = async() => {
    const data = {
      email: formvalues.email,
      otp : formvalues.otp ,
      token : getLocalStorageItem('recovery_token')
    }
    handleCalls({apiCall:()=>updateRecoveryVerify(data),successCall:back,setIsLoading:setIsLoading4,showErrorToast:showErrorToast,setError422:setError422,handle422AsToast:false,errorCall:back});
  }
  return (
    <>
      {isLoading4 && <Loader/>}
      <Commonheader/>
          <Cardheader text={state.showEmailScreen ? "Recovery Email" : "Verify Email"} back={state.showEmailScreen ? back : backs} cancel={back} next={state.showEmailScreen ? next : confirmcode} isenabled={state.showEmailScreen ? state.isEmailValid : state.isOtpValid}>
            {state.showEmailScreen ? (
             <> 
            <label htmlFor="inputField"  className="doc_label">Email Address</label>
            <input type="email" name="email" value={formvalues.email} onChange={handleChange} className={`form-control docform ${formvalues.email ? 'docform-active' : ''}`}  id="inputField" placeholder="Enter Email" />
            </>
            ) : (
              <>
              <label htmlFor="inputField"  className="doc_label">Verification Code</label>
              <input type="text" name="otp" value={formvalues.otp} onChange={handleChange} maxLength={6} onPaste={(e) => handlePaste(e, (val) => {setFormvalues((prev) => ({ ...prev, otp: val }));setError422('')})} onBeforeInput={handlekey} className={`form-control docform ${formvalues.otp ? 'docform-active' : ''}`}  id="inputField" placeholder="Enter Verification Code" />
              </>
            )}
            <div className="error-message">{error422}</div>
          </Cardheader>
    </>
  );
}
export default WithToast(Recoveryemail);