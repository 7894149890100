import React, { useState, useCallback, useEffect } from 'react';
import { useResizeObserver } from '@wojtekmaj/react-hooks';
import { pdfjs, Document, Page } from 'react-pdf';
import Modal from 'react-bootstrap/Modal';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import Spinner from 'react-bootstrap/Spinner';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url,
).toString();

const options = {
  cMapUrl: '/cmaps/',
  standardFontDataUrl: '/standard_fonts/',
};

const maxWidth = 800;
const resizeObserverOptions = {};

function PDFViewer({ fileUrl, onClose,show }) {
  // const [file, setFile] = useState(fileUrl || null);
  const [numPages, setNumPages] = useState(null);
  const [containerRef, setContainerRef] = useState(null);
  const [containerWidth, setContainerWidth] = useState(null);
  // const [isModalOpen, setIsModalOpen] = useState(!!fileUrl);
  const [isLoading, setIsLoading] = useState(true);

  const onResize = useCallback((entries) => {
    const [entry] = entries;
    if (entry) {
      setContainerWidth(entry.contentRect.width);
    }
  }, []);

  useResizeObserver(containerRef, resizeObserverOptions, onResize);

  // useEffect(() => {
  //   if (fileUrl) {
  //     setFile(fileUrl);
  //     setNumPages(null);
  //     setIsModalOpen(true);
  //     setIsLoading(true);
  //   }
  // }, [fileUrl]);

  function onDocumentLoadSuccess({ numPages: nextNumPages }) {
    setNumPages(nextNumPages);
    setIsLoading(false);
  }

  const close = () => {
    // setFile(null);
    // setIsModalOpen(false);
    setNumPages(null);
    if (onClose) onClose();
  };
  
  return (
    <div className="Example">
      <Modal show={show} onHide={close} dialogClassName='custom-modal-size' size="lg">
        <Modal.Header closeButton>
          <Modal.Title>PDF Viewer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isLoading && (
            <div className="loading-indicator" style={{height:'70vh',display:'flex',justifyContent:'center',alignItems:'center'}}>
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          )}
          <div className={`Example__container__document ${isLoading ? 'd-none' : ''}`} ref={setContainerRef}>
            <Document
              file={fileUrl}
              onLoadSuccess={onDocumentLoadSuccess}
              options={options}
              loading={<></>}
              noData={<></>}
              onLoadError={(error) => {
                if (error.name !== 'AbortException') {
                  console.error(error);
                }
              }}
            >
              {Array.from(new Array(numPages), (el, index) => (
                <Page
                  key={`page_${index + 1}`}
                  pageNumber={index + 1}
                  width={containerWidth ? Math.min(containerWidth, maxWidth) : maxWidth}
                  loading={false}
                  renderAnnotationLayer={false}
                  renderTextLayer={false}
                />
              ))}
            </Document>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default PDFViewer;
