import React from 'react'
import Boxcontainer from '../../component/boxcontainer'
import { useNavigate } from 'react-router-dom'

function Accountseprate({login_user}) {
    const navigate = useNavigate();
  return (
    <>
    <Boxcontainer heardervalue='Accounts details' forward={(()=>navigate(`/u/${login_user}/security/accountdetails`))} arrow={true}>
    </Boxcontainer>
    </>
  )
}

export default Accountseprate