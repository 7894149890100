import React, { useEffect } from "react";
import Navbars from "./navbar";
import Sidebar from "./sidebar";
import './home.css';
import { useState } from "react";
import school from "./images/school_green-01.png";
import evmarket from "./images/school_orange-01.png";
import googleplay from "./images/google_play-01.png";
import appstore from "./images/app_store-01.png";
import faithub from './images/religious_channel-01.png';
import jobposting from './images/see_job_posting-04.png';
import lanch from './images/launch_now-04.png';
import info from './images/info-04.png';
import { useNavigate } from "react-router-dom";
function Services() {
    const [isAppRowVisible, setIsAppRowVisible] = useState(false);
    const navigate = useNavigate();
    const toggleAppRow = () => {
        setIsAppRowVisible(!isAppRowVisible);
    };
    const jobpostings = () => {
        navigate('/jobpostings')
    }
    const [infoBoxVisible, setInfoBoxVisible] = useState(false);
    const [infoBoxVisible1, setInfoBoxVisible1] = useState(false);
    const [infoBoxVisible2, setInfoBoxVisible2] = useState(false);
    return (
        <>
            <Navbars />
            <div className="container-fluid bg-white service p-0">
                <div className="documentrow">
                    <div className=" documentside">
                        <Sidebar />
                    </div>
                    <div className=" documentmain">
                        <div className="box p-3 border-rounded pt-3 px-md-4 mb-4" style={{ backgroundColor: 'white', width: '100%', minHeight: '370px', border: '1px solid #ABA7A7', borderRadius: '18px' }}>
                            <div className="d-flex justify-content-between sevicediv align-items-center  pt-4">
                                <h5 className="seviceheading">Client App Services<img src={info} onMouseEnter={() => setInfoBoxVisible1(true)}  onMouseLeave={() => setInfoBoxVisible1(false)} className="infoimg mx-2" alt="info"></img></h5>
                                <div>
                                    <button className="googleplay me-sm-4 me-md-4"><img className="me-1 googleplayimage" src={googleplay} alt="google" width='17px' height='17px'></img>Google Play</button>
                                    <button className="appstore  me-4 me-md-0 me-sm-0"><img className="me-1 appstoreimage" src={appstore} alt="appstore" width='17px' height='17px'></img>App Store</button>
                                </div>
                            </div>
                            {infoBoxVisible1 && (
                                    <div className="info1box1" onMouseEnter={() => setInfoBoxVisible1(true)}  onMouseLeave={() => setInfoBoxVisible1(false)}>
                                       When You down load our User App, these are the mini applications you will see
                                    </div>
                                )}
                            <div className="row serviceboxrow mt-4 ">
                                <div className="col-12 serviceboxbottom  col-sm-6 col-md-6 col-lg-4 mb-sm-3">
                                    <div className="box-container servicebox border p-3   ">
                                        <img
                                            src={school}
                                            alt="school"
                                            className="img-fluid  d-md-block servicepageimg"
                                        />
                                        <h6 className="mb-2 mt-2 servicepageh6">School</h6>
                                        <p className="servicepagecomment mt-2 mb-3">Some gate players pain,employee the employees of the company</p>
                                        {/* New elements inside the first box */}
                                        <div className="d-flex align-items-center mb-0 justify-content-between">
                                            <span className=" mt-1 mb-0 servicepageupdatedate ">Joined on April,2023</span>
                                            <button className=" about mb-0">About</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6 serviceboxbottom col-md-6 col-lg-4 mb-sm-3">
                                    <div className="box-container servicebox border p-3  ">
                                        <img
                                            src={evmarket}
                                            alt="school"
                                            className="img-fluid  d-md-block servicepageimg"

                                        />
                                        <h6 className="mb-2 mt-2 servicepageh6">EVMarket Place</h6>
                                        <p className="servicepagecomment mt-2 mb-3">Some gate players pain,employee the employees of the company</p>
                                        {/* New elements inside the first box */}
                                        <div className="d-flex align-items-center  justify-content-between">
                                            <span className=" mt-1 mb-0 servicepageupdatedate" >Joined on April,2023</span>
                                            <button className=" about mb-0 ">About</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6 serviceboxbottom col-md-6 col-lg-4 mb-sm-3" >
                                    <div className="box-container servicebox serviceboxborder p-3  " >
                                        <img
                                            src={faithub}
                                            alt="school"
                                            className="img-fluid  d-md-block servicepageimg"

                                        />
                                        <h6 className="mb-2 mt-2 servicepageh6">FaithHub</h6>
                                        <p className="servicepagecomment mt-2 mb-3">Some gate players pain,employee the employees of the company</p>
                                        {/* New elements inside the first box */}
                                        <div className="d-flex align-items-center  justify-content-between">
                                            <span className=" mt-1 mb-0 servicepageupdatedate" >Joined on April,2023</span>
                                            <button className=" about mb-0 ">About</button>
                                        </div>
                                    </div>
                                </div>

                            </div>


                        </div>
                        <div className="box p-3 border-rounded pt-3 px-md-4 mb-4" style={{ backgroundColor: 'white', width: '100%', minHeight: '370px', border: '1px solid #ABA7A7', borderRadius: '18px' }}>
                            <div className="d-flex justify-content-between sevicediv align-items-center pt-4">
                                <h5 className="seviceheading">Agent App Services<img src={info} onMouseEnter={() => setInfoBoxVisible2(true)} onMouseLeave={() => setInfoBoxVisible2(false)}  className="infoimg mx-2" alt="info"></img></h5>
                                <div>
                                    <button className="googleplay me-sm-3 me-md-4"><img className="me-1 googleplayimage" alt="google" src={googleplay} width='17px' height='17px'></img>Google Play</button>
                                    <button className="appstore me-4 me-md-0 me-sm-0"><img className="me-1 appstoreimage" alt="apptore" src={appstore} width='17px' height='17px'></img>App Store</button>
                                </div>
                            </div>
                            {infoBoxVisible2 && (
                                    <div className="info1box2" onMouseEnter={() => setInfoBoxVisible2(true)}  onMouseLeave={() => setInfoBoxVisible2(false)}>
                                       When You down load our User App, these are the mini applications you will see
                                    </div>
                                )}
                            <div className="row serviceboxrow mt-5 ">
                                <div className="col-12 col-sm-6 serviceboxbottom col-md-6 col-lg-4 mb-sm-3">
                                    <div className="box-container servicebox border  p-3" >
                                        <img
                                            src={school}
                                            alt="school"
                                            className="img-fluid  d-md-block servicepageimg"

                                        />
                                        <h6 className="mb-2 mt-2 servicepageh6">Education Provider</h6>
                                        <p className="servicepagecomment mt-2 mb-3">Some gate players pain,employee the employees of the company</p>
                                        {/* New elements inside the first box */}
                                        <div className="d-flex align-items-center  justify-content-between">
                                            <span className=" mt-1 mb-0 servicepageupdatedate" >Joined on April,2023</span>
                                            <button className=" about mb-0 ">About</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6 serviceboxbottom col-md-6 col-lg-4 mb-sm-3">
                                    <div className="box-container servicebox border p-3 " >
                                        <img
                                            src={evmarket}
                                            alt="school"
                                            className="img-fluid  d-md-block servicepageimg"

                                        />
                                        <h6 className="mb-2 mt-2 servicepageh6">E Commerce</h6>
                                        <p className="servicepagecomment mt-2 mb-3">Some gate players pain,employee the employees of the company</p>
                                        {/* New elements inside the first box */}
                                        <div className="d-flex align-items-center  justify-content-between">
                                            <span className=" mt-1 mb-0 servicepageupdatedate" >Joined on April,2023</span>
                                            <button className=" about mb-0 ">About</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6 col-md-6 col-lg-4 mb-sm-3">
                                    <div className="box-container servicebox border p-3 " >
                                        <img
                                            src={faithub}
                                            alt="school"
                                            className="img-fluid  d-md-block servicepageimg"

                                        />
                                        <h6 className="mb-2 mt-1 servicepageh6">FaithHub</h6>
                                        <p className="servicepagecomment mt-2 mb-3">Some gate players pain,employee the employees of the company</p>
                                        {/* New elements inside the first box */}
                                        <div className="text-end">
                                            <button className=" about mb-0 ">About</button>
                                        </div>
                                    </div>
                                </div>

                            </div>


                        </div>
                        <div className="box p-3 border-rounded pt-3 px-md-4 mb-4" style={{ backgroundColor: 'white', width: '100%', minHeight: '370px', border: '1px solid #ABA7A7', borderRadius: '18px' }}>
                            <div className="d-flex justify-content-between sevicediv  align-items-center pt-4">
                                <h5 className="seviceheading">Client Web Services</h5>
                            </div>
                            <div className="row seviceboxrow1 mt-5 ">
                                <div className="col-12 col-sm-6 serviceboxbottom col-md-6 col-lg-4 mb-sm-3">
                                    <div className="box-container servicebox border p-3 " >
                                        <img
                                            src={school}
                                            alt="school"
                                            className="img-fluid  d-md-block servicepageimg"

                                        />
                                        <h6 className="mb-2 mt-2 servicepageh6">E-Commerce Seller</h6>
                                        <p className="servicepagecomment mt-2 mb-3">Some gate players pain,employee the employees of the company</p>
                                        {/* New elements inside the first box */}
                                        <div className="d-flex align-items-center justify-content-between">
                                            <p className=" mt-1 mb-0 servicepageabout" >About<img className="ms-1 jobposting" src={jobposting} alt="jobposting" ></img></p>
                                            <button className=" about mb-0 ">Join Now</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6 serviceboxbottom col-md-6 col-lg-4  mb-sm-3">
                                    <div className="box-container servicebox border p-3 " >
                                        <img
                                            src={evmarket}
                                            alt="school"
                                            className="img-fluid  d-md-block servicepageimg"

                                        />
                                        <h6 className="mb-2 mt-2 servicepageh6">School</h6>
                                        <p className="servicepagecomment mt-2 mb-3">Some gate players pain,employee the employees of the company</p>
                                        {/* New elements inside the first box */}
                                        <div className="d-flex align-items-center  justify-content-between">
                                            <p className=" mt-2 mb-0 servicepageabout">About<img className="ms-1 jobposting" src={jobposting} alt="jobposting" ></img></p>
                                            <span className="mt-2 mb-0 servicepageupdatedate text-end">Joined on April,2023</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6 serviceboxbottom col-md-6 col-lg-4 mb-sm-3">

                                </div>
                            </div>
                        </div>
                        <div className="box p-3 border-rounded pt-3 px-md-4 mb-4" style={{ backgroundColor: 'white', width: '100%', minHeight: '370px', border: '1px solid #ABA7A7', borderRadius: '18px' }}>
                            <div className="d-flex justify-content-between sevicediv  align-items-center pt-4">
                                <h5 className="seviceheading">Agent Web Services</h5>
                            </div>
                            <div className="row seviceboxrow1 mt-5 ">
                                <div className="col-12 col-sm-6 serviceboxbottom col-md-6 col-lg-4 mb-sm-3">
                                    <div className="box-container servicebox border p-3 " >
                                        <img
                                            src={school}
                                            alt="school"
                                            className="img-fluid  d-md-block servicepageimg"

                                        />
                                        <h6 className="mb-2 mt-2 servicepageh6">E-Commerce Seller</h6>
                                        <p className="servicepagecomment mt-2 mb-3">Some gate players pain,employee the employees of the company</p>
                                        {/* New elements inside the first box */}
                                        <div className="d-flex align-items-center justify-content-between">
                                            <p className=" mt-1 mb-0 servicepageabout" >About<img className="ms-1 jobposting" src={jobposting} alt="jobposting" ></img></p>
                                            <button className=" about mb-0 ">Join Now</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6 serviceboxbottom col-md-6 col-lg-4  mb-sm-3">
                                    <div className="box-container servicebox border p-3 " >
                                        <img
                                            src={evmarket}
                                            alt="school"
                                            className="img-fluid  d-md-block servicepageimg"

                                        />
                                        <h6 className="mb-2 mt-2 servicepageh6">School</h6>
                                        <p className="servicepagecomment mt-2 mb-3">Some gate players pain,employee the employees of the company</p>
                                        {/* New elements inside the first box */}
                                        <div className="d-flex align-items-center  justify-content-between">
                                            <p className=" mt-2 mb-0 servicepageabout">About<img className="ms-1 jobposting" src={jobposting} alt="jobposting" ></img></p>
                                            <span className="mt-2 mb-0 servicepageupdatedate text-end">Joined on April,2023</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6 serviceboxbottom col-md-6 col-lg-4 mb-sm-3">

                                </div>
                            </div>
                        </div>
                        <div className="box p-3 border-rounded pt-3 px-md-4 mb-4" style={{ backgroundColor: 'white', width: '100%', minHeight: '370px', border: '1px solid #ABA7A7', borderRadius: '18px' }}>
                            <div className="d-flex justify-content-between servicediv align-items-center pt-4">
                                <h5 className="seviceheading">Enterprise Services<img src={info}  onMouseEnter={() => setInfoBoxVisible(true)} onMouseLeave={() =>setInfoBoxVisible(false)}
            className="infoimg  mx-2" alt="info"></img></h5> 
                            </div>
                            {infoBoxVisible && (
                                    <div className="info1box" onMouseEnter={() => setInfoBoxVisible(true)}  onMouseLeave={() => setInfoBoxVisible(false)}>
                                        You can use these services if you are accessing our platform through an organization such as a school, health facility, or any other institution.
                                    </div>
                                )}
                            <div className="row seviceboxrow1 mt-5">
                                <div className="col-12 col-sm-6 serviceboxbottom col-md-6 col-lg-4 mb-sm-3 ">
                                    <div className="box-container servicebox border p-3 " >
                                        <img
                                            src={school}
                                            alt="school"
                                            className="img-fluid  d-md-block servicepageimg"

                                        />
                                        <h6 className="mb-2 mt-2 servicepageh6">School center</h6>
                                        <p className="servicepagecomment mt-2 mb-3">Some gate players pain,employee the employees of the company</p>
                                        {/* New elements inside the first box */}
                                        <div className="d-flex align-items-center  justify-content-between">
                                            <p className=" mt-2 mb-0 servicepageabout " >About <img className="ms-1 jobposting" src={jobposting} alt="jobposting"></img></p>
                                            <span className=" mt-2 mb-0 servicepageupdatedate text-end">Joined on April,2023</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6 serviceboxbottom col-md-6 col-lg-4 mb-sm-3 mb-xs-3">
                                    <div className="box-container servicebox border p-3 " >
                                        <img
                                            src={school}
                                            alt="school"
                                            className="img-fluid  d-md-block servicepageimg"

                                        />
                                        <h6 className="mb-2 mt-2 servicepageh6">Virtual WorkSpace</h6>
                                        <p className="servicepagecomment mb-3 mt-2">Some gate players pain,employee the employees of the company</p>
                                        {/* New elements inside the first box */}
                                        <div className="d-flex align-items-center  justify-content-between">
                                            <p className=" mt-1 mb-0 servicepageabout ">About<img className="ms-1 jobposting" src={jobposting} alt="jobposting"></img></p>
                                            <button className=" abouts mb-0 ">Contact Sales</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6 serviceboxbottom col-md-6 col-lg-4 mb-sm-3 mb-xs-3">
                                </div>
                            </div>
                        </div>
                        <div className="box p-3 border-rounded pt-3 px-md-4 mb-4" style={{ backgroundColor: 'white', width: '100%', border: '1px solid #ABA7A7', borderRadius: '18px' }}>
                            <div className="d-flex justify-content-between servicediv align-items-center pt-4">
                                <h5 className="seviceheading">In house</h5>
                                <div className="form-check">
                                    <input className="form-check-input" onClick={toggleAppRow} type="checkbox" value="" id="flexCheckDefault"></input>
                                </div>
                            </div>
                            {isAppRowVisible && (
                                <div className="row seviceboxrow1 mt-5">
                                    <div className="col-12 col-sm-6 serviceboxbottom col-md-6 col-lg-4 mb-sm-3 mb-xs-3">

                                        <div className="box-container servicebox border  p-3"   >
                                            <img
                                                src={school}
                                                alt="school"
                                                className="img-fluid d-none d-md-block servicepageimg"

                                            />
                                            <h6 className="mb-2 mt-3 servicepageh6">Pilot</h6>
                                            <p className="servicepagecomment">Some gate players pain,employee the employees of the company</p>
                                        </div>

                                    </div>
                                    <div className="col-12 col-sm-6 serviceboxbottom col-md-6 col-lg-4 mb-sm-3 mb-xs-3">
                                        <div className="box-container servicebox border  p-3" >
                                            <div className="d-flex align-items-center justify-content-between">
                                                <img
                                                    src={evmarket}
                                                    alt="school"
                                                    className="img-fluid  d-md-block servicepageimg"

                                                />
                                                <img className="infoimg" src={info} alt="info" ></img>
                                            </div>
                                            <h6 className="mb-2 mt-3 servicepageh6">Market Panel</h6>
                                            <p className=" servicepagecomment">Some gate players pain,employee the employees of the company</p>
                                            <hr className="servicehr" />
                                            <div className="col-12">
                                                <p onClick={jobpostings} className=" mt-0 mb-0 text-end  seejobposting">See job postings<img className="ms-1 jobposting" src={jobposting} alt="jobposting"></img></p>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6 serviceboxbottom col-md-6 col-lg-4 mb-sm-3 mb-xs-3">
                                        <div className="box-container servicebox border  p-3" >
                                            <div className="d-flex align-items-center justify-content-between">
                                                <img
                                                    src={faithub}
                                                    alt="school"
                                                    className="img-fluid  d-md-block servicepageimg"

                                                />
                                                <img className="infoimg" src={info} alt="info"></img>
                                            </div>
                                            <h6 className="mb-1 mt-0 servicepageh6">FaithHub</h6>
                                            <p className="mb-1" style={{ fontSize: "10px" }}>Role:curator</p>
                                            <p className="servicepagecomment">Some gate players pain,employee the employees of the company</p>
                                            <hr className="servicehr" />
                                            {/* New elements inside the first box */}
                                            <div className="d-flex">
                                                <p className=" mt-0 mb-0 servicepagelaunchnow" ><img className="me-1 launchimg" src={lanch} alt="jobposting" ></img>Lanch now</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {/* <div className="row"> */}
                            {isAppRowVisible && (
                                <div className="col-sm-6 serviceboxbottom col-md-6 col-lg-4">
                                    <div className="box-container border  servicebox  mb-sm-3 mb-xs-3 p-3" >
                                        <div className="d-flex align-items-center justify-content-between">
                                            <img
                                                src={faithub}
                                                alt="school"
                                                className="img-fluid  d-md-block servicepageimg"

                                            />
                                            <img className="infoimg" src={info} alt="info" ></img>

                                        </div>
                                        <h6 className="mb-1 mt-0 servicepageh6">FaithHub</h6>
                                        <p className="" style={{ fontSize: "10px" }}>Role:curator</p>
                                        <p className="servicepagecomment">Some gate players pain,employee the employees of the company</p>
                                        <hr className="servicehr" />
                                        <div className="d-flex">
                                            <p className=" mt-0 mb-0 servicepaagelaunchnow" ><img className="me-1 launchimg" src={lanch} alt="jobposting"></img>Lanch now</p>
                                            <p className="mt-0 mb-0 text-end seejobposting" >See job postings<img className="ms-1 jobpostings" src={jobposting} alt="jobposting" ></img></p>
                                        </div>
                                    </div>

                                </div>
                            )}
                        </div>



                    </div>
                </div>
            </div>
        </>
    );
}
export default Services;