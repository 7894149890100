import React, { useCallback, useEffect, useRef, useState } from 'react';
import Documentcard from '../../component/documentcard';
import { confirmDialog, generateUniqueKey, HandleCalls } from '../../api/utils/utils';
import Select from 'react-select';
import { countryList, documentgeneraldelete, documentGeneralList} from '../../api/helper/apiurlhelper';
import Documentsectionnew from '../../component/documentsectionnew';
import { useIpContext } from '../../api/hooks/iphook';
import Generaladd from './generaladd';
import Button from '../../component/savebutton';
import ImageViewer from '../../component/imageviewer';
import PDFViewer from '../pdfviewer';
import Generaledit from './generaledit';
import { publicDomain } from '../../api/helper/urlhelper';
function Generallist({documentRef,setIsLoading4,showErrorToast}) {
  const { ipCountryCode ,getUserIpCountryCode } = useIpContext();
  const [formvalues,setFormvalues] = useState({selectcountry:null,countries:[],isLoading:false,documents:[],show:false,documenttypeoption:[],calwidth:200,selectimage:[],ispreviewvisible:false,ischeck:null,uniquekey:0,selectdoc:'',ispdfvisible:false,editvalues:[],editshow:false});
  const selectRef = useRef(null);
  const genraldocumentget = useCallback(async(selectcountry) => {
     const after_successcall = async(response) => {
        const doc = response.data.data.values;
        setFormvalues((prev)=>({...prev,documents:doc}));
     }
     const params = {
      country_id: selectcountry,
      is_app: 0
    }
     await HandleCalls({apiCall:()=>documentGeneralList(params),successCall:after_successcall,showErrorToast:showErrorToast,setIsLoading: (loading) =>setFormvalues((prev) => ({ ...prev, isLoading: loading }))});
    setFormvalues((prev)=>({...prev,isLoading:false}));
  },[]); 
  
  const fetchCountries = useCallback(async() => {
    // setFormvalues((prev) => ({ ...prev, isLoading: true }));
    const after_successcall = async(response) => {
       const countrylist = response?.data.data;
       const formattedCountries = countrylist.map((country) => ({
        value: country.id,
        label: country.name,
    }));
       const defaultCountry = countrylist.find(country => country.country_code === ipCountryCode);
        if (defaultCountry) {
            setFormvalues((prev)=>({...prev,selectcountry:defaultCountry.id,countries: formattedCountries}));
        } 
    } 
    HandleCalls({apiCall:countryList,successCall:after_successcall,showErrorToast:showErrorToast,setIsLoading: (loading) =>setFormvalues((prev) => ({ ...prev, isLoading: loading }))});
  },[ipCountryCode]);
  useEffect(() => {
        if (ipCountryCode !== null) {
        fetchCountries();
        }
    }, [ipCountryCode, fetchCountries])  
  const fetchdata = useCallback(async() => {
    // setFormvalues((prev)=>({...prev,isLoading:true}));
    if(!ipCountryCode){
        getUserIpCountryCode();
    }
    if (formvalues.selectcountry !== null) {
        await genraldocumentget(formvalues.selectcountry);
    }
    // setFormvalues((prev)=>({...prev,isLoading:false}));
  },[ipCountryCode,getUserIpCountryCode,genraldocumentget,formvalues.selectcountry]);
  useEffect(()=>{
    setFormvalues((prev)=>({...prev,isLoading:true}));
    fetchdata();
  },[fetchdata]);
  const addgeneral = () => {
    if(formvalues.selectcountry){
    setFormvalues((prev)=>({...prev,show:true}));
    }else{
      showErrorToast(
        "Please Select a Country First",
        '422Error',
      ); 
    }
  }
  const handleclose = () => {
    setFormvalues((prev)=>({...prev,show:false}));
  }
  const selectedcountrychange = (selectedOption) => {
    if (selectedOption) {
      setFormvalues((prev)=>({...prev,selectcountry:selectedOption.value}))
    } else {
      setFormvalues((prev)=>({...prev,selectcountry:null}))
    }
  };
  const calculateWidth = (label) => {
          const tempDiv = document.createElement('div');
          tempDiv.style.position = 'absolute';
          tempDiv.style.visibility = 'hidden';
          tempDiv.style.height = 'auto';
          tempDiv.style.width = 'auto';
          tempDiv.style.whiteSpace = 'nowrap';
          document.body.appendChild(tempDiv);
      
          tempDiv.innerText = label;
          const newWidth = tempDiv.offsetWidth + 30; 
          document.body.removeChild(tempDiv);
      
          return newWidth;
        };
      
        const updateSelectWidth = useCallback(()=>{
          const selectedOption = formvalues.countries.find(option => option.value === formvalues.selectcountry);
          const newWidth = selectedOption ? calculateWidth(selectedOption.label + 30) : 200;
          setFormvalues((prev)=>({...prev,calwidth:`${newWidth}px`}))
        },[formvalues.selectcountry,formvalues.countries])
      
        useEffect(() => {
          updateSelectWidth();
        }, [updateSelectWidth]);
      
        useEffect(() => {
          const handleResize = () => {
            updateSelectWidth();
          };
          window.addEventListener('resize', handleResize);
      
          return () => {
            window.removeEventListener('resize', handleResize);
          };
        }, [formvalues.selectcountry,updateSelectWidth]);
  const customStyles = {
    control: (provided,state) => ({
      ...provided,
      minWidth: '200px',
      width:formvalues.calwidth,
      backgroundColor: '#FFFFFF',
      color: 'white',
      border: state.isFocused ? 'none' : '1px solid black',
      '&:hover': {
      borderColor:  'black' ,
      },
      boxShadow: state.isFocused ? 'none' : 'none',
      borderRadius: '4px',
      height: '35px',
      textAlign: 'center',
      display: 'flex',
      paddingRight:'30px',
      alignItems: 'center',
      justifyContent: 'center',
    }),
    menu: (provided) => ({
      ...provided,
      width: formvalues.calwidth,
      minWidth: '100%',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: 'black',
      textAlign: 'center',
      width: '100%',
    }),
    placeholder: (provided) => ({
      ...provided,
      color: 'black',
      textAlign: 'center',
      width: '100%',
    }),
    input: (provided) => ({
      ...provided,
      color: 'black',
      textAlign: 'center',
    }),
  };
  const DropdownIndicator = null;
  const showimageviewer = (show,viewer) => {
    if(viewer === 'image'){
    setFormvalues((prev)=>({...prev,ispreviewvisible:true,ischeck:show}));
    }else{
    setFormvalues((prev)=>({...prev,ispdfvisible:true,ischeck:show}));
    }
  }
  const closeimageviewer = () => {
    if(formvalues.ischeck === 'add'){
      setFormvalues((prev)=>({...prev,show:true}));
    }else if(formvalues.ischeck === 'edit'){
      setFormvalues((prev)=>({...prev,editshow:true}));
    }
    setFormvalues((prev)=>({...prev,ispreviewvisible:false,ispdfvisible:false}));
  }
  const handleedit = (doc) => {
    if(doc){
       setFormvalues((prev)=>({...prev,editvalues:doc,editshow:true}));
    }
  }
  const handleEyeClickimage = (document) => {
    if(document.user_document_map[document.user_document_map.length - 1]?.path.endsWith('.pdf')){
      setFormvalues((prev)=>({...prev,selectdoc:`${publicDomain()}/${document.user_document_map[document.user_document_map.length - 1]?.path}`,
      uniquekey:generateUniqueKey('list'), 
      }));
      showimageviewer('','pdf');
      }else{
      setFormvalues((prev)=>({...prev,selectimage:[document.user_document_map[document.user_document_map.length - 1]?.path]}));
      showimageviewer('','image');
      }
  }
  const handledeletedocument = async(doc) => {
    const id = doc.id;
    const result = await confirmDialog('Are you sure?','You won\'t be able to revert this!');
    if(result.isConfirmed){
       const after_successcall = async() => {
          await fetchdata();
        }
      setIsLoading4(true);
      await HandleCalls({apiCall:()=>documentgeneraldelete(id),successCall:after_successcall,showErrorToast:showErrorToast}); 
      setIsLoading4(false)
    }
  }
  console.log(formvalues.isLoading);
  
  return (
    <>
     <div className="select-custom-container" style={{ minWidth: '223px', width: `calc(${formvalues.calwidth} + 23px)`}}>
      <Select ref={selectRef} className="generalcountryselect" value={formvalues.countries?.find(option => option.value === formvalues.selectcountry)} onChange={selectedcountrychange} options={formvalues.countries} placeholder="Select Country" styles={customStyles} components={{ DropdownIndicator }}/>
    </div>
    {formvalues.documents.length > 0 ? (
     <> 
     <Documentcard documents={formvalues.documents} handelgeneraledit={handleedit} handleEyeClickimage={handleEyeClickimage} handledeletedocument={handledeletedocument}/>
     <div className='text-end buttonwidthdoc'>
     <Button label='New' icon={true} active={true} defaultclassname='docaddnew mt-3' onClick={addgeneral}></Button>
     </div>
     </> 
    ) : (
      <Documentsectionnew isLoading={formvalues.isLoading} Type='General Identification' onClick={addgeneral} documentRef={documentRef}/>
    )} 
      <Generaladd formvalues={formvalues} fetchdata={fetchdata} setIsLoading4={setIsLoading4} imageviewer={showimageviewer} closeimageviewer={closeimageviewer} showErrorToast={showErrorToast} show={formvalues.show} handleclose={handleclose} setFormvalues={setFormvalues}/>
      <Generaledit values={formvalues.editvalues} formvalues={formvalues} fetchdata={fetchdata} setIsLoading4={setIsLoading4} imageviewer={showimageviewer} closeimageviewer={closeimageviewer} showErrorToast={showErrorToast} show={formvalues.editshow} setFormvalues={setFormvalues} />
      {formvalues.selectimage && 
      <ImageViewer isPreviewVisible={formvalues.ispreviewvisible} selectedImage1={formvalues.selectimage} onClose={closeimageviewer} isMultiImage={false}/>
      }
      {formvalues.selectdoc && <PDFViewer key={formvalues.uniquekey}  show={formvalues.ispdfvisible}  fileUrl={formvalues.selectdoc}  onClose={closeimageviewer} />}
    </>
  )
}

export default Generallist