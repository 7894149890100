import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Documentsectionnew from '../../component/documentsectionnew';
import { confirmDialog, HandleCalls, removecore } from '../../api/utils/utils';
import adddocument from '../../images/document_add-01.png';
import edit from '../../images/job_details_icons/edit_1-01.png';
import acdamicdeleteimg from '../../images/trash-red-circle-01.png';
import { acdamicdelete, acdamicHistoryList } from '../../api/helper/apiurlhelper';
import Acdamicadd from './acdamicadd';
import Button from '../../component/savebutton';
import ImageViewer from '../../component/imageviewer';
import Acdamicedit from './acdamicedit';
function Acdamiclist({documentRef,login_user,setIsLoading4,showErrorToast}) {
  const navigate = useNavigate();    
  const [listvalues,setListvalues] = useState({acdamichistory:[],isLoading:false,show:false,selectimage:[],ispreviewvisible:false,ischeck:null,editvalues:[],editshow:false});  
  const acdamichistorydocument = async() => {
    setListvalues((prev)=>({...prev,isLoading:true}));
    const after_successcall = async(response) => {
    setListvalues((prev)=>({...prev,acdamichistory:response.data.data}));
    }
    await HandleCalls({apiCall:acdamicHistoryList,successCall:after_successcall,showErrorToast:showErrorToast,});
    setListvalues((prev)=>({...prev,isLoading:false}));
    }
    useEffect(()=>{
    acdamichistorydocument();
    },[]);
  const addacdamic = () => {
    setListvalues((prev)=>({...prev,show:true}));
  }
  const imageviewer = (show) => {
    setListvalues((prev)=>({...prev,ispreviewvisible:true,ischeck:show}));
    }
    const closeimageviewer = () => {
    if(listvalues.ischeck === 'add'){
        setListvalues((prev)=>({...prev,show:true}));
    }else if(listvalues.ischeck === 'edit'){
        setListvalues((prev)=>({...prev,editshow:true}));
    }
    setListvalues((prev)=>({...prev,ispreviewvisible:false}));
    }
  const updateacdamichistory = (doc) => {
    if(doc){
       setListvalues((prev)=>({...prev,editvalues:doc,editshow:true})); 
    }
  }
  const handleacdamichistorydelete = async(doc) => {
    const id = doc.id;
    const result = await confirmDialog(
            'Are you sure?',
          'You won\'t be able to revert this!',
        );
        if(result.isConfirmed){
            const after_successcall = async() => {
                await acdamichistorydocument();
              }
           setIsLoading4(true);
           await HandleCalls({apiCall:()=>acdamicdelete(id),successCall:after_successcall,showErrorToast:showErrorToast}); 
           setIsLoading4(false)
        }
  }
  const acdamichistorydocumentlist = (acdamic) => {
    const id = acdamic.id;
    navigate(`/u/${login_user}/documents/acdamichistory/${id}/document`);
  }
  return (
    <>
     {listvalues.acdamichistory.length > 0 ? (
      <>
        {listvalues.acdamichistory.map((acdamic, index) => (
        <div key={index} className="docbox">
        <div className="d-flex align-items-center" style={{ width: "100%",gap:'10px' }}>
            <div className="d-flex align-items-center acdamic45" style={{ width: '45%',overflow:'hidden' }}>
            <div className="me-3">
            <img src={removecore(acdamic.image)} alt="car" className="docvehicleimg"></img>
            </div>
            <div className="workhistorydiv">
                <h6 className="workhistoryh6">{acdamic.user_education_level.name}</h6>
                <p className="workhistoryp">{acdamic.school}</p>
            </div>
            </div>
            <div className="acdamic20" style={{ width: '20%',overflow:'hidden' }}>
                <div className="text-start">
                <h6 className="workhistoryh61">{new Date(acdamic.start).getFullYear()}-{new Date(acdamic.end).getFullYear()}</h6>
                </div>
            </div>
            <div className="d-flex align-items-center justify-content-end acdamic35" style={{ width: '35%' }}>
                <div className="docvechilebuttons me-3">
                <button style={{ position: 'relative' }} onClick={() => updateacdamichistory(acdamic)} className=" docuploadvehicle">
                    <div className="butimag">
                    <div className="__col docvechilespan">
                        <img src={edit} alt="deposits" className="me-2" width='18px' height='auto' />
                    </div>
                    <span className="__col docvechilespan">Update Detail</span>
                    </div>
                </button>
                <button style={{ position: 'relative' }} onClick={() =>acdamichistorydocumentlist(acdamic)} className=" docuploadviewdocument">
                    <div className="butimag">
                    <div className="__col docvechilespan">
                        <img src={adddocument} alt="deposits" className="me-2" width='18px' height='auto' />
                    </div>
                    <span className="__col docvechilespan">Document</span>
                    </div>
                </button>
                </div>
                <div>
                <img src={acdamicdeleteimg} alt="delete" onClick={() => handleacdamichistorydelete(acdamic)} className="docvehicledeletered"></img>
                </div>
            </div>
        </div>
        </div>
        ))}
        <div className='text-end buttonwidthdoc'>
     <Button label='New' icon={true} active={true} defaultclassname='docaddnew mt-3' onClick={addacdamic}></Button>
     </div>
      </>
     ) : (
      <Documentsectionnew isLoading={listvalues.isLoading} Type='Add Academic Background' onClick={addacdamic} documentRef={documentRef}/>
     )}   
     <Acdamicadd acdamichistorydocument={acdamichistorydocument} setListvalues={setListvalues} imageviewer={imageviewer} show={listvalues.show} setIsLoading4={setIsLoading4} showErrorToast={showErrorToast}/>
     <Acdamicedit values = {listvalues.editvalues} acdamichistorydocument={acdamichistorydocument} setListvalues={setListvalues} imageviewer={imageviewer} show={listvalues.editshow} setIsLoading4={setIsLoading4} showErrorToast={showErrorToast}/>
     {listvalues.selectimage && 
      <ImageViewer isPreviewVisible={listvalues.ispreviewvisible} selectedImage1={listvalues.selectimage} onClose={closeimageviewer} isMultiImage={false}/>
      }
    </>
  )
}

export default Acdamiclist;