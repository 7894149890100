import { fetchDocumentTypeListPending, fetchDocumentTypeListFulfilled, fetchDocumentTypeListRejected } from '../slices/documentTypeSlice';
import { documentTypesList,} from '../../../api/helper/apiurlhelper';
import { HandleCalls} from '../../utils/utils'; 

export const fetchDocumentTypes = (showErrorToast,data) => async (dispatch,getState) => {
    const { documenttypes } = getState();
    if (documenttypes.documentTypeOptions.length > 0) return;
  try{
  dispatch(fetchDocumentTypeListPending());
  const response = await  HandleCalls({apiCall: () => documentTypesList(data),showErrorToast: showErrorToast});
  if (response.status === 200) {
    const mappedRelations = response.data.data.values
    dispatch(fetchDocumentTypeListFulfilled(mappedRelations));
  }
  }catch(error){
    dispatch(fetchDocumentTypeListRejected(error.response?.data || error.message));
  }
};
