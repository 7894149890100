import { useEffect, useState } from "react";
export const useAuth = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [token, setToken] = useState(false);
  const checkcall = async()=>{
    await makeAjaxCall();
  }
  useEffect(() => {
    const checkAuth = async () => {
      if(!userData){
        await checkcall(); 
      }
      if(userData){
        if(userData?.length > 0){
          const check1 = userData.some(item => !item.logout && !item.exp);
           if(check1){
             setToken(true);
           } 
        } 
      }
    };
    checkAuth().finally(()=>setIsLoading(false));
  }, []);

  return { token, isLoading };
};

// import { useEffect, useState } from "react";
// export const useAuth = () => {
//   const [isLoading, setIsLoading] = useState(true);
//   const [token, setToken] = useState(false);
//   const checkcall = async()=>{
//     // await makeAjaxCall();
//   }
//   useEffect(() => {
//     const checkAuth = async () => {
//       setToken(true)
//       // if(!userData){
//       //   await checkcall(); 
//       // }
//       // // if(userData){
//       // //   if(userData?.length > 0){
//       // //     const check1 = userData.some(item => !item.logout && !item.exp);
//       // //      if(check1){
//       // //        setToken(true);
//       // //      } 
//       // //   } 
//       // // }
//     };
//     checkAuth().finally(()=>setIsLoading(false));
//   }, []);

//   return { token, isLoading };
// };
