import React, { useEffect, useState } from 'react';
import Cardheader from '../../component/carddesign';
import Commonheader from '../../component/commonheader';
import { useNavigate } from 'react-router-dom';
import { handleCalls } from '../../api/utils/utils';
import ProfileImage from '../../component/profileimage';
import exportimg from '../../images/145_Exit door 5.svg'
import WithToast from '../../api/hooks/withtoasthook';
import { getaccounts } from '../../api/helper/apiurlhelper';
import Boxcontainer from '../../component/boxcontainer';
function Connectaccounts({showErrorToast,login_user}) {
    const navigate = useNavigate();
    const [details,setDetails] = useState([]);
    const [showurl,setShowurl] = useState(false);
    const getaccountsdetails = async() => {
       const response = await handleCalls({apiCall:getaccounts,showErrorToast:showErrorToast,});
       if(response?.status=== 200){
          const data = response.data.data;
          setDetails(data); 
       }
    }
      useEffect(()=>{
        if(window.location.pathname.includes('connectedaccounts')){
         setShowurl(true);
         getaccountsdetails();
        }else{
         setShowurl(false);
         getaccountsdetails();
        }
        },[]);
        const Connectedaccountlist = () => {
         return (
           (showurl ? details : details.slice(0, 3))?.map((item, index) => (
             <div key={index} className="d-flex mb-3 align-items-center justify-content-between border rounded p-3">
               <div className="d-flex align-items-center gap-2 accounts_width accounts_width90">
                 <ProfileImage containerClass="connect_profile" imgClass="modal_user_profile" imageSrc={item.image} />
                 <div className="accounteplise text-start">
                   <h6 className="mb-0 fw-medium lh-base elipse">{item.name}</h6>
                   <p className="mb-0 fw-normal accountcolorp elipse">{item.email}</p>
                 </div>
               </div>
               <div className="accounts_width10 text-end">
                 <img src={exportimg} alt="export"></img>
               </div>
             </div>
           ))
         );
       };
  return (
    <>
     {!showurl ? (
      <Boxcontainer heardervalue="Connected accounts" forward={()=>navigate(`/u/${login_user}/security/connectedaccounts`)} arrow={true}>
     <div className='mt-4'>
       <Connectedaccountlist/>
      </div> 
      </Boxcontainer>
     ) : (
      <>
      <Commonheader/>
     <Cardheader text='Connected accounts' back={()=>navigate(`/u/${login_user}/security`)} footer={false}>
       <Connectedaccountlist/>
     </Cardheader>
      </>
     )}
    </>
  )
}

export default WithToast(Connectaccounts);