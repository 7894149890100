// import axios from 'axios';
// const useCustomHeader = process.env.REACT_APP_USE_CUSTOM_HEADER === 'true';

// function extractLoginUser() {
//     const routePath = window.location.pathname;
//     const regex = /\/u\/([^/]+)/;
  
//     const match = routePath.match(regex);
//     if (match) {
//         return match[1].toString(); 
//     } else {
//         console.log("User parameter not found in route path.");
//         return null;
//     }
// }

// function updateAuthUserHeader(config) {
//     if (!login_user) {
//         login_user = extractLoginUser();
//     }

//     if (login_user) {
//         config.headers['X-Authuser'] = login_user;
//     }

//     return config;
// }

// let login_user = null;

// const axiosInstance = axios.create({
//     headers: {
//         'Content-type': 'application/json',
//         'Accept': 'application/json',
//         ...(useCustomHeader && {
//             'Accept': 'application/json', 
//             "X-Authuser": 0, 
//             "X-Request-Agent": "APP", 
//             "X-SID": "sid_ldmqeUxp4dldMxuheQ2v", 
//             "X-MUID": "mut_bIzztMGIUg9KeYQO4Aal"
//         }),
//     },
//     withCredentials: true
// });

// axiosInstance.interceptors.request.use(
//     config => updateAuthUserHeader(config),
//     error => Promise.reject(error)
// );


// export default axiosInstance;


// import axios from 'axios';

// const extractLoginUser = async()=>{
//     await makeAjaxCall();
// }

// const updateAuthUserHeader = async(config) => {
//     if (!login_user) {
//         await extractLoginUser();
//   }

//   if (login_user) {
//     console.log(login_user,'login');
//       config.headers['X-Authuser'] = login_user;
//   }

//   return config;
// }

// const axiosInstance = axios.create({
//     headers: {
//         'Content-type': 'application/json','Accept': 'application/json',
//     },
//     withCredentials: true
// });

// axiosInstance.interceptors.request.use(
//     config => updateAuthUserHeader(config),
//     error => Promise.reject(error)
// );

// export default axiosInstance;


import axios from 'axios';
let login_user = null; 
function extractLoginUser() {
    const routePath = window.location.pathname;
    const regex = /\/u\/([^/]+)/;
  
    const match = routePath.match(regex);
    if (match) {
        return match[1].toString(); 
    } else {
        console.log("User parameter not found in route path.");
        return null;
    }
}

function updateAuthUserHeader(config) {
    if (!login_user) {
        login_user = extractLoginUser();
    }

    if (login_user) {
        config.headers['X-Authuser'] = login_user;
    }

    return config;
}

const axiosInstance = axios.create({
    headers: {
        'Content-type': 'application/json',
        'Accept': 'application/json',
    },
    withCredentials: true
});

axiosInstance.interceptors.request.use(
    config => updateAuthUserHeader(config),
    error => Promise.reject(error)
);


export default axiosInstance;

