import { useDispatch, useSelector } from "react-redux";
import { fetchRelations } from "../../redux/actions/relationActions";
import { fetchDocumentTypes } from "../../redux/actions/documentTypeActions";
import { fetchWorkTypes } from "../../redux/actions/workTypeActions";
import { fetchEmploymentTypes } from "../../redux/actions/employementTypeActions";
import { fetchAcademicLevels } from "../../redux/actions/acdamicLevelAction";

export const useRelationOptions = ({ showErrorToast}) => {
    const dispatch = useDispatch();
    const { relationOptions, loading, error } = useSelector((state) => state.relation);
  
    const loadRelations = () => {
      dispatch(fetchRelations(showErrorToast));
    };
  
    return { relationOptions, loading, error, loadRelations };
};


export const useDocumentTypeOption = ({ showErrorToast,select }) => {
  const dispatch = useDispatch();
  const { documentTypeOptions, loading, error } = useSelector((state) => state.documenttypes);

  const loadTypes = async() => {
    dispatch(fetchDocumentTypes(showErrorToast,select));
  };

  return { documentTypeOptions, loading, error, loadTypes };
};


export const useWorkTypeOptions = ({ showErrorToast}) => {
  const dispatch = useDispatch();
  const { workTypeOptions, loading, error } = useSelector((state) => state.workType);

  const loadWorkTypes = async() => {
    dispatch(fetchWorkTypes(showErrorToast));
  };

  return { workTypeOptions, loading, error, loadWorkTypes };
};


export const useEmployentTypeOptions = ({ showErrorToast}) => {
  const dispatch = useDispatch();
  const { employmentTypeOptions, loading1, error } = useSelector((state) => state.employmentType);
  const loadEmployementTypes = async() => {
    dispatch(fetchEmploymentTypes(showErrorToast));
  };

  return { employmentTypeOptions, loading1, error, loadEmployementTypes };
};


export const useAcdamicOptions = ({ showErrorToast}) => {
  const dispatch = useDispatch();
  const { academicLevelOptions, loading, error } = useSelector((state) => state.academicLevels);
  const loadAcdamicLevels = async() => {
    dispatch(fetchAcademicLevels(showErrorToast));
  };

  return { academicLevelOptions, loading, error, loadAcdamicLevels };
};