import React ,{useState,useEffect, useCallback} from "react";
import {useParams } from "react-router-dom";
import userCircle from '../images/user-circle.png';
import { useNavigate } from "react-router-dom";
import {  customStyles1, handleApiError, HandleCalls, handleImageUploads, handleInputNameValidation, selectWidth, validateAndSetMobileChange, validateEmailValue} from "../api/utils/utils";
import upload from '../images/upload-alt 1.png'
import delteicon from "../images/general-id/delete-01.png";
import editicon from '../images/job_details_icons/edit_1-01.png';
import Select from 'react-select';
import {getemergencyapi} from "../api/helper/urlhelper";
import axiosInstance from "../api/utils/axiosutils";
import WithToast from "../api/hooks/withtoasthook";
import { useRelationOptions } from "../api/hooks/redux-hooks/useReduxActions";
import { useCountryData1 } from "../api/hooks/mobilecountryhook";
import PhoneInputWithCountrySelector from "../component/phoneinputcountryselector";
import Loader from "../component/loader";
import Commonheader from "../component/commonheader";
import ProfileImage from "../component/profileimage";
import Button from "../component/savebutton";
import Cardheader from "../component/carddesign";
import { storeEmergency } from "../api/helper/apiurlhelper";
import { useUpload } from "../api/hooks/uploadhook";
const getemergencyapiurl = getemergencyapi();
function Updateemergencyinfo({showErrorToast,login_user}){
    const {dualdynamic} = useParams(); 
    const [emerget,setEmerget] = useState([]);
    const {uploadFile} = useUpload();
    const {loadRelations,relationOptions,loading} = useRelationOptions({showErrorToast});
    const {countryOptions1,isLoading1,loadselection} = useCountryData1();
    const [selectedCountry, setSelectedCountry] = useState({country_code: "",dial_code: "",mcc_id: "",mobile_length: "",mobile_starting_digits: "",mobileChange:"",numberinvalid:false});
    const navigate = useNavigate();
    const [formvalues,setFormValues] =useState({name:'',changedimage:false,email:'',relation:null,profileImage:null,isNextOfKin:false,imagechanged:false});
    const [error, setError] = useState({nameerror:'',mobileerror:'',emailerror:''});
    const [isSaveButtonEnablednext, setIsSaveButtonEnablednext] = useState(false);
    const [isLoading4, setIsLoading4] = useState(false);
    const [error422,setError422] = useState('');
    const [initialloading,setInitialloading] = useState(true);
     const handleChange = (e) => {
      const { name, type, checked, value } = e.target;
      setFormValues((prevValues) => ({
        ...prevValues,
        [name]: type === "checkbox" ? checked : value,
      }));
      if(name === 'name'){
        setError({nameerror:''})
      }
      if(name === 'email'){
        setError({emailerror:''})
      }
      setError422('');
    };    
    useEffect(() => {
      const getEmergencyData = async () => {
        try {
          const response = await axiosInstance.get(getemergencyapiurl);
          if (response.status === 200) {
            const emergency = response.data.data;
            const matchingEntry = emergency.find(entry => entry.id.toString() === dualdynamic.toString());
            if (matchingEntry) {
              setEmerget(matchingEntry);
              setSelectedCountry((prev)=>({
                ...prev,country_code:matchingEntry.country_code,
                mobileChange:matchingEntry.mobile || ''
              }));
              setFormValues((prev)=>({...prev,
                 name:matchingEntry.name || '',
                 changedimage:matchingEntry.image ? true : false,
                 profileImage:matchingEntry.image || null,
                 email:matchingEntry.email || '',
                 isNextOfKin:matchingEntry.kin_status === 1,
                 relation:matchingEntry.relation_id ? {value:matchingEntry.relation_id,label:matchingEntry.relation_name} : null
              }));
            } else {
              navigate(`/u/${login_user}/profile`);
            }
          }
        } catch (error) {
          handleApiError(error, null, null, true, showErrorToast);
        }
      };
      getEmergencyData().finally(()=>setInitialloading(false));
    }, []);
    useEffect(()=>{
      if(!initialloading && selectedCountry.mobileChange && selectedCountry.mcc_id && selectedCountry.country_code && selectedCountry.mobile_length && selectedCountry.mobile_starting_digits ){
        validateAndSetMobileChange(selectedCountry.mobileChange, {
          setMobileChange: (val) => setSelectedCountry((prev)=>({...prev,mobileChange:val})),
          setError: (errorMsg) => setError((prev) => ({ ...prev, mobileerror: errorMsg })),
          setNumberInvalid: (val) => setSelectedCountry((prev)=>({...prev,numberinvalid:val})),
          mobile_length: selectedCountry.mobile_length,             
          mobile_starting_digits: selectedCountry.mobile_starting_digits 
        });
    }
    },[selectedCountry.mobileChange,selectedCountry.mcc_id,selectedCountry.country_code,selectedCountry.mobile_starting_digits,selectedCountry.mobile_length,initialloading]);
    useEffect(()=>{ 
      loadRelations();
      },[])
    const handleImageUpload = (e) => {
      const file = handleImageUploads(e);
      if (typeof file === 'string') {
        setError((prev)=>({...prev,imageerror:file}))
      } else {
        setError422('');
        setFormValues((prev) => ({...prev,profileImage:file,changedimage: true,imagechanged:true}));
      }
  };
    const triggerFileInput = () => {
        document.getElementById('fileInput').click();
        setError422('');
    };
    useEffect(()=>{
      if(!initialloading){
        loadselection(selectedCountry.country_code,true,(data) => {
          if(data){
          setSelectedCountry((prev)=>({
            ...prev,
            country_code: data?.country_code,
            dial_code: data?.dial_code,
            mcc_id: data?.mcc_id,
            mobile_length: data?.mobile_length,
            mobile_starting_digits: data?.mobile_starting_digits,
          }));
        }
        });
      }
    },[initialloading,selectedCountry.country_code]);
      const handlePhoneNumberChange = (value) => {
          validateAndSetMobileChange(value, {
            setMobileChange: (val) => setSelectedCountry((prev)=>({...prev,mobileChange:val})),
            setError: (errorMsg) => setError((prev) => ({ ...prev, mobileerror: errorMsg })),
            setNumberInvalid: (val) => setSelectedCountry((prev)=>({...prev,numberinvalid:val})),
            mobile_length: selectedCountry.mobile_length,             
            mobile_starting_digits: selectedCountry.mobile_starting_digits 
          });
        };

        const handlePaste = (e) => {
          const pastedText = e.clipboardData.getData('text');
          let numericPastedText = pastedText.replace(/[^0-9]/g, '');
          const dialCodeWithoutPlus = typeof selectedCountry?.dial_code === 'string' ? selectedCountry?.dial_code.replace('+', '') : '';
          if (pastedText.startsWith('+') && numericPastedText.startsWith(dialCodeWithoutPlus)) {
              numericPastedText = numericPastedText.slice(dialCodeWithoutPlus.length);
          }
          validateAndSetMobileChange(numericPastedText, {
            setMobileChange: (val) => setSelectedCountry((prev)=>({...prev,mobileChange:val})),
            setError: (errorMsg) => setError((prev) => ({ ...prev, mobileerror: errorMsg })),
            setNumberInvalid: (val) => setSelectedCountry((prev)=>({...prev,numberinvalid:val})),
            mobile_length: selectedCountry.mobile_length,             
            mobile_starting_digits: selectedCountry.mobile_starting_digits 
          });
          e.preventDefault();
        };
        const handleCountryChange = (selectedOption) => {
          const selectedCountryData = countryOptions1.find(
              (country) => country.value === selectedOption.value
          );
          if (selectedCountryData) {
            const { mcc_id, dial_code, country_code, mobile_length, mobile_starting_digits } = selectedCountryData;
            setSelectedCountry((prev)=>({
              ...prev,
              country_code:country_code,
              dial_code: dial_code,
              mcc_id: mcc_id,
              mobile_length: mobile_length,
              mobile_starting_digits: mobile_starting_digits,
            }));
        
            validateAndSetMobileChange(selectedCountry.mobileChange, {
              setMobileChange:(val) => setSelectedCountry((prev)=>({...prev,mobileChange:val})),
              setError: (errorMsg) => setError((prev) => ({ ...prev, mobileerror: errorMsg })),
              setNumberInvalid: (val) => setSelectedCountry((prev)=>({...prev,numberinvalid:val})),
              mobile_length : mobile_length,
              mobile_starting_digits : mobile_starting_digits,
            });
          }
      };
      const handlebefore = (e) => {
        handleInputNameValidation(e,{setError: (errorMsg) => setError((prev) => ({ ...prev, nameerror: errorMsg }))});
      }
    const check = useCallback(()=>{
      const valid = (
        formvalues.name !== '' && selectedCountry.mobileChange !== '' && formvalues.relation !=='' && formvalues.relation !== null && selectedCountry.numberinvalid === true
      )
      setIsSaveButtonEnablednext(valid);
    },[formvalues.name,selectedCountry.mobileChange,formvalues.relation,selectedCountry.numberinvalid])
    useEffect(()=>{
      check();
    },[check])
       const save = async () => {
       if(formvalues.email){ 
       const isEmailValid = validateEmailValue(formvalues.email);
        setError((prevState) => ({
          ...prevState,
          emailerror: isEmailValid ? '' : 'Please enter a valid email address.',
        }));
        if (!isEmailValid) return;
      }
      let newImagePath = formvalues.profileImage;
      if (typeof newImagePath === "object" && newImagePath instanceof File) {
        const uploadedImage = await uploadFile(formvalues.profileImage,'emergency',setIsLoading4);
        newImagePath = uploadedImage; 
      }
        setIsLoading4(true)
        const data = {
          id:emerget.id,  
          name: formvalues.name,
          mobile: selectedCountry.mobileChange,
          mcc_id: selectedCountry.mcc_id,
          kin_status : formvalues.isNextOfKin ? 1 : 0,
          email : formvalues.email,
          relation_id : formvalues.relation.value,
        };
        if (formvalues.profileImage && !formvalues.imagechanged) {
            data.image = formvalues.profileImage;
        }else if(formvalues.imagechanged){
          data.image = newImagePath;  
        }else{
          data.image = null
        }
        const after_successcall = () => { 
          navigate(`/u/${login_user}/profile`);
        }
        await HandleCalls({apiCall:()=>storeEmergency(data,false),successCall:after_successcall,setError422:setError422,handle422AsToast:false,showErrorToast:showErrorToast,setIsLoading:setIsLoading4});
        setIsLoading4(false);
      }
    return(
        <>
        {isLoading4 && (<Loader/>)}
            <Commonheader/>
            <Cardheader text='Emergency Contacts' back={()=>navigate(`/u/${login_user}/profile`)} next={save} isenabled={isSaveButtonEnablednext}>
            <div className="d-flex align-items-center mb-3 gap-4"> 
            <ProfileImage containerClass='user_connect_profile_emergency'  imgClass='modal_user_profile' imageSrc={formvalues.profileImage ? formvalues.profileImage : userCircle} /> 
            <div>
              <h6 className="emerh6">Update your Picture<span className="emerspan">(optional)</span></h6>
              <p className="emerp">Upload a photo under 2 MB</p>
              {formvalues.changedimage ? (
                <div className="d-flex align-items-center">
                <Button label="Replace" icon={true} imageicon={editicon} onClick={triggerFileInput} active={true} defaultclassname="emerreplace" iconclassname="emeruploadreplace"/>  
                <Button label="Delete" icon={true} imageicon={delteicon} onClick={()=>setFormValues((prev)=>({...prev,changedimage:false,profileImage:null,imagechanged:false}))} active={true} defaultclassname="emerdeleteimage" iconclassname="emeruploaddelete"/>  
                </div>
              ) : (
                <Button label="Upload" icon={true} imageicon={upload} onClick={triggerFileInput} active={true} defaultclassname="emerreplace" iconclassname="emeruploadreplace"/>  
              )}  
               <input type="file" accept="image/png,image/jpg,image/jpeg"  id="fileInput" style={{ display: 'none' }} onChange={handleImageUpload}/>
            </div>
            {error.imageerror && <div className="error-message">{error.imageerror}</div>}
            </div>
            <div className="mb-3">
                  <label htmlFor="names" className="doc_label">Name<span className="docpopstar docpopstaremer ">*</span></label>
                  <input type="text" name="name" autoComplete="name" className={`form-control docform ${formvalues.name ? 'docform-active' : ''}`} value={formvalues.name ?? ''} onBeforeInput={handlebefore} onChange={handleChange} placeholder="Enter Name" id="names"  />
                  {error.mobileerror && <div className="error-message">{error.nameerror}</div>}
                </div>
                <div className="mb-3">
                <label htmlFor="inputField" className="doc_label">Phone number<span className="docpopstar docpopstaremer">*</span></label>
                  <div className="position-relative">
                <PhoneInputWithCountrySelector id='mobilemergency' countryOptions={countryOptions1} handleCountryChange={handleCountryChange} dialCode={selectedCountry.dial_code} mobileChange={selectedCountry.mobileChange} handlePhoneNumberChange={handlePhoneNumberChange}  handlePaste={handlePaste} countryCode={selectedCountry.country_code} minheight='45px'  walletprofileinput = 'docform' walletprofileinputactive='docform-active' isLoading={isLoading1} placeholder="Mobile Number"/>
                </div>
                {error.mobileerror && <div className="error-message">{error.mobileerror}</div>}
                </div>
                <div className="mb-3">
                <label htmlFor="inputField" className="doc_label">Email Address</label>
                  <input type="email" name="email" autoComplete="email" className={`form-control docform ${formvalues.email ? 'docform-active' : ''}`} value={formvalues.email ?? ''} onChange={handleChange} placeholder="Enter Email" id="inputField"  />
                </div>
                {error.emailerror && <div className="error-message">{error.emailerror}</div>}
                <div className="mb-3">
                <label htmlFor="relationselection" className="doc_label">Relation<span className="docpopstar docpopstaremer">*</span></label>
                  <Select name="relation" inputId="relationselection" options={relationOptions} value={formvalues.relation} onChange={(selectOption) => setFormValues((prev) => ({ ...prev, relation: selectOption }))}    placeholder="Select Relation" isLoading={loading}  styles={customStyles1(Boolean(formvalues.relation))}  />
                </div>
                <div className="d-flex align-items-center">
                  <input type="checkbox" name='isNextOfKin' checked={formvalues.isNextOfKin }  id="nextOfKinCheckbox"  onChange={handleChange} className="form-check-input emer-check"></input>
                  <p className="emercheckp">Make Next of Kin</p>
                </div>
                 <p className="error-message">{error422}</p>
            </Cardheader>
    </>
    );
}
export default WithToast(Updateemergencyinfo);