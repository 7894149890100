import { createSlice } from '@reduxjs/toolkit';

const employmentTypeSlice = createSlice({
  name: 'employmentType',
  initialState: {
    employmentTypeOptions: [],
    loading: false,
    error: null,
  },
  reducers: {
    fetchEmploymentTypeListPending: (state) => {
      state.loading = true;
      state.error = null;
    },
    fetchEmploymentTypeListFulfilled: (state, action) => {
      state.loading = false;
      state.employmentTypeOptions = action.payload;
    },
    fetchEmploymentTypeListRejected: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  fetchEmploymentTypeListPending,
  fetchEmploymentTypeListFulfilled,
  fetchEmploymentTypeListRejected,
} = employmentTypeSlice.actions;

export default employmentTypeSlice.reducer;