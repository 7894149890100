import { fetchWorkTypeListPending, fetchWorkTypeListFulfilled, fetchWorkTypeListRejected } from '../slices/workTypeSlice';
import { HandleCalls } from '../../utils/utils'; 
import { workLocationList } from '../../helper/apiurlhelper';

export const fetchWorkTypes = (showErrorToast) => async (dispatch, getState) => {
    const { workType } = getState();
    if (workType.workTypeOptions.length > 0) return;
    try {
        dispatch(fetchWorkTypeListPending());
        const response = await HandleCalls({ apiCall:workLocationList,showErrorToast:showErrorToast });
        if (response.status === 200) {
            const mappedWorkTypes = response.data.data.map((workType) => ({
                value: workType.id,
                label: workType.name,
            }));
            dispatch(fetchWorkTypeListFulfilled(mappedWorkTypes));
        }
    } catch (error) {
        dispatch(fetchWorkTypeListRejected(error.response?.data || error.message));
    }
};
