import {React,useEffect,useState,useCallback} from "react";
import { useNavigate } from "react-router-dom";
import {extractLoginUser,getLocalStorageItem,handleBeforeInput, handleCalls, handlePaste, setLocalStorageItem, validateAndSetMobileChange } from "../api/utils/utils";
import WithToast from "../api/hooks/withtoasthook";
import { useCountryData } from "../api/hooks/mobilehook";
import PhoneInputWithCountrySelector from "../component/phoneinputcountryselector";
import Cardheader from "../component/carddesign";
import Loader from "../component/loader";
import Commonheader from "../component/commonheader";
import { updateRecoveryVerify, updateSendOtp } from "../api/helper/apiurlhelper";
function Recoveryphone({showErrorToast}) {
  const login_user = extractLoginUser();
  const navigate = useNavigate();
  const {
    mccId, dialCode, countryCode, countryOptions,mobile_length,mobile_starting_digits,isLoading,setMccId,setDialCode,setCountryCode,setMobile_length,setMobile_starting_digits,
    loadCountryByIp,empty_values
  } = useCountryData();
    useEffect(()=>{
      const path = window.location.pathname;
      const split_path = path.split('/');
      if(split_path[3] === 'recoveryphone'){
        empty_values()
      } 
   },[]);
   const [state, setState] = useState({isMobileValid: false,isOtpValid: false,showMobileScreen: true});
  const [mobileChange, setMobileChange] = useState('');
  const [error422, setError422] = useState('');
  const [isLoading4, setIsLoading4] = useState(false);
  const [otp, setOtp] = useState('');
  const [numberinvalid,setNumberinvalid] = useState(false);
  useEffect(()=>{
    loadCountryByIp(true);
  },[]);
  const handleKeyPress = (e) => {
     handleBeforeInput(e);
  };
  const handlePhoneNumberChange = (value) => {
    validateAndSetMobileChange(value, {
      setMobileChange: setMobileChange,
      setError: setError422,
      setNumberInvalid: setNumberinvalid,
      mobile_length: mobile_length,             
      mobile_starting_digits: mobile_starting_digits 
    });
  };
  const handlePaste1 = (e) => {
    const pastedText = e.clipboardData.getData('text');
    let numericPastedText = pastedText.replace(/[^0-9]/g, '');
    const dialCodeWithoutPlus = dialCode.replace('+', '');
    if (pastedText.startsWith('+') && numericPastedText.startsWith(dialCodeWithoutPlus)) {
        numericPastedText = numericPastedText.slice(dialCodeWithoutPlus.length);
    }
    validateAndSetMobileChange(numericPastedText, {
      setMobileChange: setMobileChange,
      setError: setError422,
      setNumberInvalid: setNumberinvalid,
      mobile_length: mobile_length,             
      mobile_starting_digits: mobile_starting_digits 
    });
    e.preventDefault();
  };
  const handleCountryChange = (selectedOption) => {
    const selectedCountryData = countryOptions.find(
        (country) => country.value === selectedOption.value
    );
    if (selectedCountryData) {
      const { mcc_id, dial_code, country_code, mobile_length, mobile_starting_digits } = selectedCountryData;
      setMccId(mcc_id);
      setDialCode(`+${dial_code}`);
      setCountryCode(country_code);
      setMobile_length(mobile_length);
      setMobile_starting_digits(mobile_starting_digits);
      validateAndSetMobileChange(mobileChange, {
        setMobileChange,
        setError: setError422,
        setNumberInvalid: setNumberinvalid,
        mobile_length,
        mobile_starting_digits,
      });
    }
};
  const back = () => {
    navigate(`/u/${login_user}/security`);
  }
  const validateMobile = useCallback(() => {
    setState((prev) => ({ ...prev, isMobileValid: mobileChange !== "" && numberinvalid === true }));
  }, [mobileChange,numberinvalid]);

  useEffect(validateMobile, [validateMobile]);

  const next = async() => {
    const after_successcall = (response) =>{
      const recovery_token = response.data.data.token;
      setLocalStorageItem('recovery_token', recovery_token);
      setState((prev)=>({
          ...prev,showMobileScreen:false
        }));
    }
    const data ={
      mobile: mobileChange,
      mcc_id : mccId,
    }
    handleCalls({apiCall:()=>updateSendOtp(data),successCall:after_successcall,setIsLoading:setIsLoading4,
      setError422:setError422,handle422AsToast:false,showErrorToast:showErrorToast
    });
  }
  const onPaste1 = (e) => {
    handlePaste(e, setOtp); 
    setError422('');
  };
  const backs = () => {
    setState((prev)=>({
      ...prev,showMobileScreen:true
    }));;
    setOtp('');
    setError422('');
  }
  const validateOtp = () => {
    setState((prev) => ({ ...prev, isOtpValid: otp !== "" }));
  };

  useEffect(validateOtp, [otp]);
  const confirmcode = async() => {
    const data ={
      mobile: mobileChange,
      mcc_id : mccId,
      otp : otp ,
      token : getLocalStorageItem('recovery_token')
    }
    handleCalls({apiCall:()=>updateRecoveryVerify(data),successCall:back,setIsLoading:setIsLoading4,showErrorToast:showErrorToast,setError422:setError422,handle422AsToast:false,errorCall:back});
  }
  return (
    <>
     {isLoading4 && <Loader/>}
       <Commonheader/>
         <Cardheader text={state.showMobileScreen ? 'Recovery Phone' : 'Confirmation Code'} back={state.showMobileScreen ? back : backs} cancel={back} next={state.showMobileScreen ? next : confirmcode} isenabled={state.showMobileScreen ?state.isMobileValid : state.isOtpValid}>
         {state.showMobileScreen ? (
            <>
            <label htmlFor="mobilemergency" className="doc_label">Mobile Number</label>
           <div className="position-relative">
            <PhoneInputWithCountrySelector id='mobilemergency' countryOptions={countryOptions} handleCountryChange={handleCountryChange} dialCode={dialCode} mobileChange={mobileChange} handlePhoneNumberChange={handlePhoneNumberChange}  handlePaste={handlePaste1} countryCode={countryCode} minheight='45px'  walletprofileinput = 'docform' walletprofileinputactive='docform-active' isLoading={isLoading} placeholder=""/>
            </div>
            </>
         ) : (
          <>
            <label htmlFor="inputField"  className="doc_label" >Verification Code</label>
            <input type="text" value={otp} onChange={(e)=>{setOtp(e.target.value);setError422('')}} maxLength={6} onPaste={onPaste1} onBeforeInput={handleKeyPress} className={`form-control docform ${otp ? 'docform-active' : ''}`}  id="inputField" placeholder="Enter Verification Code" />
          </>
         )}
            <div className="error-message">{error422}</div>
        </Cardheader>
    </>
  );
}
export default WithToast(Recoveryphone);