import React,{useState,useEffect,useRef} from "react";
import Navbars from "../navbar";
import Sidebar from "../sidebar";
import Docnav from "./docnav";
import Generalidentification from "./generalidentification";
import Skills from "./skills";
import Acdamichistory from "./acdamichistory";
import Workhistory from "./workhistory";
import Vechicle from "./vechicle";
import Generallist from "./generalsection/generallist";
import Loader from "../component/loader";
import WithToast from "../api/hooks/withtoasthook";
import Workhistorylist from "./workhistorysection/workhistorylist";
import Acdamiclist from "./acdamichistorysection.js/acdamiclist";
function Documents({showErrorToast,login_user}){
    const [source, setSource] = useState(() => {
        const storedActiveSection = localStorage.getItem('activeNavItem');
        return storedActiveSection || 'General Identification'; // Set the default section if none is found in localStorage
      });
      const [isLoading4,setIsLoading4] = useState(false);
      const documentRef = useRef(null);
      useEffect(() => {
        localStorage.setItem('activeNavItem', source);
      }, [source]);

    return(
        <>
         {isLoading4 && <Loader/>}
         <Navbars />
      <div className="container-fluid bg-white" style={{padding:'0px'}}>
        <div className="documentrow">
          <div className=" documentside">
            <Sidebar />
          </div>
          <div className=" documentmain">
            <div className="text-center">
              <h4 className="mb-3" style={{ fontWeight: '500', fontSize: '24px', verticalAlign: 'cap-height', lineHeight: '36px' }}>Documents</h4>
              <p style={{ fontWeight: '400', fontSize: '16px', verticalAlign: 'cap-height', lineHeight: '24px', color: '#232323' }}>Manage your documents , including personal documents , vehicle documents and professional documents</p>
            </div>
            <div className="document" ref={documentRef}>
              <Docnav setSource={setSource} source={source}/>
              {/* <div> */}
              {source === "General Identification" && 
              // <Generalidentification documentRef={documentRef}/>}
               <Generallist documentRef={documentRef} setIsLoading4={setIsLoading4} showErrorToast={showErrorToast}/>}
              {source === "Skills" && 
              <Skills documentRef={documentRef}/>}
              {source === "Academic History" && 
              <Acdamiclist documentRef={documentRef} setIsLoading4={setIsLoading4} showErrorToast={showErrorToast} login_user={login_user}/>}
              {/* <Acdamichistory documentRef={documentRef}/>} */}
              {source === "Work History" && 
              <Workhistorylist documentRef={documentRef} setIsLoading4={setIsLoading4} showErrorToast={showErrorToast} login_user={login_user}/>}
               {/* <Workhistory documentRef={documentRef}/>} */}
              {source === "Vehicle Details" && 
              <Vechicle documentRef={documentRef}/>}
            </div>
          </div>
        </div>
      </div>
           {/* </div>  */}
        </>
    );
}
export default WithToast(Documents);