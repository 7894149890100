import React,{useState,useCallback,useEffect,useRef} from "react";
import file from "../images/job_details_icons/Document_green-01.png";
import eye from "../images/job_details_icons/eye_green-01.png";
import { formatDateForInput,transformString,handleDragOver,handleKeyDown,getFormattedDate,handleEditfilechange,handleFileChange,formatDateForExpiry,formatFileSize,parseFileExtension,handleDrop,handleDrop1,handleDragOver1,disableFutureDates,disablePastDates,CustomSuffixIcon,handleApiError,generateUniqueKey } from "../api/common/commonapi";
import { countryselectapi,documenttypesapi,documentstoreapi,documentsgeneralgetapi,documentsgeneraldeleteapi,documentsgeneralupdateapi,publicDomain } from "../api/url";
import Select from 'react-select';
import cancel from '../images/add_document/cancel-02.png';
import plus1 from '../images/plus.png';
import transparent from '../images/Menu_icons/My_documents_black-01.png';
import { DatePicker } from 'antd';
import Modal from 'react-bootstrap/Modal';
import closecircle from '../images/times-circle-01.png';
import popdeletes from '../images/general-id/delete-01.png';
import documentorange from '../images/general-id/add-general-id.png';
import fileopen from '../images/folder-open-01.png';
import edit from '../images/job_details_icons/edit_1-01.png';
import { useIpContext } from "../api/common/ipContext";
import axiosInstance from "../api/common/axiosinstand";
import { Image } from "antd";
import Swal from 'sweetalert2';
import dayjs from 'dayjs';
import SkeletonLoader from "./skeletonloader";
import WithToast from "../api/common/withtoast";
import PDFViewer from "./pdfviewer";

const countryselect = countryselectapi();
const documenttypesapiurl = documenttypesapi();
const documentstoreapiurl = documentstoreapi();
const publicUrl = publicDomain();
const documentsgeneralgetapiurl = documentsgeneralgetapi();
const documentsgeneralupdateapiurl = documentsgeneralupdateapi();
const documentsgeneraldeleteapiurl = documentsgeneraldeleteapi();
function Generalidentification({documentRef,showErrorToast}) {
      const { ipCountryCode ,getUserIpCountryCode } = useIpContext();
      const [selectcountry, setSelectedcountry] = useState(null);
      const [modalStates, setModalStates] = useState({  modal1: false,modalEdit1: false,});
      const [countries, setCountries] = useState([]);
      const [documenttypes, setDocumenttypes] = useState([]);
      const [documents, setDocuments] = useState([]);
      const [isLoading4,setIsLoading4] = useState(false);
      const [isLoading1, setIsLoading1] = useState(false);
      const [docname, setDocname] = useState('');
      const [dateofissue, setDateofissue] = useState(null);
      const [expiryissue, setExpiryofissue] = useState(null);
      const [error422,setError422] = useState('');
      const [uploading, setUploading] = useState(false);
      const [progress, setProgress] = useState(0);
      const [progressColor, setProgressColor] = useState("#28a745");
      const [image, setImage] = useState([]);
      const [fileSize, setFileSize] = useState(0);
      const [fileType, setFileType] = useState('');
      const [showInputBox, setShowInputBox] = useState(false);
      const [inputValue, setInputValue] = useState('');
      const [selectedDocument, setSelectedDocument] = useState(null);
      const [expiryerror,setExpiryerror] = useState({
        expirydateerror : '',editexpirydateerror : ''
      });
      const [editDocName, setEditDocName] = useState('');
      const [editCountry, setEditCountry] = useState('');
      const [format, setFormat] = useState('');
      const [generaldocumentsave , setGeneraldocumentsave] = useState(false);
      const [generaldocumentsaveupdate , setGeneraldocumentsaveupdate] = useState(false);
      const [editDateOfIssue, setEditDateOfIssue] = useState(null);
      const [editExpiryDate, setEditExpiryDate] = useState(null);
      const [editshowInputBox, setEditShowInputBox] = useState(false);
      const [editinputValue, setEditInputValue] = useState('');
      const [editImage, setEditImage] = useState([]);
      const [editImagedetails, setEditImageDetails] = useState(false);
      const [user_document_id, setUser_Document_id] = useState('');
      const [generaldocumenttype, setGeneraldocumenttypes] = useState('');
      const [selectedImage, setSelectedImage] = useState(null);
      const [isPreviewVisible, setPreviewVisible] = useState(false);
      const [calculatedWidth, setCalculatedWidth] = useState('200px');
      const [selectedImage1, setSelectedImage1] = useState(null);
      const [isPreviewVisible1, setPreviewVisible1] = useState(false);
      const [isPreviewVisible2, setPreviewVisible2] = useState(false);
      const selectRef = useRef(null);
      const [uniqueKeys, setUniqueKeys] = useState({ uniqueKey: 0, uniqueKey1: 0 ,uniqueKey2: 0});
      const genraldocumentget = useCallback(async (selectcountry) => {
        try {
          setIsLoading1(true);
          const params = {
            country_id: selectcountry,
            is_app: 0
          }
      
          const response = await axiosInstance.get(documentsgeneralgetapiurl, {
            params: params,
          });
      
          if (response.status === 200) {
            setDocuments(response.data.data.values);
          }
        } catch (error) {
          handleApiError(error, setIsLoading1, null, true, showErrorToast);
        } finally {
          setIsLoading1(false);
        }
      }, [showErrorToast]); 
      const fetchCountries = useCallback(async () => {
        try {
          const response = await axiosInstance.get(countryselect, {
            headers: {
              'Content-Type': 'application/json',
            },
            withCredentials: true, // Include credentials for cross-origin requests
          });
          const data = response.data;
          if (data.message === 'Success') {
            const countryList = data.data;
            setCountries(countryList);
            const defaultCountry = countryList.find(country => country.country_code === ipCountryCode);
            if (defaultCountry) {
              setSelectedcountry(defaultCountry.id);
            }
          }
        } catch (error) {
          handleApiError(error, setIsLoading1, null, true, showErrorToast);
        }
      }, [ipCountryCode,showErrorToast]);
      useEffect(() => {
        if (ipCountryCode !== null) {
          fetchCountries();
        }
      }, [ipCountryCode, fetchCountries])
      const fetchCountriesdocumenttypes = useCallback(async () => {
        try {
          if (selectcountry || editCountry) {
            const response = await axiosInstance.get(`${documenttypesapiurl}?country_id=${selectcountry || editCountry}`);
            if (response.status === 200) {
              const documenttypes = response.data.data.values;
              setDocumenttypes(documenttypes);
            }
          }
        } catch (error) {
          handleApiError(error, setIsLoading1, null, true, showErrorToast);
        }
      }, [selectcountry, editCountry,showErrorToast]);
      
      const fetchData = useCallback(async() => {
        if(!ipCountryCode){
          getUserIpCountryCode();
        }
        if (selectcountry !== null) {
          await genraldocumentget(selectcountry);
          await fetchCountriesdocumenttypes();
        }
      }, [ipCountryCode, getUserIpCountryCode, selectcountry, genraldocumentget, fetchCountriesdocumenttypes]);
    
      useEffect(() => {
        fetchData();
      }, [fetchData]);
      const initialState = {
        generaldocumenttype: '',
        docname: '',
        dateofissue: '',
        expiryissue: '',
        uploading: false,
        // ... other state variables
      };
      const handleClose1 = (modalName) => {
        // setShow1(false);
        setModalStates((prevState) => ({
          ...prevState,
          [modalName]: false,
        }));
        setProgress(100);
        setError422('');
        setExpiryerror((prevState) => ({
          ...prevState,
          expirydateerror:''
        }))
        setGeneraldocumenttypes(initialState.generaldocumenttype);
        setDocname(initialState.docname);
        setDateofissue(initialState.dateofissue);
        setExpiryofissue(initialState.expiryissue);
        setUploading(initialState.uploading);
        setImage([]);
      }
      const handledelete = () => {
        setUploading(false);
        setProgress(0);
        setImage([]);
        setDocname('');
      }
      const popuplodcancel = () => {
        handleClose1('modal1');
        setProgress(100);
      }
      const close1 = () => {
        handleClose1('modal1');
        setProgress(100);
      }
      const Addnewgeneral = (modalName) => {
        if (selectcountry) {
          // setShow1(true);
          setModalStates((prevState) => ({
            ...prevState,
            'modalEdit1': false,
            [modalName]:true,
          }));
          setDateofissue(initialState.dateofissue);
          setExpiryofissue(initialState.expiryissue);
        }
        else {
          showErrorToast(
            "Please Select a Country First",
            '422Error',
          ); 
        }
      }
      const errorclar422 = () => {
        setError422('');
      }
      const fileInputRef = useRef(null);
      const handleFileClick = () => {
        errorclar422();
        fileInputRef.current.click();
      }
      const fileInputRef1 = useRef(null);
      const handleFileimageClick1 = () => {
        errorclar422();
        fileInputRef1.current.click();
      }
      const handleFileDrop = (selectedFile) => {
        handleFileChange(
          { target: { files: [selectedFile] } },
          setImage,
          setFileSize,
          setFileType,
          setProgress,
          setProgressColor,
          setUploading,
          setFormat,
          setDocname
        );
      };
      const handleeditFileDrop = (selectedFile1) => {
        handleEditfilechange(
          { target: { files: [selectedFile1] } },
          setEditImage,
          setFileSize,
          setFileType,
          setEditProgress,
          setEditProgressColor,
          setEditImageDetails,
          setFormat,
          setEditDocName
        );
      } 
      const handleEyeClickimage = (document) => {
        if(document.user_document_map[document.user_document_map.length - 1].path.endsWith('.pdf')){
          setSelectedDocument(document);
          const newUniqueKey = generateUniqueKey('model');
          setUniqueKeys((prevState)=>({
            ...prevState,uniqueKey2 : newUniqueKey
          }))
        }else{
          setSelectedDocument(document);
          setPreviewVisible2(true);
        }
      };
      const handleEyeClick = (image, modalId) => {
        if(image[0] && typeof image[0] === 'string' && image[0].startsWith('data:application/pdf')){
          setSelectedImage(image[0]);
          const newUniqueKey = generateUniqueKey(modalId);
          setUniqueKeys((prevState)=>({
           ...prevState, uniqueKey: newUniqueKey,
          }));
        setModalStates((prevState) => ({
          ...prevState,
          [modalId]: false,
        }));
        }else{
          setSelectedImage(image[0]);
          setPreviewVisible(true); 
          setModalStates((prevState) => ({
            ...prevState,
            [modalId]: false,
          }));
        }
      };   
      const handleSelectChange = (selectedOption) => {
        errorclar422();
        setGeneraldocumenttypes(selectedOption.value);
      };
      const selectedcountrychange = (selectedOption) => {
        if (selectedOption) {
          setSelectedcountry(selectedOption.value);
        } else {
          setSelectedcountry(null);
        }
      };
      const options = documenttypes.map((document_type) => ({
        value: document_type.name,
        label: document_type.name,
      }));
      options.push({ value: 'others', label: 'Others' });
      const countryoptions = countries.map((country) => ({
        value: country.id,
        label: country.name,
      }));
      useEffect(() => {
        if (generaldocumenttype === 'others') {
          setInputValue('');
          setShowInputBox(true);
        } else {
          setShowInputBox(false);
        }
      }, [generaldocumenttype]);
      const handleInputChange = (inputValue) => {
        setInputValue(inputValue);
      };
      const handleInputClear = () => {
        setInputValue('');
        setGeneraldocumenttypes('');
        setShowInputBox(false);
      };
      const checkFormgeneralValidity = () => {
        let is_general_check;
        if(generaldocumenttype === 'others')
        {
           is_general_check = inputValue.trim();
        }
        else
        {
           is_general_check = generaldocumenttype;
        }
        const isValid = (
          is_general_check !== '' &&
           docname.trim() !== '' &&
           dateofissue !== '' &&
          //  expiryissue !== '' &&
           dateofissue !== null &&
          //  expiryissue !== null &&
           image.length > 0 
        );
        setGeneraldocumentsave(isValid);
      };
      useEffect(() => {
        checkFormgeneralValidity();
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [generaldocumenttype,inputValue,docname,dateofissue,image]);
      const generalsave = async () => {
        try {
          setIsLoading4(true)
          const documentTypeToSend = generaldocumenttype === 'others' ? inputValue : generaldocumenttype;
          const data = {
            document_type: documentTypeToSend,
            document_name: docname,
            country_id: selectcountry,
            // expired_at: formatDateForExpiry(expiryissue),
            issued_at: formatDateForInput(dateofissue),
            images: image,
          }
          if (expiryissue) {
            data.expired_at = formatDateForExpiry(expiryissue);
          }
          const response = await axiosInstance.post(documentstoreapiurl, data,);
          if (response.status === 200) {
            handleClose1('modal1');
            
            // fetchCountries();
            // genraldocumentget();
            await fetchData();
            setIsLoading4(false)
            // showSuccessToast(response.data.message,'422Error',);
          }
        } catch (error) {
          handleApiError(error,setIsLoading4,setError422,false,showErrorToast);
        }
      }
      const handledeletedocument = async (document) => {
        try {
          const user_document_id1 = document.user_document_map[0].user_document_id;
          const result = await Swal.fire({
            title: 'Are you sure?',
            text: 'You won\'t be able to revert this!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          });
    
          if (result.isConfirmed) {
            setIsLoading4(true);
            const response = await axiosInstance.post(`${documentsgeneraldeleteapiurl}?user_document_id=${user_document_id1}`, {},);
            if (response.status === 200) {
              // genraldocumentget();
              await fetchData();
              setIsLoading4(false)
              // showSuccessToast(response.data.message,'422Error',);
            }
          }
        }
        catch (error) {
          handleApiError(error,setIsLoading4,null,true,showErrorToast);
        }
      }
      const [editDocumentType, setEditDocumentType] = useState("");
      const handleEditSelectChange = (selectedOption) => {
        setEditDocumentType(selectedOption.value);
        errorclar422();
      };
      useEffect(() => {
        if (editDocumentType === 'others') {
          // setEditInputValue('');
          setEditShowInputBox(true);
        } else {
          setEditShowInputBox(false);
        }
      }, [editDocumentType]);
      const handleEditInputClear = () => {
        setEditInputValue('');
        setEditDocumentType('');
        setShowInputBox(false);
      };
      const handelgeneraledit = async (document,modalName) => {
        try {
          setModalStates((prevState) => ({
            ...prevState,
            'modal1': false,
            [modalName]: true,
          }));
          // setShowedit1(true);
          if (document) {
            setEditDocName((prevEditDocName) => document.document_name || prevEditDocName);
            setEditCountry(document?.document_country?.country_id || []);
            const selectedDocumentType = document?.document_country?.document_type?.name || '';
            const selectedDocumentTypeid = document?.document_country?.document_type?.id || '';
            const isDocumentTypeInList = documenttypes.some(type => type.name === selectedDocumentType);
            if (isDocumentTypeInList) {
              setEditDocumentType({label:selectedDocumentType , value:selectedDocumentTypeid });
              setEditInputValue('');
              setEditShowInputBox(false);
            } else {
              setEditDocumentType('others');
              setEditInputValue(selectedDocumentType);
              setEditShowInputBox(true);
            }
            const issuedDate = document.issued_at;
            const datePart = issuedDate.split(' ')[0];
            const datepart2 = dayjs(datePart, 'YYYY-MM-DD')
            setEditDateOfIssue(datepart2);
            
            if (document.expired_at) {
              const formattedExpiredDate = document.expired_at.split(' ')[0];
              const formatDateForExpirydate = dayjs(formattedExpiredDate,'YYYY-MM-DD');
              setEditExpiryDate(formatDateForExpirydate);
            }else{
              setEditExpiryDate(null);
            }
              // const formattedExpiredDate = parse(document.expired_at.split(' ')[0], 'yyyy-MM-dd', new Date());
            //   setEditExpiryDate(formattedExpiredDate);
            // } else {
            //   setEditExpiryDate(null);
            // }
            setUser_Document_id(document?.user_document_map[0]?.user_document_id);
            if (document.user_document_map?.length > 0) {
              const lastDocument = document.user_document_map[
                document.user_document_map.length - 1
              ];
              const lastDocumentPath = lastDocument.path;
              const imageUrl = `${publicUrl}/core/${lastDocumentPath}`;
              setEditImage([imageUrl]);
              const fileSize = formatFileSize(lastDocument.fileSize);
              setFileSize(fileSize);
              setFormat(parseFileExtension(lastDocument.path))
            }
            fetchCountriesdocumenttypes();
          }
        } catch (error) {
          console.error("Error in handelgeneraledit:", error);
        }
      }
      // const [editFileType, setEditFileType] = useState(editImage.fileType);
      // const [editFileSize, setEditFileSize] = useState(editImage.fileSize);
      const [editProgress, setEditProgress] = useState(100);
      const [editProgressColor, setEditProgressColor] = useState('#28a745');
      const handleEyeClick1 = (editImage, modalName) => {
        if (editImage[0] && typeof editImage[0] === 'string' && (editImage[0].endsWith('.pdf') || editImage[0].startsWith('data:application/pdf'))) {
          setSelectedImage1(editImage[0]);
          const newUniqueKey = generateUniqueKey(modalName);
          setUniqueKeys((prevState)=>({
            ...prevState, uniqueKey1: newUniqueKey,
           }));
          setModalStates((prevState) => ({
            ...prevState,
            [modalName]: false,
          }));
        } else {
          setSelectedImage1(editImage[0]);
          setModalStates((prevState) => ({
            ...prevState,
            [modalName]: false,
          }));
          setPreviewVisible1(true);
        }
      };
      
      const [ischange, setIschange] = useState(false);
      const handledelete1 = () => {
        setEditProgress(0);
        // setEditFileSize(0);
        setEditImage('');
        setEditImageDetails(true);
        setEditDocName('');
        setIschange(true);
      }
      const generalupdatecancel = () => {
        handleCloseedit1('modalEdit1');
      }

      const checkFormgeneralEditValidity = useCallback(()=>{
        let is_general_check;
        if(editDocumentType === 'others')
        {
           is_general_check = editinputValue.trim();
        }
        else
        {
           is_general_check = editDocumentType;
        }
        const isValid = (
          is_general_check !== '' &&
           editDocName.trim() !== '' &&
           editDateOfIssue !== '' &&
          //  editExpiryDate !== '' &&
           editDateOfIssue !== null &&
          //  editExpiryDate !== null &&
           editImage.length > 0 
        );
        setGeneraldocumentsaveupdate(isValid);
      },[editDocumentType,editinputValue,editDocName,editDateOfIssue,editImage]);
      useEffect(() => {
        checkFormgeneralEditValidity();
      }, [checkFormgeneralEditValidity]);
      const generalupdatesave = async () => {
        try {
          setIsLoading4(true);
          let data;
          const documentTypeToSend = editDocumentType === 'others' ? editinputValue : (typeof editDocumentType === 'object' ? editDocumentType.label : editDocumentType);
          if (ischange) {
            data = {
              user_document_id: user_document_id,
              document_type: documentTypeToSend,
              document_name: editDocName,
              country_id: editCountry,
              expired_at: formatDateForExpiry(editExpiryDate),
              issued_at: formatDateForInput(editDateOfIssue),
              images: editImage,
            }
          }
          else {
            data = {
              user_document_id: user_document_id,
              document_type: documentTypeToSend,
              document_name: editDocName,
              country_id: editCountry,
              expired_at: formatDateForExpiry(editExpiryDate),
              issued_at: formatDateForInput(editDateOfIssue),
            }
          }
          const response = await axiosInstance.post(documentsgeneralupdateapiurl, data,);
          if (response.status === 200) {
            handleCloseedit1('modalEdit1');
            await fetchData();
            setIsLoading4(false);
            // showSuccessToast(response.data.message,'422Error',);
          }
        } catch (error) {
          handleApiError(error,setIsLoading4,setError422,false,showErrorToast);
        }
      }
      const handleCloseedit1 = (modalName) => {
        // setShowedit1(false);
        setModalStates((prevState) => ({
          ...prevState,
          [modalName]: false,
        }));
        setIschange(false);
        setEditImageDetails(false);
        setError422('');
        setEditProgress(100);
      }
      const closeedit1 = () => {
        handleCloseedit1('modalEdit1');
      } 
      const calculateWidth = (label) => {
        const tempDiv = document.createElement('div');
        tempDiv.style.position = 'absolute';
        tempDiv.style.visibility = 'hidden';
        tempDiv.style.height = 'auto';
        tempDiv.style.width = 'auto';
        tempDiv.style.whiteSpace = 'nowrap';
        document.body.appendChild(tempDiv);
    
        tempDiv.innerText = label;
        const newWidth = tempDiv.offsetWidth + 30; // Add some padding for the arrow
        document.body.removeChild(tempDiv);
    
        return newWidth;
      };
    
      const updateSelectWidth = useCallback(()=>{
        const selectedOption = countryoptions.find(option => option.value === selectcountry);
        const newWidth = selectedOption ? calculateWidth(selectedOption.label + 30) : 200;
        setCalculatedWidth(`${newWidth}px`);
      },[selectcountry,countryoptions])
    
      useEffect(() => {
        updateSelectWidth();
      }, [updateSelectWidth]);
    
      useEffect(() => {
        const handleResize = () => {
          updateSelectWidth();
        };
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, [selectcountry,updateSelectWidth]);
    
      const customStyles = {
        control: (provided,state) => ({
          ...provided,
          minWidth: '200px',
          width: calculatedWidth,
          backgroundColor: '#FFFFFF',
          color: 'white',
          border: state.isFocused ? 'none' : '1px solid black',
          '&:hover': {
          borderColor:  'black' ,
          },
          boxShadow: state.isFocused ? 'none' : 'none',
          borderRadius: '4px',
          height: '35px',
          textAlign: 'center', // Center text
          display: 'flex',
          paddingRight:'30px',
          alignItems: 'center',
          justifyContent: 'center',
        }),
        menu: (provided) => ({
          ...provided,
          width: calculatedWidth,
          minWidth: '100%',
        }),
        singleValue: (provided) => ({
          ...provided,
          color: 'black',
          textAlign: 'center',
          width: '100%',
        }),
        placeholder: (provided) => ({
          ...provided,
          color: 'black',
          textAlign: 'center',
          width: '100%',
        }),
        input: (provided) => ({
          ...provided,
          color: 'black',
          textAlign: 'center',
        }),
      };
      const DropdownIndicator = null; 
      
  const customStyles1 = (hasValue) => ({
    control: (provided,state) => ({
      ...provided,
      borderColor: hasValue ? 'black' : '#DCDCDC',
      height:'40px',
      fontSize:'14px',
      boxShadow: state.isFocused ? 'none' : 'none',
      '&:hover': {
        borderColor: hasValue ? 'black' : '#DCDCDC',
      },
    }),placeholder: (provided) => ({
      ...provided,
      color: '#ABABAB',
      width: '100%',
      fontSize:'13px',
      fontWeight:'500',
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: hasValue ? 'black' : '#ABABAB',
      svg: {
        fill: hasValue ? 'black' : '#B9B9B9',
      },
    }),
  });
  const expiryPickerRef = useRef(null);
  const dateOfIssuePickerRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleOpenChange = (open) => {
    setIsOpen(open);
  }; 
  const handleExpiryClick = (e) => {
    if (!dateofissue) {
      setExpiryerror((prevState) => ({
        ...prevState,
        expirydateerror:'Please select Date of Issue first'
      }));
      // setExpiryerror('Please select Date of Issue first');
      setIsOpen(false);
    } else {
      setExpiryerror((prevState) => ({
        ...prevState,
        expirydateerror:''
      }));
      setIsOpen(true);
    }
  };
  const handleEditExpiryClick = (e) => {
    if (!editDateOfIssue) {
      setExpiryerror((prevState) => ({
        ...prevState,
        editexpirydateerror:'Please select Date of Issue first'
      }));
      // setExpiryerror('Please select Date of Issue first');
      setIsOpen(false);
    } else {
      setExpiryerror((prevState) => ({
        ...prevState,
        editexpirydateerror:''
      }));
      setIsOpen(true);
    }
  };
 
  const handlePDFViewerClose = (modalId) => {
    setModalStates((prevState) => ({
      ...prevState,
      [modalId]: true,
    }));
  };
  
  return (
    <>
    {isLoading4 && (
                <>   
                    <div className="loaderover">
                    <div className="loader ">
                    </div>
                    </div>
                </>
            )} 
      <div className="select-custom-container" style={{ minWidth: '223px', width: `calc(${calculatedWidth} + 23px)`}}>
      <Select
        ref={selectRef}
        className="generalcountryselect"
        value={countryoptions.find(option => option.value === selectcountry)}
        onChange={selectedcountrychange}
        options={countryoptions}
        placeholder="Select Country"
        styles={customStyles}
        components={{ DropdownIndicator }}
      />
    </div>      
      {documents.length > 0 ? (
        <>
        <div className="divshow1400">
        {documents.map((document, index) => ( 
          <div key={index} className="docbox">
            <div className="d-flex justify-content-between align-items-center" style={{gap:'10px'}}>
              <div className="d-flex align-items-center" style={{width:'28%'}}>
              <div>
                <img src={file} alt="file" width="60px" className="me-2" height="auto" />
              </div>
              <div style={{width:'100%',overflow:'hidden',}}>
                <p className="docviewp truncated-text">Document Type</p>
                <p className="docviewp2 truncated-text">
                  {document.document_country.document_type.name}
                </p>
                </div>
              </div>
              <div style={{width:'23%',overflow:'hidden',}}>
                <p className="docviewp truncated-text">Document name</p>
                <p className="docviewp2 truncated-text">
                  {document.document_name}
                </p>
              </div>
              <div style={{width:'15%',overflow:'hidden',}}>
                <p className="docviewp truncated-text">Date of Issue</p>
                <p className="docviewp2 truncated-text">
                  {getFormattedDate(document.issued_at)}
                </p>
              </div>
              <div style={{width:'15%',overflow:'hidden',}}>
                <p className="docviewp truncated-text">Expire Date</p>
                <p className="docviewp2 truncated-text">
                  {document.expired_at ? getFormattedDate(document.expired_at) : '\u00A0'}
                </p>
              </div>
              <div className="d-flex align-items-center justify-content-end" style={{width:'19%'}}>
              <div className="me-3">
                <button className="docedit" onClick={() => handelgeneraledit(document,'modalEdit1')}><img className="me-3" src={edit} alt="edit" width="18px" height="auto"/>Edit</button>
              </div>
              <div className="d-flex flex-column">
                <img
                  className="mb-2 documenteyeview"
                  src={eye}
                  onClick={() => handleEyeClickimage(document)}
                  alt="eye"
                />
                <img
                  className="documenteyeview"
                  src={cancel}
                  onClick={() => handledeletedocument(document)}
                  alt="file"
                  width="29.89px"
                  height="auto"
                />
              </div>
              </div>
            </div>
          </div>
          
        ))}
        </div>
        <div className="divshow1401">
  {documents.map((document, index) => ( 
    <div key={index} className="docbox">
      <div className="d-flex justify-content-between align-items-center" style={{gap:'20px'}}>
        <div className="d-flex align-items-center divshow140130 ">
          <img src={file} alt="file" width="60px" className="me-3" height="auto" />
          <div style={{width:'100%', overflow:'hidden'}}>
            <p className="docviewp truncated-text">Document Type</p>
            <p className="docviewp2 truncated-text">
              {document.document_country.document_type.name}
            </p>
          </div>
        </div>
        <div className="divshow140130" style={{overflow:'hidden',}} >
          <p className="docviewp truncated-text">Document name</p>
          <p className="docviewp2 truncated-text">
            {document.document_name}
          </p>
        </div>
        <div className="divshow140120" style={{overflow:'hidden',}} >
          <p className="docviewp truncated-text">Date of Issue</p>
          <p className="docviewp2 truncated-text">
            {getFormattedDate(document.issued_at)}
          </p>
        </div>
        <div className="divshow140120" style={{overflow:'hidden',}}>
          <p className="docviewp truncated-text">Expire Date</p>
          <p className="docviewp2 truncated-text">
            {document.expired_at ? getFormattedDate(document.expired_at) : '\u00A0'}
          </p>
        </div>
        <div className="d-flex align-items-center justify-content-end divshow140150end" style={{width:'20%'}}>
        <div className="divshow140150" >
          <button className="docedit me-3" onClick={() => handelgeneraledit(document,'modalEdit1')}>
            <img className="me-3" src={edit} alt="edit" width="18px" height="auto"/>
            Edit
          </button>
          </div>
          <div className="d-flex flex-column justify-content-end align-items-end divshow140150" >
            <img
              className="mb-2 documenteyeview"
              src={eye}
              onClick={() => handleEyeClickimage(document)}
              alt="eye"
            />
            <img
              className="documenteyeview"
              src={cancel}
              onClick={() => handledeletedocument(document)}
              alt="file"
              width="29.89px"
              height="auto"
            />
          </div>
        </div>
      </div>
    </div>
  ))}
</div>

        </>
      ) : (
        <div className="d-flex mt-5 justify-content-center align-items-center flex-column generalnew">
          {!isLoading1 && (
            <>
              <img
                src={documentorange}
                alt="document"
                className="docorangeimage"
              />
              <h6 className="addnewgeneralh6">Add General ID</h6>
              <p className="generalp">
                For legitimacy, add documents confirming your identity in this
                country. Could be a National ID, driver’s license, worker’s
                license, etc
              </p>
              <button className="addnewgeneralbutton" onClick={()=>Addnewgeneral('modal1')}>
                Add General ID
              </button>
            </>
          )}
          {isLoading1 && (
            <>
              <SkeletonLoader  documentRef={documentRef}/>
            </>
          )}
        </div>
      )}

      {documents.length > 0 && (
        <div className="text-end mt-3" style={{ width: "97%" }}>
          <button className="docaddnew" onClick={()=>Addnewgeneral('modal1')}>
            <img
              src={plus1}
              className="me-2"
              alt="plus"
              width="15px"
              height="auto"
            ></img>
            New
          </button>
        </div>
      )}
      {/* general popup */}

      <Modal show={modalStates.modal1} dialogClassName="document-dialog1" contentClassName="document-content1" onHide={()=>handleClose1('modal1')} centered>
        <Modal.Body style={{ margin: '0', padding: '0' }}>
          <div className="d-flex align-items-center justify-content-between docpop">
            <h6 className="docpoph6">Add General Identification</h6>
            <img src={closecircle} onClick={close1} alt="close" className="docpopclose"></img>
          </div>
          <div className="error-message1">{error422}</div>
          <div>
             {uploading ? (
              <div className="popprogressbox">
                <div className="d-flex align-items-center" style={{gap:'10px'}}>
                  <div className="d-flex align-items-center mb-2" style={{width:'80%'}}>
                    {image[0] && typeof image[0] === 'string' && image[0].startsWith('data:application/pdf')  ? (
                       <img src={transparent} alt="file" className="popuploadfileimg me-3"></img>
                    ):(
                      <img src={image} alt="file" className="popuploadfileimg me-3"></img>
                    )}
                    <div style={{overflow:'hidden'}}>
                      <p className="popfilefrontp">{docname ? `${transformString(fileType)}.${transformString(format)}` : `${transformString(fileType)}.${transformString(format)}`}</p>
                      <p className="popfilekb">{formatFileSize(fileSize)}</p>
                    </div>
                  </div>
                  <div style={{width:'20%',textAlign:'end'}}>
                    {progress === 100 && (
                      <img src={eye} alt="eye" onClick={() => handleEyeClick(image,'modal1')} className="popupeye me-3"></img>
                    )}
                    <img src={popdeletes} alt="delete" className="popupeye" onClick={handledelete}></img>
                  </div>
                </div>
                <div className="progress mt-1 mb-1">
                  <div className="progress-bar" role="progressbar" style={{ width: `${progress}%`, backgroundColor: progressColor }} aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100">
                    {``}
                  </div>
                </div>
              </div>
            ) : (
              <div className="popinputbox mt-3" onClick={handleFileClick} onDrop={(e) => handleDrop(e, handleFileDrop)}
                onDragOver={handleDragOver}>
                <div className="d-flex align-items-center justify-content-center flex-column p-4">
                  <img src={fileopen} className="popuploadflle" alt="file" width='60px' height='auto'></img>
                  <h5 className="popuploadh5"><span className="popspan">Click to Upload</span>or drag and drop</h5>
                  <p className="popuploadp">File format: .pdf, .png, .jpg</p>
                  <p className="popuploadp">( Max. File size : 20 MB)</p>
                </div>
                <input type="file" accept=".jpg, ,jpeg, .png, .pdf" ref={fileInputRef} style={{ display: 'none' }}  onChange={(e) => handleFileChange(e, setImage, setFileSize, setFileType, setProgress, setProgressColor, setUploading,setFormat,setDocname)} />
              </div>
            )}
            <div className="row docpoprow">
              <div className="col-sm-6 col-12">
                <label className="docpoplabel">Document Type<span className="docpopstar">*</span></label>
                {showInputBox ? (
                  <div style={{ position: 'relative' }}>
                    <input
                      type="text"
                      className={`form-control docform ${inputValue ? 'docform-active' : ''}`}
                      value={inputValue}
                      onChange={(e) => {handleInputChange(e.target.value);errorclar422()}}
                      placeholder="Enter Document Type"
                    />
                    <p onClick={handleInputClear} style={{ position: 'absolute', right: '10px', top: '55%', transform: 'translateY(-50%)', cursor: 'pointer',color:inputValue ? 'black' : '#ABABAB',marginBottom:'0px' }}>
                      &#x2715; {/* Close icon (X) */}
                    </p>
                  </div>
                ) : (
                  <Select value={options.find(option => option.value === generaldocumenttype)} onChange={handleSelectChange} options={options} styles={customStyles1(Boolean(generaldocumenttype))} placeholder="Select Document Type"/>
                )}
              </div>
              <div className="col-sm-6 col-12">
                <label className="docpoplabel">Document Name<span className="docpopstar">*</span></label>
                <input className={`form-control docform ${docname ? 'docform-active' : ''}`} placeholder="Enter Document Name" name="docname" readOnly={image.length === 0} value={docname} onChange={(e) =>{ setDocname(e.target.value); setFileType(e.target.value);errorclar422();}}></input>
              </div>
            </div>
            <div className="row docpoprow">
              <div className="col-sm-6 col-12">
                <label className="docpoplabel">Date of Issue<span className="docpopstar">*</span></label>
             <DatePicker value={dateofissue} ref={dateOfIssuePickerRef}  disabledDate={disableFutureDates} onChange={(date) =>{ setDateofissue(date);errorclar422();setExpiryofissue('');setExpiryerror((prevState) => ({...prevState,expirydateerror:''}))}} picker="month" placeholder="MM/YY" className={`${dateofissue ? 'ant-picker-active' : 'ant-picker'}`} style={{borderColor: dateofissue ? 'black' : '#d9d9d9',boxShadow:'none' , padding:'.375rem .75rem',borderRadius:'.25rem'}} format="MM/YYYY" getPopupContainer={(trigger) => trigger.parentNode} suffixIcon={<CustomSuffixIcon isActive={dateofissue}/>}  onKeyDown={handleKeyDown}/>
             {expiryerror.expirydateerror && <div className="error-message">{expiryerror.expirydateerror}</div>}
              </div>
              <div className="col-sm-6 col-12">
                <label className="docpoplabel" >Expiry Date</label>
             <DatePicker value={expiryissue}  onClick={handleExpiryClick}  open={isOpen}  onOpenChange={handleOpenChange} ref={expiryPickerRef}  disabledDate={disablePastDates(dateofissue)}  onChange={(date) => {setExpiryofissue(date);errorclar422();}} picker="month" placeholder="MM/YY" className={`${expiryissue ? 'ant-picker-active' : 'ant-picker'}`} style={{borderColor: expiryissue ? 'black' : '#d9d9d9',boxShadow:'none' , padding:'.375rem .75rem',borderRadius:'.25rem'}} format="MM/YYYY" getPopupContainer={(trigger) => trigger.parentNode} suffixIcon={<CustomSuffixIcon isActive={expiryissue}/>} onKeyDown={handleKeyDown} />
              </div>
            </div>
            <div className="d-flex justify-content-end align-items-center mt-4 mb-2">
              <button className="docpopcancel me-3" onClick={popuplodcancel}>Cancel</button>
              <button className="docpopsave"  style={{ backgroundColor: generaldocumentsave ? '#5D64F2' : '#EAEAEA', color: generaldocumentsave ? '#FFF' : '#333' , cursor: generaldocumentsave ? 'pointer' : 'not-allowed'}} disabled={!generaldocumentsave} onClick={generalsave}>Save</button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* general editpopup */}
      <Modal show={modalStates.modalEdit1} dialogClassName="document-dialog1" contentClassName="document-content1" onHide={()=>handleCloseedit1('modalEdit1')} centered>
        <Modal.Body style={{ margin: '0', padding: '0' }}>
          <div className="d-flex align-items-center justify-content-between docpop">
            <h6 className="docpoph6">Add General Identification</h6>
            <img src={closecircle} onClick={closeedit1} alt="close" className="docpopclose"></img>
          </div>
          <div className="error-message1">{error422}</div>
          <div>
          {editImagedetails ? (
              <div className="popinputbox mt-3" onClick={handleFileimageClick1} onDrop={(e) => handleDrop1(e, handleeditFileDrop)}
                onDragOver={handleDragOver1}>
                <div className="d-flex align-items-center justify-content-center flex-column p-4">
                  <img src={fileopen} className="popuploadflle" alt="file" width="60px" height="auto" />
                  <h5 className="popuploadh5"><span className="popspan">Click to Upload</span>or drag and drop</h5>
                  <p className="popuploadp">( Max. File size: 20 MB)</p>
                </div>
                <input type="file" ref={fileInputRef1} style={{ display: 'none' }} accept=".jpg, .jpeg, .png, .pdf" onChange={(e) => handleEditfilechange(e, setEditImage, setFileSize, setFileType, setEditProgress, setEditProgressColor, setEditImageDetails, setFormat, setEditDocName)} />
              </div>
            ) : (
              <div className="popprogressbox">
                <div className="d-flex align-items-center " >
                  <div className="d-flex align-items-center mb-2"style={{width:'80%'}}>
                  {editImage[0] && typeof editImage[0] === 'string' && (editImage[0].endsWith('.pdf') || editImage[0].startsWith('data:application/pdf')) ? (
                     <img src={transparent} alt="file" className="popuploadfileimg me-3" />
                  ) : (
                    <img src={editImage} alt="file" className="popuploadfileimg me-3" />
                  )}
                    <div style={{overflow:'hidden'}}>
                      <p className="popfilefrontp">{editDocName ? `${transformString(editDocName)}${transformString(format)}`: `${transformString(fileType)}${transformString(format)}`}</p>
                      {fileSize && fileSize >= 1024 && (
                        <p className="popfilekb">{formatFileSize(fileSize)}</p>
                      )}
                    </div>
                  </div>
                  <div className="text-end" style={{width:'20%'}}>
                    {editProgress === 100 && (
                      <img src={eye} alt="eye" onClick={() => handleEyeClick1(editImage,'modalEdit1')} className="popupeye me-3" />
                    )}
                    <img src={popdeletes} alt="delete" onClick={() => handledelete1()} className="popupeye" />
                  </div>
                </div>
                <div className="progress mt-1 mb-1">
                  <div className="progress-bar" role="progressbar" style={{ width: `${editProgress}%`, backgroundColor: editProgressColor }} aria-valuenow={editProgress} aria-valuemin="0" aria-valuemax="100">
                    {``}
                  </div>
                </div>
              </div>
            )}
            <div className="row docpoprow">
              <div className="col-sm-6 col-12">
                <label className="docpoplabel">Document Type<span className="docpopstar">*</span></label>
                {editshowInputBox ? (
                  <div style={{ position: 'relative' }}>
                    <input
                      type="text"
                      className={`form-control docform ${editinputValue ? 'docform-active' : ''}`}
                      value={editinputValue}
                      onChange={(e) => {setEditInputValue(e.target.value);errorclar422();}}
                      placeholder="Enter custom value"
                    />
                    <span onClick={handleEditInputClear} style={{ position: 'absolute', right: '0px', top: '50%',color:editinputValue ? 'black' : '#ABABAB',transform: 'translateY(-50%)', cursor: 'pointer' }}>
                      &#x2715; {/* Close icon (X) */}
                    </span>
                  </div>
                ) : (
                  <Select
          value={options.find(option => option.value === editDocumentType)}
          onChange={handleEditSelectChange}
          defaultValue={editDocumentType}
          options={options}
          styles={customStyles1(Boolean(editDocumentType))}
          placeholder="Select Document Type"
        />
                )}
              </div>
              <div className="col-sm-6 col-12">
                <label className="docpoplabel">Document Name<span className="docpopstar">*</span></label>
                <input className={`form-control docform ${editDocName ? 'docform-active' : ''}`} name="editDocName" placeholder="Enter Document Name" readOnly={editImage.length === 0} value={editDocName} onChange={(e) => {setEditDocName(e.target.value);errorclar422();}}></input>
              </div>
            </div>
            <div className="row docpoprow">
    <div className="col-sm-6 col-12">
      <label className="docpoplabel">Date of Issue<span className="docpopstar">*</span></label>
      <DatePicker value={editDateOfIssue}  disabledDate={disableFutureDates} onChange={(date) => {setEditDateOfIssue(date);errorclar422();setEditExpiryDate('')}} picker="month" placeholder="MM/YY" className={`${editDateOfIssue ? 'ant-picker-active' : 'ant-picker'}`} style={{borderColor: editDateOfIssue ? 'black' : '#d9d9d9',boxShadow:'none' , padding:'.375rem .75rem',borderRadius:'.25rem'}} format="MM/YYYY" getPopupContainer={(trigger) => trigger.parentNode} suffixIcon={<CustomSuffixIcon isActive={editDateOfIssue}/>}  onKeyDown={handleKeyDown}/>
      {expiryerror.editexpirydateerror && <div className="error-message">{expiryerror.editexpirydateerror}</div>}
    </div>
    <div className="col-sm-6 col-12">
      <label className="docpoplabel">Expiry Date</label>
      <DatePicker value={editExpiryDate} onClick={handleEditExpiryClick}  open={isOpen}  onOpenChange={handleOpenChange}  disabledDate={disablePastDates(editDateOfIssue)} onChange={(date) => {setEditExpiryDate(date);errorclar422();}} picker="month" placeholder="MM/YY" className={`${editExpiryDate ? 'ant-picker-active' : 'ant-picker'}`} style={{borderColor: editExpiryDate ? 'black' : '#d9d9d9',boxShadow:'none' , padding:'.375rem .75rem',borderRadius:'.25rem'}} format="MM/YYYY" getPopupContainer={(trigger) => trigger.parentNode} suffixIcon={<CustomSuffixIcon isActive={editExpiryDate}/>} onKeyDown={handleKeyDown} />
    </div>
  </div>
            
            <div className="d-flex justify-content-end align-items-center mt-4 mb-2">
              <button className="docpopcancel me-3" onClick={generalupdatecancel}>Cancel</button>
              <button className="docpopsave" style={{ backgroundColor: generaldocumentsaveupdate ? '#5D64F2' : '#EAEAEA', color: generaldocumentsaveupdate ? '#FFF' : '#333' , cursor: generaldocumentsaveupdate ? 'pointer' : 'not-allowed'}} disabled={!generaldocumentsaveupdate} onClick={generalupdatesave}>Save</button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
        
      {selectedDocument && (
       selectedDocument.user_document_map[selectedDocument.user_document_map.length - 1].path.endsWith('.pdf') ? ( 
        <PDFViewer key={uniqueKeys.uniqueKey2} fileUrl={`${publicUrl}/core/${selectedDocument.user_document_map[selectedDocument.user_document_map.length - 1].path}`}/>
      ) : ( 
      <Image src={`${publicUrl}/core/${selectedDocument.user_document_map[selectedDocument.user_document_map.length - 1].path}`} alt="Enlarged Image" preview={{visible: isPreviewVisible2,onVisibleChange: setPreviewVisible2, onClose: () => {setPreviewVisible2(false);},}}style={{ maxWidth: '100%', maxHeight: '80vh', zIndex: '9999', display: 'none' }}/>
      ))}
      

      {selectedImage && typeof selectedImage === 'string' && selectedImage.startsWith('data:application/pdf') ? (
      // <embed src={selectedImage} type="application/pdf" width="100%" height="100%" />
      <PDFViewer key={uniqueKeys.uniqueKey} fileUrl={selectedImage} onClose={() => handlePDFViewerClose('modal1')}/>
      ) : (
      <Image
      src={selectedImage}
      alt="Enlarged Image"
      preview={{
        visible: isPreviewVisible,
        onVisibleChange: setPreviewVisible,
        onClose: () => {
          setPreviewVisible(false);
          setModalStates((prevState)=>({
            ...prevState,'modal1' : true
          }))
          // setShow1(true); // Show another modal if needed
        },
      }}
      style={{ maxWidth: '100%', maxHeight: '80vh', zIndex: '9999',display: 'none' }}
      />
      )}


      {selectedImage1 && (
         typeof selectedImage1 === 'string' && (selectedImage1.endsWith('.pdf') || selectedImage1.startsWith('data:application/pdf') )? (
          <PDFViewer key={uniqueKeys.uniqueKey1} fileUrl={selectedImage1} onClose={() => handlePDFViewerClose('modalEdit1')}/>
        ) : (
        <Image src={selectedImage1} alt="Enlarged Image" preview={{ visible: isPreviewVisible1, onVisibleChange: setPreviewVisible1,onClose: () => {setPreviewVisible1(false);setModalStates((prevState)=>({...prevState,'modalEdit1' : true})); },}}style={{ maxWidth: '100%', maxHeight: '80vh', zIndex: '9999', display: 'none' }}/>
        )
      )}

    </>
  );
}
export default WithToast(Generalidentification);
