import { createSlice } from '@reduxjs/toolkit';

const academicLevelSlice = createSlice({
  name: 'academicLevels',
  initialState: {
    academicLevelOptions: [],
    loading: false,
    error: null,
  },
  reducers: {
    fetchAcademicLevelListPending: (state) => {
      state.loading = true;
      state.error = null;
    },
    fetchAcademicLevelListFulfilled: (state, action) => {
      state.loading = false;
      state.academicLevelOptions = action.payload;
    },
    fetchAcademicLevelListRejected: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  fetchAcademicLevelListPending,
  fetchAcademicLevelListFulfilled,
  fetchAcademicLevelListRejected,
} = academicLevelSlice.actions;

export default academicLevelSlice.reducer;
