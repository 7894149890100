import React,{useState,useRef,useEffect,useCallback} from "react";
import { useNavigate } from "react-router-dom";
import acdamicdeleteimg from '../images/trash-red-circle-01.png';
import Select from 'react-select';
import plus from '../images/upload-02.png';
import plus1 from '../images/plus.png';
import Modal from 'react-bootstrap/Modal';
import closecircle from '../images/times-circle-01.png';
import vechicleimg from '../images/vehicle-01.png';
import axiosInstance from "../api/utils/axiosutils";
import vechicledelete from '../images/Delete-icon-01.png';
import adddocument from '../images/document_add-01.png';
// import { UseToast } from "../api/common/toastcontext";
import Swal from 'sweetalert2';
import edit from '../images/job_details_icons/edit_1-01.png';
import { Image } from "antd";
import { removePrefix,extractLoginUser,handleApiError, removecore, extractImageString } from "../api/utils/utils";
import { vehiclecompanyapi, vechiclemodelapi,vechiclestoreapi,vechicleupdateapi,publicDomain,vechiclegetapi,vechicledeleteimageapi,vechicledeleteapi} from "../api/helper/urlhelper";
import SkeletonLoader from "./skeletonloader";
import WithToast from "../api/hooks/withtoasthook";
import { useUpload } from "../api/hooks/uploadhook";
const vehiclecompanyapiurl = vehiclecompanyapi();
const vechiclemodelapiurl = vechiclemodelapi();
const vechiclestoreapiurl = vechiclestoreapi();
const vechicledeleteapiurl = vechicledeleteapi();
const vechicleupdateapiurl = vechicleupdateapi();
const vechiclegetapiurl = vechiclegetapi();
const publicUrl = publicDomain();
const vechicledeleteimageapiurl = vechicledeleteimageapi();
function Vechicle({documentRef,showErrorToast}){
    const navigate = useNavigate();
    const login_user = extractLoginUser();
    // const {showErrorToast,showSuccessToast } = UseToast();
    const [vechicles, setVechicles] = useState([]);
    const [clickedImage, setClickedImage] = useState(null);
    const [showImageModal, setShowImageModal] = useState(false);
    const [vehicleBrand, setVehicleBrand] = useState(null);
    const [vehicleModel, setVehicleModel] = useState(null);
    const [vehicleColor, setVehicleColor] = useState('');
    const [vehicleNumber, setVehicleNumber] = useState('');
    const [numberOfSeats, setNumberOfSeats] = useState('');
    const [isLoading4,setIsLoading4] = useState(false);
    const [isLoading1, setIsLoading1] = useState(false);
    const [savebuttondisabled, setSaveButtonDisabled] = useState(false);
    const [saveupdatebuttondisabled, setSaveupdatebuttondisabled] = useState(false);
    const [brandError, setBrandError] = useState('');
    const [modelError, setModelError] = useState('');
    const [colorError, setColorError] = useState('');
    const [numberError, setNumberError] = useState('');
    const [seatsError, setSeatsError] = useState('');
    const [imageError, setImageError] = useState('');
    const [showedit2, setShowEdit2] = useState(false);
    const [editVehicleBrand, setEditVehicleBrand] = useState(null);
    const [editVehicleModel, setEditVehicleModel] = useState(null);
    const [editVehicleColor, setEditVehicleColor] = useState('');
    const [editVehicleNumber, setEditVehicleNumber] = useState('');
    const [editNumberOfSeats, setEditNumberOfSeats] = useState('');
    const [vehicleImages1, setVehicleImages1] = useState([{ file: null, url: '' }]);
    const [vechicleimageid, setVechicleimageid] = useState([]);
    const [vechicleid, setVechicleid] = useState('');
    const [error422,setError422] = useState('');
    const [brandOptions, setBrandOptions] = useState([]);
    const [modelOptions, setModelOptions] = useState([]);
    const [vehicleImages, setVehicleImages] = useState([]);
    const [vehicleImagesBase64, setVehicleImagesBase64] = useState([]);
    const [modelLoading1, setModelLoading1] = useState(false);
    const [modelLoading, setModelLoading] = useState(false);
    const {uploadFile} = useUpload({showErrorToast});
    const vechicleget = async () => {
        try {
          setIsLoading1(true);
          const response = await axiosInstance.get(vechiclegetapiurl,);
          
          if (response.status === 200) {
            setIsLoading1(false);
            const vechicle = response.data.data.values;
            setVechicles(vechicle);
          }
        } catch (error) {
          handleApiError(error,setIsLoading1,null,true,showErrorToast);
        }
      }
      const fetchvehiclecompany = async () => {
        try {
          setModelLoading1(true);
          const response = await axiosInstance.get(vehiclecompanyapiurl);
          if (response.status === 200) {
            const vechiclecompanies = response.data.data;
            setBrandOptions(vechiclecompanies.map(company => ({ value: company.id, label: company.name })));
            setModelLoading1(false);
          }
        } catch (error) {
          setModelLoading1(false);
          handleApiError(error,setIsLoading1,null,true,showErrorToast);
        }
      };
      const fetchvechlemodel = useCallback(async () => {
        try {
          const companyId = vehicleBrand ? vehicleBrand?.value : editVehicleBrand?.value;
          if(companyId){
            setModelLoading(true);
          const response = await axiosInstance.get(`${vechiclemodelapiurl}?vehicle_company_id=${companyId}`);
          if (response.status === 200) {
            const vechiclemodels = response.data.data.values;
            setModelLoading(false);
             setModelOptions(vechiclemodels.map(model => ({ value: model.id, label: model.name })));
          }
        }
        } catch (error) {
          setModelLoading(false);
          handleApiError(error,setIsLoading1,null,true,showErrorToast);
        }
      }, [vehicleBrand, editVehicleBrand]);
    
      useEffect(() => {
        fetchvehiclecompany();
        vechicleget();
      }, []);
    
      useEffect(() => {
        if (vehicleBrand || editVehicleBrand) {
          setModelOptions([]);
          setVehicleModel(null); // Reset vehicle model state
          setVehicleModel(''); 
          setModelLoading(true);
          fetchvechlemodel();
        }
      }, [vehicleBrand, editVehicleBrand, fetchvechlemodel]);
    
      const handleVehicleBrandChange = (selectedOption) => {
        setVehicleBrand(selectedOption);
        setVehicleModel(null);
        vechicleerrorclear();
        setBrandError('');
        setVehicleModel('');
      };
    
      const handleVehicleModelChange = (selectedOption) => {
        setVehicleModel(selectedOption);
        vechicleerrorclear();
      };
      const [show2, setShow2] = useState(false);
      const addvehiclenew = () => {
        fetchvehiclecompany();
        setShow2(true);
        setShowEdit2(false);
        handleAddMore();
      }
      const handleClose2 = () => {
        setShow2(false);
        setVehicleBrand('');
        setVehicleModel('');
        setVehicleColor('');
        setVehicleNumber('');
        setNumberOfSeats('');
        setVehicleImages([]);
        setModelOptions([]);
        setBrandOptions([]);
        setVehicleImagesBase64([]);
        setBrandError('');
        setModelError('');
        setColorError('');
        setNumberError('');
        setSeatsError('');
        setImageError('');
      }
      const close2 = () => {
        handleClose2();
      }
      const vechiclecancel = () => {
        handleClose2();
      }
      const handleVechicleFileChange = (e, index) => {
        const files = Array.from(e.target.files);
        if (files.length > 0) {
          const file = files[0];
    
          setVehicleImages((prevImages) => {
            const newImages = [...prevImages];
            newImages[index] = {image:file,image_name:file.name
            }
            return newImages;
          });
    
          // convertToBase64(file, index);
        }
      };
     const convertToBase64 = (file, index) => {
      const reader = new FileReader();
    
     
        reader.onload = (event) => {
          const base64String = event.target.result;
          const modifiedFilename = file.name
            .replace(/\s+/g, '-') 
            .replace(/\.[^/.]+$/, ''); 
    
            setVehicleImagesBase64((prevImagesArray) => {
            const newImagesArray = [...prevImagesArray];
            newImagesArray[index] = {
              image: base64String,
              image_name: modifiedFilename,
            };
    
            // const formattedJsonString = JSON.stringify({ images: newImagesArray });
            // setImagesArray(formattedJsonString);
    
            return newImagesArray;
          });
        };
        reader.readAsDataURL(file);
    };
    const [pendingFileInputClick, setPendingFileInputClick] = useState(false);
    const [showVehicleInputBox, setShowVehicleInputBox] = useState(true);
      const handleFileClick1 = (index, event,image) => {
        const clickedElement = event.target;
    
        if(clickedElement.classList.contains('vehicleinputbox2')){
            handleimageuploadclick(index);
        } else if(clickedElement.classList.contains('delete-iconref')){
            handleDeleteuploadimage(index);
        } else if(clickedElement.classList.contains('addimage')){
            handleimageuploadclick(index);
        } else if(clickedElement.classList.contains('imagepreview')){
            handleuploadimagepreview(image);
        }
    };
  
      const handleAddMore = () => {
        if(vehicleImages.length < 1){
        setVehicleImages((prevImages) => [...prevImages, null]);
        setVehicleImagesBase64((prevImagesBase64) => [...prevImagesBase64, null]);
        setShowVehicleInputBox(true);
        }else{
          setPendingFileInputClick(true);
          setVehicleImages((prevImages) => [...prevImages, null]);
          setVehicleImagesBase64((prevImagesBase64) => [...prevImagesBase64, null]);
          setShowVehicleInputBox(false);
        }
      };
      useEffect(() => {
        if (pendingFileInputClick) {
          const index = vehicleImages.length - 1;
          const fileInput = document.getElementById(`vehicleFileInput-${index}`);
          if (fileInput) {
            fileInput.click();
            setPendingFileInputClick(false);
          }
        }
      }, [vehicleImages, pendingFileInputClick]);
      const checkFormvechicleValidity = () => {
        const areImagesEmpty = vehicleImages && vehicleImages.length > 0 && vehicleImages.some(image => image !== null) ;
        
        const isValid = (
          vehicleBrand !== '' &&
          vehicleModel !== '' &&
          vehicleColor.trim() !== '' &&
          vehicleNumber.trim() !== '' &&
          numberOfSeats !== '' &&
          areImagesEmpty &&
          // !areImagesEmpty1 &&
          // vehicleImages.length > 1 && 
          seatsError === '' &&
          colorError === '' 
        );
        setSaveButtonDisabled(isValid);
      };
      useEffect(() => {
        checkFormvechicleValidity();
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [vehicleBrand, vehicleModel, vehicleColor, vehicleNumber, numberOfSeats,vehicleImages,seatsError,colorError]);
      
      
      const deleteIconRef = useRef(null);
      const [selectedImage, setSelectedImage] = useState(null);
      const [isPreviewVisible, setPreviewVisible] = useState(false);
      const [selectedImage1, setSelectedImage1] = useState(null);
      const [isPreviewVisible1, setPreviewVisible1] = useState(false);    
      const handleimageuploadclick = (index) => {
        const fileInput = document.getElementById(`vehicleFileInput-${index}`);
        fileInput.click();
      }
      const handleDeleteuploadimage = (index) => {
        const totalImageCount = vehicleImages.filter(img => img !== null).length;
        if(totalImageCount === 1){
          setShowVehicleInputBox(true);
          setVehicleImages((prevImages) => {
            const updatedImages = [...prevImages];
            updatedImages[0] = null;
            return updatedImages;
        });
        setVehicleImagesBase64((prevImagesBase64) => {
            const updatedImagesBase64 = [...prevImagesBase64];
            updatedImagesBase64[0] = null;
            return updatedImagesBase64;
        });
        }else{
        setVehicleImages((prevImages) => prevImages.filter((_, i) => i !== index));
        setVehicleImagesBase64((prevImagesBase64) => prevImagesBase64.filter((_, i) => i !== index));
        }
      }
      const handleuploadimagepreview = (image) => {
        setSelectedImage(image)
               setPreviewVisible(true)
                setShow2(false);
      }
      const handleDeletediv = (index, event) => {
        event.preventDefault();
        event.stopPropagation();
        setVehicleImages((prevImages) => prevImages.filter((_, i) => i !== index));
        setVehicleImagesBase64((prevImagesBase64) => prevImagesBase64.filter((_, i) => i !== index));
      };
      const vechicleerrorclear = () => {
        setBrandError('');
        setModelError('');
        setColorError('');
        setNumberError('');
        setSeatsError('');
        setImageError('');
        setError422('');
      }
      const vehicleRef = useRef({
        brand: null,
        model: null,
        color: null,
        number: null,
        seats: null,
        images: null,
      });
      const focusOnInput = (inputName) => {
        const inputRef = vehicleRef.current[inputName];
        if (inputRef) {
          inputRef.focus();
        }
      };

      const handleKeyDown = (event) => {
        // Allow only numbers and special keys (e.g., backspace, delete, arrow keys)
        if (!/^\d*$/.test(event.key) && 
            event.key !== 'Backspace' && 
            event.key !== 'Delete' && 
            event.key !== 'ArrowLeft' && 
            event.key !== 'ArrowRight' &&
            event.key !== 'Tab') {
          event.preventDefault();
        }
      };
      const handleVehicleModelClick = () => {
        if (!vehicleBrand) {
          setBrandError('Please select a vehicle brand first.');
          focusOnInput('brand');
          // setModelLoading(true);
        } else {
          setModelError('');
          // setModelLoading(false);
        }
      };
      const handleVehicleModelChanges = (selectedOption) => {
        if (!vehicleBrand) {
          setBrandError('Please select a vehicle brand first.');
          focusOnInput('brand');
          fetchvechlemodel();
        } else {
          setVehicleModel(selectedOption);
          setModelError('');
        }
      }
      const handleVehicleColorChange = (e) => {
        const value = e.target.value;
        vechicleerrorclear();
        if (/^[A-Za-z]*$/.test(value)) {
          setVehicleColor(value);
          setColorError('');
        } else {
          setColorError('Please enter only alphabetical letters');
        }
      };
      const handleVehicleNumberChange = (e) => {
        vechicleerrorclear();
        const value = e.target.value.toUpperCase().replace(/[^A-Z0-9]/g, '');
        setVehicleNumber(value);
      };
      const handleNumberOfSeatsChange = (e) => {
        const value = e.target.value;
        vechicleerrorclear();
        if (/^\d*$/.test(value) && (value === '' || (parseInt(value, 10) >= 0 && parseInt(value, 10) <= 25))) {
          setNumberOfSeats(value);
          setSeatsError(''); // Clear error message if input is valid
        } else {
          setSeatsError('Max 25');
        }
      };
      const vechicle_delete = async(vechicle) => {
        try {
          // const token = localStorage.getItem('access_token');
          // const headers = {
          //   'Authorization': `Bearer ${token}`,
          //   'Content-Type': 'application/json',
          // };
          const id = vechicle.id;
          const result = await Swal.fire({
            title: 'Are you sure?',
            text: 'You won\'t be able to revert this!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          });
          if (result.isConfirmed) {
            setIsLoading4(true);
            const response = await axiosInstance.post(`${vechicledeleteapiurl}?user_vehicle_id=${id}`, {},);
            if (response.status === 200) {
              await vechicleget();
              // showSuccessToast(response.data.message,'422Error',);
              setIsLoading4(false);
            }
          }
        }
        catch (error) {
          handleApiError(error,setIsLoading1,null,true,showErrorToast);
        }
      }  
      const vechiclesave = async () => {
        try {
          setIsLoading4(true);
          const images = vehicleImages.filter(image =>image !== null);
          const uploadedImages = await Promise.all(
            images.map(async (image) => {
              const uploadedUrl = await uploadFile(image.image, 'general', setIsLoading4);
              return {
                image: uploadedUrl, // Assuming uploadFile returns the uploaded image URL
                image_name: image.name || `image_${Date.now()}.jpg` // Use the original filename or a generated one
              };
            })
          )
          // const uploadImages = async () => {
          //   const uploadPromises = images.map(image => uploadFile(image.image, 'general', setIsLoading4));
          //   const results = await Promise.all(uploadPromises);
          //   return {image:results,image_name:images.image_name};
          // };
          // const val = await uploadedImages()
          const data = {
            vehicle_model_id: vehicleModel.value,
            color: vehicleColor,
            code: vehicleNumber,
            seat_count: numberOfSeats,
            images:uploadedImages
            // images:vehicleImagesBase64Filtered,
          }
          const response = await axiosInstance.post(vechiclestoreapiurl, data,);
          if (response.status === 200) {
            setShow2(false);
            handleClose2();
            await vechicleget();
            setIsLoading4(false);
            // showSuccessToast(response.data.message,'422Error',);
          }
        } catch (error) {
          handleApiError(error,setIsLoading4,setError422,false,showErrorToast);
        }
      }
      const updatevechicle = (vechicle) => {
        fetchvehiclecompany();
        fetchvechlemodel();
        setShow2(false);
        setShowEdit2(true);
        if (vechicle && vechicle.user_vehicle_images) {
          const initialImages = vechicle.user_vehicle_images.map((img) => ({
            id: img.id,
            file: null,
            url: img.image,
            image_name:img.image_name
          }));
          setVehicleImages1(initialImages);
        }
        if (vechicle) {
          setVechicleid(vechicle.id);
          setEditVehicleBrand({ label: vechicle.vehicle_model.vehicle_company[0].name, value: vechicle.vehicle_model.vehicle_company[0].id });
          setEditVehicleModel({label: vechicle.vehicle_model.name, value: vechicle.vehicle_model.id});
          setEditVehicleColor(vechicle.color);
          setEditVehicleNumber(vechicle.code);
          setEditNumberOfSeats(vechicle.seat_count);
        }
      }
      const handleeditClose2 = () => {
        setModelOptions([]);
        setBrandOptions([]);
        setEditVehicleColor('');
        setVechicleimageid([]);
        setEditNumberOfSeats('');
        setEditNumberOfSeats('');
        setError422('');
        vechicleerrorclear();
        setShowEdit2(false);
      }
      const closeedit2 = () => {
        handleeditClose2();
      }
      const vechicleeditcancel = () => {
        handleeditClose2();
      }
    
    
      const updateVehicleImages = (index, file) => {
        setVehicleImages1((prevImages) => {
          const updatedImages = [...prevImages];
          if (updatedImages[index]) {
            updatedImages[index].file = file;
          } else {
            updatedImages[index] = { file };
          }
          return updatedImages;
        });
      };
      const deleteIconRef1 = useRef(null);
    
      const handleClick = (index, event , image) => {
        const clickedElement = event.target;
         if(clickedElement.classList.contains('imageclass')){
           handlepreview(image);
        }
        else if (clickedElement.classList.contains('delete-icon1')) {
          handleDeleteImage1(index,image);
        } else if (clickedElement.classList.contains('vehicleinputbox')) {
          handleImageClick(index,image);
        }else if (clickedElement.classList.contains('delete-icons1')) {
          handleupdateDeletediv(index, event);
        }else if (clickedElement.classList.contains('vechicleimageclick')){
          handleImageClick(index,image);
        }
      };
      const [pendingupdateFileInputClick, setPendingupdateFileInputClick] = useState(false);
      const [showupdateVehicleInputBox, setShowupdateVehicleInputBox] = useState(true);

      const handlepreview = (image) => {
        setSelectedImage1(image.url);
        setPreviewVisible1(true);
        setShowEdit2(false);
      }
      const handleupdateDeletediv = (index,) => {
        setVehicleImages1((prevImages) => prevImages.filter((_, i) => i !== index));
      }
      
      const handleDeleteImage1 = async(index,image) => {
        const totalImageCount = vehicleImages1.filter(img => img.url !== '').length;
        setVechicleimageid((prevIds) => [...prevIds, image.id]);
        if(totalImageCount === 1){
          setShowupdateVehicleInputBox(true);
          setVehicleImages1((prevImages) => {
            const updatedImages = [...prevImages];
            if (index >= 0 && index < updatedImages.length) {
              updatedImages[index] = { file: null, url: '',image_name:'' };
            }
        
            return updatedImages;
          });
        }else{
          setVehicleImages1((prevImages) => prevImages.filter((_, i) => i !== index));
        }
      };
      
      const handleImageClick = (index,image) => {
        const fileInput1 = document.getElementById(`vehicleFileInput1-${index}`);
          fileInput1.click();
      };
      
      const handleVehicleFileChange = (e, index) => {
        const files = e.target.files;
    
        if (files && files.length > 0) {
          const reader = new FileReader();
    
          // reader.onload = (event) => {
            // const base64String = event.target.result;
    
            const updatedImages = [...vehicleImages1];
            updatedImages[index] = {
              ...updatedImages[index],
              file: files[0],
              // url: base64String, // Use base64String as the URL directly
              image_name: extractImageString(files[0].name),
            };
            setVehicleImages1(updatedImages);
            updateVehicleImages(index, files[0]); // Call update function here
          // };
    
          reader.readAsDataURL(files[0]);
        }
      };
      const handleAddMoreImages = () => {
        if(vehicleImages1.length < 1){
        setVehicleImages1((prevImages) => [
          ...prevImages,
          { file: null, url: '' },
        ]);
        setShowupdateVehicleInputBox(true);
      }else{
        setShowupdateVehicleInputBox(false);
        setPendingupdateFileInputClick(true);
        setVehicleImages1((prevImages) => [
          ...prevImages,
          { file: null, url: '' },
        ]);
      }
      };
      useEffect(() => {
        if (pendingupdateFileInputClick) {
          const index = vehicleImages1.length - 1;
          const fileInput1 = document.getElementById(`vehicleFileInput1-${index}`);
          if (fileInput1) {
            fileInput1.click();
            setPendingupdateFileInputClick(false);
          }
        }
      }, [vehicleImages1, pendingupdateFileInputClick]);
      const vechicle_document = (vechicle) => {
        const id = vechicle.id;
        // localStorage.setItem('userId',id);
        navigate(`/u/${login_user}/documents/vechicle/${id}/document`);
      }
      const handleEditVehicleBrandChange = (selectedOption) => {
        setEditVehicleBrand(selectedOption);
        setEditVehicleModel(null);
        setBrandError('');
      };
    
      const handleEditVehicleModelChange = (selectedOption) => {
        setEditVehicleModel(selectedOption);
        setModelError('');
      };
    
      const handleEditVehicleColorChange = (e) => {
        const value = e.target.value;
        if (/^[A-Za-z]*$/.test(value)) {
          setEditVehicleColor(value);
          setColorError('');
        } else {
          setColorError('Please enter only alphabetical letters');
        }
      };
    
      const handleEditVehicleNumberChange = (e) => {
        vechicleerrorclear();
        const value = e.target.value.toUpperCase().replace(/[^A-Z0-9]/g, '');
        setEditVehicleNumber(value);
      };
    
      const handleEditNumberOfSeatsChange = (e) => {
        const value = e.target.value;
        vechicleerrorclear();
        if (/^\d*$/.test(value) && (value === '' || (parseInt(value, 10) >= 0 && parseInt(value, 10) <= 25))) {
          setEditNumberOfSeats(value);
          setSeatsError(''); // Clear error message if input is valid
        } else {
          setSeatsError('Max 25');
        }
      };
      const checkFormEditvechicleValidity = () => {
        // const areImagesEmpty = vehicleImages1.some(image => image.url === '');
        // const areImagesEmpty = vehicleImages1 && vehicleImages1.length > 0 && vehicleImages1[0] !== null;
        const areImagesEmpty = vehicleImages1 && vehicleImages1.length > 0 && vehicleImages1.some(image => image.url !== '') ;
        const isValid = (
          editVehicleBrand !== '' &&
          editVehicleModel !== '' &&
          editVehicleModel !== null &&
          editVehicleColor.trim() !== '' &&
          editVehicleNumber.trim() !== '' &&
          editNumberOfSeats !== '' &&
          areImagesEmpty &&
          // vehicleImages1.length > 0 && 
          seatsError === '' &&
          colorError === '' 
        );
        setSaveupdatebuttondisabled(isValid);
      };
      useEffect(() => {
        checkFormEditvechicleValidity();
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [editVehicleBrand, editVehicleModel, editVehicleColor, editVehicleNumber, editNumberOfSeats,vehicleImages1,seatsError,colorError]);
      const vechicleupdatesave = async () => {
        try {
          // ... (your existing code)
          setIsLoading4(true);
          // Step 1: Delete images
          for (const deletedImageId of vechicleimageid) {
            await deleteImage(deletedImageId);
          }
    
          // Step 2: Update vehicle details
          const response = await updateVehicleDetails();
          if (response.status === 200) {
            setShowEdit2(false);
           await vechicleget();
           setVechicleimageid([]);
            setIsLoading4(false);
            // showSuccessToast(response.data.message,'422Error',);
          }
        } catch (error) {
          handleApiError(error,setIsLoading4,setError422,false,showErrorToast);
        }
      };
      const deleteImage = async (imageId) => {
        try {
          
          // Check if imageId is provided before making the API call
          if (imageId) {
    
            const data = {
              user_vehicle_id: vechicleid,
              user_vehicle_image_id: imageId,
            };
    
            const response = await axiosInstance.post(vechicledeleteimageapiurl, data,);
    
            if (response.status !== 200) {
              console.error(`Error deleting image with id ${imageId}`);
            }
          }
        } catch (error) {
          // Handle errors
          handleApiError(error,setIsLoading4,null,true,showErrorToast);
        }
      };
    
      const updateVehicleDetails = async () => {
        try {
          // ... (your existing code for updating vehicle details)
          
          
          const uploadedImages = await Promise.all(
            vehicleImages1.filter((image) => image.file)
            .map(async (image) => {
              const uploadedUrl = await uploadFile(image.file, 'general', setIsLoading4);
              return {
                image: uploadedUrl, // Assuming uploadFile returns the uploaded image URL
                image_name: image.image_name || `image_${Date.now()}.jpg` // Use the original filename or a generated one
              };
            })
          )
          const data = {
            user_vehicle_id: vechicleid,
            vehicle_model_id: editVehicleModel.value,
            color: editVehicleColor,
            code: editVehicleNumber,
            seat_count: editNumberOfSeats,
            images: uploadedImages
          }
          const response = await axiosInstance.post(vechicleupdateapiurl, data,);
    
          return response;
        } catch (error) {
          throw error;
        }
      }
      const customStyles1 = (hasValue) => ({
        control: (provided,state) => ({
          ...provided,
          borderColor: hasValue ? 'black' : '#ABABAB',
          height:'40px',
          fontSize:'14px',
          boxShadow: state.isFocused ? 'none' : 'none',
          '&:hover': {
            borderColor: hasValue ? 'black' : '#ABABAB',
          },
        }),placeholder: (provided) => ({
          ...provided,
          color: '#ABABAB',
          width: '100%',
          fontSize:'13px',
          fontWeight:'500',
        }),
        dropdownIndicator: (provided) => ({
          ...provided,
          color: hasValue ? 'black' : '#ABABAB',
          svg: {
            fill: hasValue ? 'black' : '#B9B9B9',
          },
        }),
      });
    return(
        <>
        {isLoading4 && (
                <>   
                    <div className="loaderover">
                    <div className="loader ">
                    </div>
                    </div>
                </>
            )}
         {vechicles.length > 0 ? (
                    vechicles.map((vechicle, index) => (
                      <div key={index} className="docbox">
                        <div className="d-flex align-items-center" style={{ width: "100%",gap:'10px' }}>
                          <div className="d-flex align-items-center" style={{ width: '70%' }}>
                            <div className="d-flex align-items-center" style={{width:'100%',gap:'20px'}}>
                            <div className="me-3">
                            {vechicle.user_vehicle_images && vechicle.user_vehicle_images.length > 0 && (
                              <div style={{ position: 'relative',width:'90px' }}>
                                <img src={`${publicUrl}/${vechicle.user_vehicle_images[0]?.image}`} alt="car" className="docvehicleimg" />
                                <div className="vechicleimagelength">{vechicle.user_vehicle_images.length}</div>
                              </div>
                            )}
                            </div>
                            <div style={{width:'100%'}}>
                            <h5 className="docvehicleh5">{vechicle.vehicle_model.vehicle_company[0].name}</h5>
                            <hr className="docvehiclehr"></hr>
                            <div className="d-flex align-items-center" style={{marginTop:'10px'}}>
                              <div className="widthvechicle25">
                                <p className="docmodalp">Model</p>
                                <p className="docmodalp1">{vechicle.vehicle_model.name}</p>
                              </div>
                              <div className="widthvechicle25">
                                <p className="docmodalp">Color</p>
                                <p className="docmodalp1">{vechicle.color}</p>
                              </div>
                              <div className="widthvechicle25 widthvechicle35">
                                <p className="docmodalp">Vehicle Number</p>
                                <p className="docmodalp1">{vechicle.code}</p>
                              </div>
                              <div className="widthvechicle25 widthvechicle15">
                                <p className="docmodalp">Seats</p>
                                <p className="docmodalp1">{vechicle.seat_count}</p>
                              </div>
                            </div>
                          </div>
                          </div>
                          </div>
                          <div className="d-flex align-items-center justify-content-end acdamic35" style={{ width: '35%' }}>
                              <div className="docvechilebuttons me-3">
                                <button style={{ position: 'relative' }} onClick={() => updatevechicle(vechicle)} className=" docuploadvehicle">
                                  <div className="butimag">
                                    <div className="__col docvechilespan">
                                      <img src={edit} alt="deposits" className="me-2" width='18px' height='auto' />
                                    </div>
                                    <span className="__col docvechilespan">Update Vehicle</span>
                                  </div>
                                </button>
                                <button style={{ position: 'relative' }} onClick={() => vechicle_document(vechicle)} className=" docuploadviewdocument">
                                  <div className="butimag">
                                    <div className="__col docvechilespan">
                                      <img src={adddocument} alt="deposits" className="me-2" width='18px' height='auto' />
                                    </div>
                                    <span className="__col docvechilespan">Document</span>
                                  </div>
                                </button>
                              </div>
                              <div>
                                <img src={acdamicdeleteimg} alt="delete" onClick={() => vechicle_delete(vechicle)} className="docvehicledeletered"></img>
                              </div>
                            </div>
                          </div>
                        </div>
                    ))
                  ) : (
                    <div className="d-flex mt-5 justify-content-center align-items-center flex-column generalnew ">
                       {isLoading1 ? (
                        <>
                        <SkeletonLoader documentRef={documentRef}/>
                        </>
                      ) : (
                      <>
                      <img src={vechicleimg} alt="document" className="docorangeimage"></img>
                      <h6 className="addnewgeneralh6">Add Vehicle Details</h6>
                      <p className="generalp">For added convenience, add information about your vehicles, including documentation like licence, 3rd party insurance, etc</p>
                      <button className="addnewgeneralbutton" onClick={addvehiclenew}>Add Vehicle Details</button>
                      </>
                      )}
                    </div>
                  )}
                  {vechicles.length > 0 && (
                    <div className="text-end mt-3" style={{ width: '97%' }}>
                    <button className="docaddnew" onClick={addvehiclenew}>
          <img
            src={plus1}
            className="me-2"
            alt="plus"
            width="15px"
            height="auto"
          ></img>
          New
        </button>
                  </div>
                  )}

      <Modal show={show2} dialogClassName="document-dialog1" contentClassName="document-content1" onHide={handleClose2} size="lg" centered>
        <Modal.Body style={{ margin: '0', padding: '0' }}>
          <div className="d-flex align-items-center justify-content-between docpop">
            <h6 className="docpoph6">Add Vehicle details</h6>
            <img src={closecircle} onClick={close2} alt="close" className="docpopclose"></img>
          </div>
          <div className="error-message1">{error422}</div>
          <div className="mt-1 mb-1 row">
            <div className="col-12 col-sm-6">
              <label className="docpoplabel">Vehicle brand<span className="docpopstar">*</span></label>
              <Select options={brandOptions} isLoading={modelLoading1} value={vehicleBrand} ref={(el) => vehicleRef.current.brand = el} isSearchable={false} placeholder="Select Vehicle brand" onChange={handleVehicleBrandChange} styles={customStyles1(Boolean(vehicleBrand))}  />
              <div className="error-message">{brandError}</div>
            </div>
            <div className="col-12 col-sm-6">
              <label className="docpoplabel">Vehicle Model<span className="docpopstar">*</span></label>
              <Select options={modelOptions}  onFocus={handleVehicleModelClick}  isLoading={modelLoading} value={vehicleModel} ref={(el) => vehicleRef.current.model = el} isSearchable={false} placeholder= "Select Vehicle Model"  onChange={handleVehicleModelChange}  styles={customStyles1(Boolean(vehicleModel))} />
              <div className="error-message">{modelError}</div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-xl-4  ">
              <label className="docpoplabel">Vehicle color<span className="docpopstar">*</span></label>
              <input type="text" className={`form-control docform ${vehicleColor ? 'docform-active' : ''}`} value={vehicleColor} placeholder="Enter color" ref={(el) => vehicleRef.current.color = el} onChange={handleVehicleColorChange}></input>
              <div className="error-message">{colorError}</div>
            </div>
            <div className="col-12 col-sm-6 col-xl-4">
              <label className="docpoplabel">Vehicle number<span className="docpopstar">*</span></label>
              <input type="text" className={`form-control docform ${vehicleNumber ? 'docform-active' : ''}`}  value={vehicleNumber} placeholder="Enter number" ref={(el) => vehicleRef.current.number = el} onChange={handleVehicleNumberChange}/>
              <div className="error-message">{numberError}</div>
            </div>
            <div className="col-12 col-sm-6 col-xl-4">
              <label className="docpoplabel">No of seats<span className="docpopstar">*</span></label>
              <input type="text" className={`form-control docform ${numberOfSeats ? 'docform-active' : ''}`} placeholder="0" value={numberOfSeats} ref={(el) => vehicleRef.current.seats = el} onChange={handleNumberOfSeatsChange} onKeyDown={handleKeyDown}></input>
              <div className="error-message">{seatsError}</div>
            </div>
          </div>
          <label className="docpoplabel ">Upload Vehicle Pictures<span className="docpopstar">*</span></label>
          <div className="parentimage">
            <div className="_row" style={{ alignItems: 'center' }}>
              {vehicleImages.map((image, index) => (
                <div key={index} style={{ display: index === 0 || (index !== 0 && image) ? '' : 'none' }} className="col col_16_66">
                  <div style={{ position: 'relative' }}
                    id={`vehicleInputBox-${index}`}
                    className={image ? '' : showVehicleInputBox ? 'vehicleinputbox2 vehicleinputbox' : ''}
                    onClick={(event) => handleFileClick1(index, event,image)}
                  >
                    {image ? (
                      <>
                        <div style={{ position: 'relative',border:'1px solid #ABABAB',borderRadius:'10px' }}>
                          <img className="imagepreview" src={URL.createObjectURL(image.image)} alt={`vehicle-${index}`}   style={{ objectFit: 'cover', borderRadius: '10px',width:'100%',height:'100px' }} />
                          <p className="delete-iconref" ref={deleteIconRef} style={{ position: 'absolute', right: '-1px', top: '10%', transform: 'translateY(-50%)', cursor: 'pointer',marginBottom:'0px' }}><img src={vechicledelete} className="delete-iconref" alt="delete" width='25px' height='auto'></img></p>
                        </div>
                      </>
                    ) : (
                      <>
                      {showVehicleInputBox && (
                        <div className="upload-placeholder">
                          <img src={plus} className="addimage" alt="plus" width='20px' height='auto' />
                          {/* <p className="delete-icons" onClick={(event) => handleDeletediv(index, event)} style={{ position: 'absolute', right: '-1px', top: '10%', transform: 'translateY(-50%)', cursor: 'pointer' }}><img src={vechicledelete} alt="delete" width='25px' height='auto'></img></p> */}
                        </div>
                        )}
                      </>
                    )}
                  </div>
                  {vehicleImages[index] && (
                    <p className="vechiletextp">{(vehicleImages[index].image_name)}</p>
                  )} 
                  <input
                    id={`vehicleFileInput-${index}`}
                    type="file"
                    style={{ display: 'none' }}
                    accept=".jpg, .jpeg, .png"
                    onChange={(e) => handleVechicleFileChange(e, index)}
                  />
                </div>
              ))}  
              {vehicleImages && 
              vehicleImages[0] !== null &&  
              <div className="col col_16_66">
                <p className={'addmorevehicle addmorevehiclemb-0'} onClick={handleAddMore}>+ Add More</p>
              </div>
              } 
              <div className="error-message">{imageError}</div>
            </div>
          </div>
          <div className="d-flex justify-content-end align-items-center mt-4 mb-2">
            <button className="docpopcancel me-3" onClick={vechiclecancel}>Cancel</button>
            <button className="docpopsave" style={{ backgroundColor: savebuttondisabled ? '#5D64F2' : '#EAEAEA', color: savebuttondisabled ? '#FFF' : '#333' , cursor: savebuttondisabled ? 'pointer' : 'not-allowed' }} disabled={!savebuttondisabled}  onClick={vechiclesave}>Save</button>
          </div>
        </Modal.Body>
      </Modal>

      {/* vechicle edit popup */}
   
     
      <Modal show={showedit2} dialogClassName="document-dialog1" size="lg" contentClassName="document-content1" onHide={handleeditClose2} centered>
        <Modal.Body style={{ margin: '0', padding: '0' }}>
          <div className="d-flex align-items-center justify-content-between docpop">
            <h6 className="docpoph6">Add Vehicle details</h6>
            <img src={closecircle} onClick={closeedit2} alt="close" className="docpopclose"></img>
          </div>
          <div className="error-message1">{error422}</div>
          <div className="mt-1 mb-1 row">
            <div className="col-12 col-sm-6">
              <label className="docpoplabel">Vehicle brand<span className="docpopstar">*</span></label>
              <Select options={brandOptions} isLoading={modelLoading1} value={editVehicleBrand}  isSearchable={false} placeholder="Select Vehicle brand" onChange={handleEditVehicleBrandChange} styles={customStyles1(Boolean(editVehicleBrand))} />
              <div className="error-message">{brandError}</div>
            </div>
            <div className="col-12 col-sm-6">
              <label className="docpoplabel">Vehicle Model<span className="docpopstar">*</span></label>
              <Select options={modelOptions}  isLoading={modelLoading} onClick={handleVehicleModelChanges} value={editVehicleModel} defaultValue={editVehicleModel}  isSearchable={false} placeholder="Select Vehicle brand" onChange={handleEditVehicleModelChange} styles={customStyles1(Boolean(editVehicleModel))} />
              <div className="error-message">{modelError}</div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-xl-4  ">
              <label className="docpoplabel">Vehicle color<span className="docpopstar">*</span></label>
              <input type="text" className={`form-control docform ${editVehicleColor ? 'docform-active' : ''}`} value={editVehicleColor} placeholder="Enter color"  onChange={handleEditVehicleColorChange}></input>
              <div className="error-message">{colorError}</div>
            </div>
            <div className="col-12 col-sm-6 col-xl-4">
              <label className="docpoplabel">Vehicle number<span className="docpopstar">*</span></label>
              <input type="text" className={`form-control docform ${editVehicleNumber ? 'docform-active' : ''}`} value={editVehicleNumber} placeholder="Enter number"  onChange={handleEditVehicleNumberChange}></input>
              <div className="error-message">{numberError}</div>
            </div>
            <div className="col-12 col-sm-6 col-xl-4">
              <label className="docpoplabel">No of seats<span className="docpopstar">*</span></label>
              <input type="text" className={`form-control docform ${editNumberOfSeats ? 'docform-active' : ''}`} placeholder="0" value={editNumberOfSeats} onKeyDown={handleKeyDown} onChange={handleEditNumberOfSeatsChange}></input>
              <div className="error-message">{seatsError}</div>
            </div>
          </div>
          <label className="docpoplabel">Upload Vehicle Pictures<span className="docpopstar">*</span></label>
          <div className="parentimage">
            <div className="_row" style={{ alignItems: 'center' }}>
              {vehicleImages1.map((image, index) => (
                <div key={index} style={{ display: index === 0 || (index !== 0 && (image.url !== '' || image.file !== null)) ? '' : 'none' }} className="col col_16_66">
                  <div
                    style={{ position: 'relative' }}
                    id={`vehicleInputBox1-${index}`}
                    className={image.url ? 'imageclass' : showupdateVehicleInputBox ? 'vehicleinputbox' : ''}
                    onClick={(event) => handleClick(index, event , image)}
                  >
                    {(image.url || (image.file && image.file instanceof File)? (
                      <>
                        <div style={{ position: 'relative' }}>
                          <img className="imageclass imageclassborder" src={image.file instanceof File
            ? URL.createObjectURL(image.file) // Show preview for newly uploaded file
            : removecore(image.url)} alt={`vehicle1-${index}`} width="100%" height="100px" style={{ objectFit: 'cover', borderRadius: '10px' }} />
                          <p className="delete-icon1" ref={deleteIconRef1} style={{ position: 'absolute', right: '-1px', top: '10%', transform: 'translateY(-50%)', cursor: 'pointer' }}><img src={vechicledelete} className="delete-icon1" alt="delete" width="25px" height="auto" /></p>
                        </div>
                      </>
                    ) : (
                      <>
                       {showupdateVehicleInputBox && (
                        <div className="upload-placeholder">
                          <img className="vechicleimageclick" src={plus} alt="plus" width="20px" height="auto" />
                          {/* <p className="delete-icons1"  style={{ position: 'absolute', right: '-1px', top: '10%', transform: 'translateY(-50%)', cursor: 'pointer' }}><img className="delete-icons1" src={vechicledelete} alt="delete" width="25px" height="auto" /></p> */}
                        </div>
                        )}
                      </>
                    ))}
                  </div>
                  {vehicleImages1[index] && ( 
                  <p className="vechiletextp">{image.image_name}</p> 
                    )}
                  <input
                    id={`vehicleFileInput1-${index}`}
                    type="file"
                    style={{ display: 'none' }}
                    accept=".jpg, .jpeg, .png"
                    onChange={(e) => handleVehicleFileChange(e, index)}
                  />
                </div>
              ))}
              {vehicleImages1 && vehicleImages1[0] && vehicleImages1[0].url !== '' && (
              <div className="col col_16_66">
                <p className={`${vehicleImages1.length < 5 ? ' addmorevehicle addmorevehiclemb-0' : 'addmorevehicle'}`} onClick={handleAddMoreImages}>
                  + Add More
                </p>
              </div>
              )}
              <div className="error-message">{imageError}</div>
            </div>
          </div>
          <div className="d-flex justify-content-end align-items-center mt-4 mb-2">
            <button className="docpopcancel me-3" onClick={vechicleeditcancel}>Cancel</button>
            <button className="docpopsave" style={{ backgroundColor: saveupdatebuttondisabled ? '#5D64F2' : '#EAEAEA', color: saveupdatebuttondisabled ? '#FFF' : '#333' , cursor: saveupdatebuttondisabled ? 'pointer' : 'not-allowed'}} disabled={!saveupdatebuttondisabled} onClick={vechicleupdatesave}>Save</button>
          </div>
        </Modal.Body>
      </Modal>
      {selectedImage && (
      <Image
           src={URL.createObjectURL(selectedImage)}
           alt="Enlarged Image"
           preview={{
            visible: isPreviewVisible,
            onVisibleChange: setPreviewVisible,
            onClose: () => {
              setPreviewVisible(false);
              setShow2(true); 
            },
          }}
           style={{ maxWidth: '100%', maxHeight: '80vh' ,zIndex:'9999' , display:'none'}}/>
      )}
      {selectedImage1 && (
      <Image
           src={selectedImage1.startsWith('data:') ? `${selectedImage1}` : removecore(selectedImage1)}
           alt="Enlarged Image"
           preview={{
            visible: isPreviewVisible1,
            onVisibleChange:setPreviewVisible1,
            onClose: () => {
              setPreviewVisible1(false);
              setShowEdit2(true); 
            },
          }}
           style={{ maxWidth: '100%', maxHeight: '80vh' ,zIndex:'9999' , display:'none'}}/>
      )}
        </>
    );
}
export default WithToast(Vechicle);