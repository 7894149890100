import { fetchEmploymentTypeListPending, fetchEmploymentTypeListFulfilled, fetchEmploymentTypeListRejected } from '../slices/employementTypeSlice';
import { workEmployementType } from '../../../api/helper/apiurlhelper';
import { HandleCalls } from '../../utils/utils'; 

export const fetchEmploymentTypes = (showErrorToast) => async (dispatch, getState) => {
    const { employmentType } = getState();
    if (employmentType.employmentTypeOptions.length > 0) return;
    try {
        dispatch(fetchEmploymentTypeListPending());
        const response = await HandleCalls({ apiCall: () => workEmployementType(), showErrorToast:showErrorToast });
        if (response.status === 200) {
            const mappedEmploymentTypes = response.data.data.map((employmentType) => ({
                value: employmentType.id,
                label: employmentType.name,
            }));
            dispatch(fetchEmploymentTypeListFulfilled(mappedEmploymentTypes));
        }
    } catch (error) {
        dispatch(fetchEmploymentTypeListRejected(error.response?.data || error.message));
    }
};
