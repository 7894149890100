import { React, useState, useEffect } from "react";
import '../home.css';
import { useNavigate } from "react-router-dom";
import trash from '../images/trash-01.png';
import edit from '../images/pen-01.png';
import { useSelector, useDispatch } from 'react-redux';
import { fetchUserProfile } from '../api/redux/userProfiles/actions';
import { extractLoginUser ,HandleCalls, handleImageUploads} from "../api/utils/utils";
import WithToast from "../api/hooks/withtoasthook";
import Loader from "../component/loader";
import Commonheader from "../component/commonheader";
import Cardheader from "../component/carddesign";
import ProfileImage from "../component/profileimage";
import Button from "../component/savebutton";
import uploadimage from '../images/upload_1-01.svg';
import ImageViewer from "../component/imageviewer";
import { profileRemove, profileUpdate } from "../api/helper/apiurlhelper";
import { useUpload } from "../api/hooks/uploadhook";
function Profilechange({showErrorToast}) {
  const dispatch = useDispatch();
  const userProfile = useSelector((state) => state.userProfile.userProfile);
  const login_user = extractLoginUser();
  const {uploadFile} = useUpload();
  const [formvalues,setFormValues] = useState({isPreviewVisible:false,changedimage:true,default_profile:'',removeactivebuttonstatus:true,imageremoved:false,radioValue:'',image:'',Selectimage:[]});
  useEffect(() => {
    dispatch(fetchUserProfile());
  }, [dispatch]);
  const [error422, setError422] = useState(''); 
  const [isLoading4, setIsLoading4] = useState(false);
  const navigate = useNavigate();
  const triggerFileInput = () => {
    document.getElementById('fileInput').click();
    setError422('');
  };

  const visibles = [{label:'Anyone',value:'public'},{label:'People you interact with',value:'friends'}];
  const handleImageChange = (e) => {
    const file = handleImageUploads(e);
    if (typeof file === 'string') {
      setError422(file);
    } else {
      setError422('');
      setFormValues((prev) => ({...prev,image:file,changedimage: true,removeactivebuttonstatus:false,imageremoved:false}));
    }
  };
  const handleDrop = (e) => {
    handleImageChange(e);
  };
  const handleImageremove = async () => {
    setError422('');
    setFormValues((prev)=>({...prev,image:formvalues.default_profile,imageremoved:true,removeactivebuttonstatus:false,changedimage:false}));
  }
  const imageview = (image) => {
    setFormValues((prev)=>({
          ...prev,Selectimage:[image],
          isPreviewVisible:true
       }));
  }

  useEffect(() => {
    if (userProfile) {
      setFormValues((prev)=>({...prev,image:userProfile.image,radioValue:userProfile.profile_image_visibility,default_profile:userProfile.default_profile}));
    }
  }, [userProfile]);

  const handleRadioChange = (event) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      radioValue: event.target.value, 
    }));
  }
  const after_successcall = async() => {
          //  await makeAjaxCall();
          navigate(`/u/${login_user}/profile`);
  }
  const handleremovesave = async () => {
    if (formvalues.imageremoved) {
        setIsLoading4(true);
        await HandleCalls({apiCall:profileRemove,successCall:after_successcall,showErrorToast:showErrorToast,setIsLoading:setIsLoading4,setError422:setError422,handle422AsToast:false});
        setIsLoading4(false);
    }
  }
  const save = async () => {
    let newImagePath = formvalues.image;
    if (typeof newImagePath === "object" && newImagePath instanceof File) {
      const uploadedImage = await uploadFile(formvalues.image,'profile',setIsLoading4);
      newImagePath = uploadedImage; 
    }
    setIsLoading4(true);
    var data = {
      profile_image_visibility: formvalues.radioValue,
      image:newImagePath
    }
    if (newImagePath|| formvalues.radioValue) {
        await HandleCalls({apiCall:()=>profileUpdate(data),successCall:after_successcall,setIsLoading:setIsLoading4,showErrorToast:showErrorToast,setError422:setError422,handle422AsToast:false});
        setIsLoading4(false);
    }
  } 
  
  return (
    <>
     {isLoading4 && (<Loader/>)} 
     <Commonheader/>
     <Cardheader text='Profile picture' next={(!formvalues.imageremoved && formvalues.changedimage)  ? save : handleremovesave} cancel={()=>navigate(`/u/${login_user}/profile`)} back={()=>navigate(`/u/${login_user}/profile`)} div_classname='name-input d-flex flex-column justify-content-center align-items-center'>
        <div className="position-relative">  
        <ProfileImage containerClass='user_connect_profile' imageview={()=>imageview(formvalues.image)}  showeye={true} imgClass='modal_user_profile' imageSrc={formvalues.image} /> 
        </div>
      {(formvalues.removeactivebuttonstatus && !userProfile.is_default) || formvalues.imageremoved ? (
        <>
          <Button defaultclassname="profile_upload_button rounded mt-3" onClick={triggerFileInput} icon={true} imageicon={uploadimage} label="Upload" active={true} />
          <input type="file" accept="image/pdf, image/jpg, image/jpeg" onDragOver={(e) => e.preventDefault()} onDrop={handleDrop} id="fileInput" className="d-none" onChange={handleImageChange} />
        </>
       ) : ( 
        <div className="d-flex justify-content-center align-items-center w-100 gap-3">
          <Button defaultclassname="profile_upload_button rounded mt-3" onClick={triggerFileInput} icon={true} imageicon={edit} label="Upload" active={true} />
          <input type="file" accept="image/pdf, image/jpg, image/jpeg" id="fileInput" className="d-none" onChange={handleImageChange} />
          <Button defaultclassname="profile_upload_button rounded mt-3" onClick={handleImageremove} icon={true} imageicon={trash} label="Remove" active={true}/>
        </div>
       )} 
       <div className="d-flex justify-content-start flex-column w-100 mt-4">
       <h6 className="mb-0">Visible to anyone</h6>
       {visibles.map((item, index) => (
      <div className="d-flex align-items-center mt-3 gap-2" key={index}>
      <input className="formchecks" type="radio" name="visible" id={item.value} value={item.value} checked={formvalues.radioValue === item.value} onChange={handleRadioChange}/>
      <label htmlFor={item.value}>{item.label}</label>
      </div>
       ))}
       </div>
       <div className="error-message">{error422}</div>
     </Cardheader>
     {formvalues.Selectimage && (<ImageViewer isPreviewVisible={formvalues.isPreviewVisible} selectedImage1={formvalues.Selectimage}onClose={() => setFormValues((prev)=>({...prev,isPreviewVisible:false}))} isMultiImage={false}/>)}
    </>
  );
}
export default WithToast(Profilechange);