import React, { useEffect, useRef, useState } from 'react'
import Fileuploader from '../../../component/fileuploader';
import dayjs from 'dayjs';
import { disableFutureDates, disablePastDates, formatDateForExpiry, formatDateForInput, formatFileSize, generateUniqueKey, HandleCalls, parseFileExtension } from '../../../api/utils/utils';
import CustomDatePicker from '../../../component/customdatepicker';
import Documentheaderfooter from '../../../component/documentheaderfooter';
import { useUpload } from '../../../api/hooks/uploadhook';
import { acdamicDocumentStore, vechicleDocumentStore} from '../../../api/helper/apiurlhelper';
import { useParams } from 'react-router-dom';
import { publicDomain } from '../../../api/helper/urlhelper';
function Vechicledocumentedit({formvalues,values,show,setIsLoading4,fetchdata,imageviewer,setFormvalues,showErrorToast}) {
     const fileInputRef = useRef(null);  
            const [formvalue,setFormValue] = useState({showinput:false,generaldocumenttype:null,Selectimage:[],isPreviewVisible:false,uniquekey:0,selectdoc:'',ispdfvisible:false,select:'',dateofissue:null,expirydate:null,isopen:false,inputvalue:'',saveenabled:false,id:'',docid:''});  
            const [error,setError] = useState({frontimageerror:'',expiryerror:'',});
            const {userId} = useParams();
            const [error422,setError422] = useState('');
            const [fileSize, setFileSize] = useState(0);
            const {uploadFile} = useUpload();
            const [format, setFormat] = useState('');
            const [docname,setDocname] = useState('');
            const [uploading, setUploading] = useState(false);
            const [progress, setProgress] = useState(0);
            const [progressColor, setProgressColor] = useState("#28a745");
            const [image, setImage] = useState('');  
            useEffect(()=>{
              if(values){
                setDocname(values.document_name || '');
                       setFormValue((prev)=>({...prev,document_id:values?.user_vehicle_id,docid:values.id}))
                       const selectedDocumentType = values?.vehicle_document_country?.name;
                          const datePart = values.issued_at?.split(' ')[0];
                          const datepart2 = dayjs(datePart, 'YYYY-MM-DD')
                          setFormValue((prev) => ({...prev,inputvalue: selectedDocumentType, dateofissue:datepart2,id:values.id}));
                          if (values.expired_at) {
                            const formattedExpiredDate = values.expired_at.split(' ')[0];
                            const formatDateForExpirydate = dayjs(formattedExpiredDate,'YYYY-MM-DD');
                            setFormValue((prev) => ({
                                ...prev,
                                expirydate:formatDateForExpirydate
                            }));
                            }else{
                            setFormValue((prev) => ({
                                ...prev,
                                expirydate:null
                            }));
                            }
                       if (values.user_vehicle_document_map?.length > 0) {
                        const lastDocument = values.user_vehicle_document_map[values.user_vehicle_document_map.length - 1]
                       const lastDocumentPath = lastDocument.path;
                       const imageUrl = `${lastDocumentPath}`;
                       setImage(imageUrl);
                       setFileSize(formatFileSize(lastDocument.fileSize));
                       setProgress(100);
                       setFormat(parseFileExtension(lastDocumentPath))
                       setUploading(true);
                      }
              } 
            },[values]);
            const handleFrontview = (image) => {
              console.log(image,format);
              if(format === 'pdf'){
                const newkey = generateUniqueKey('stalin');
                 if(typeof image === 'object' && image instanceof File){
                setFormvalues((prev)=>({...prev,
                uniquekey:newkey,selectdoc:image
                }));
                }else{
                setFormvalues((prev)=>({...prev,
                uniquekey:newkey,selectdoc:`${publicDomain()}/${image}`
                }));
                }
                imageviewer('edit','pdf');
              }else{
                  setFormvalues((prev) => ({
                      ...prev, selectimage: [image],
                  }));
                 imageviewer('edit','image');
              }
              if (setFormvalues) {
                setFormvalues((prev) => ({ ...prev, editshow: false }));
              }
            }; 
    
            const handleOpenChange = (open) => {
              setFormValue((prev)=>({...prev,isopen:open}));
            }; 
            const handleExpiryClick = (e) => {
              if (!formvalue.dateofissue) {
                setError((prevState) => ({
                  ...prevState,
                  expiryerror:'Please select Date of Issue first'
                }));
                setFormValue((prev)=>({...prev,isopen:false}));
              } else {
                setError((prevState) => ({
                  ...prevState,
                  expiryerror:''
                }));
                setFormValue((prev)=>({...prev,isopen:true}));
              }
            };
            // useEffect(() => {
            //   if (formvalue.generaldocumenttype === 'others') {
            //     setFormValue((prev)=>({...prev,showinput:true,inputvalue:''}));
            //   } else {
            //     setFormValue((prev)=>({...prev,showinput:false}));
            //   }
            // }, [formvalue.generaldocumenttype]);
            const checkFormgeneralValidity = () => {
              // let is_general_check;
              // if(formvalue.generaldocumenttype === 'others')
              // {
              //     is_general_check = formvalue.inputvalue.trim();
              // }
              // else
              // {
              //     is_general_check = formvalue.generaldocumenttype;
              // }
              const isValid = (
                   formvalues.inputvalue !== '' &&
                  docname.trim() !== '' &&
                  formvalue.dateofissue !== '' &&
                  formvalue.dateofissue !== null &&
                  image !== '' 
              );
              setFormValue((prev)=>({...prev,saveenabled:isValid}));
              };
              const handleClose = () => {
                setError422('');
                setError((prev)=>({...prev,expiryerror:'',frontimageerror:''}));
                setFormvalues((prev)=>({...prev,editvalues:[],editshow:false}))
              }
              useEffect(() => {
                checkFormgeneralValidity();
              }, [formvalue.inputvalue,docname,formvalue.dateofissue,image]);
              const documentsave = async() => {
                setIsLoading4(true);
                
                let newImagePath = image;
                if (typeof newImagePath === "object" && newImagePath instanceof File) {
                  const uploadedImage = await uploadFile(image,'general',setIsLoading4);
                  newImagePath = uploadedImage; 
                }
                let vechicleimages = {
                    data: newImagePath,
                    document_name: docname
                };
                const data = {
                  user_vehicle_document_country_id: formvalue.docid,
                  user_vehicle_id:formvalue.document_id,
                  vehicle_document_type: formvalue.inputvalue,
                  document_name: docname,
                  issued_at: formatDateForInput(formvalue.dateofissue),
                  images: [vechicleimages],
                }
                if (formvalue.expirydate) {
                  data.expired_at = formatDateForExpiry(formvalue.expirydate);
                }
                const after_successcall = async()=> {
                   handleClose();
                  await fetchdata();
                }
                await HandleCalls({apiCall:()=>vechicleDocumentStore(data,false),successCall:after_successcall,handle422AsToast:false,showErrorToast:showErrorToast,setError422:setError422});
                setIsLoading4(false);
              }  
  return (
    <Documentheaderfooter show={show} save={documentsave} onClose={handleClose} title='Upload Document' enabled={formvalue.saveenabled}>
     <Fileuploader  handleFrontview={handleFrontview}  docname={docname} acceptPDF={true} fileInputRef={fileInputRef} setImage={setImage} format={format} fileSize={fileSize} image={image} progress={progress} setProgress={setProgress} progressColor={progressColor} setProgressColor = {setProgressColor} setFileSize={setFileSize} uploading={uploading} setUploading={setUploading} setFormat={setFormat} setDocname={setDocname} setError422={setError422} setImageerror={(message) =>setError((prev) => ({...prev,frontimageerror: message}))} Acceptformat={['jpg','jpeg','png','pdf']}/> 
     {error.frontimageerror && <p className="error-message">{error.frontimageerror}</p>}
     <div className='row docpoprow'>
      <div className="col-sm-6 col-12">
      <label className="docpoplabel">Document Type<span className="docpopstar">*</span></label>
       <input type="text"  name="input" placeholder='Enter Document Type' className={`form-control docform ${formvalue.inputvalue ? 'docform-active' : ''}`}  value={formvalue.inputvalue} onChange={(e) => setFormValue((prev) => ({ ...prev, inputvalue: e.target.value }))} />  
      </div>
      <div className="col-sm-6 col-12">
      <label className="docpoplabel">Document Name<span className="docpopstar">*</span></label>
      <div className='position-relative'>
       <input type='text' name='docname' placeholder='Enter Document Name' value={docname} onChange={(e)=>{(setDocname(e.target.value));setError422('')}} readOnly={!image} className={`form-control docform ${docname ? 'docform-active' : ''}`}></input> 
      </div>
      </div>
      </div> 
      <div className='row docpoprow'>
        <div className='col-sm-6 col-12'>
        <label className="docpoplabel">Date of Issue<span className="docpopstar">*</span></label>
        <CustomDatePicker value={formvalue.dateofissue} disabledDate={disableFutureDates} onChange={(date)=>{setFormValue((prev)=>({...prev,dateofissue:date,expirydate:null}));setError((prev)=>({...prev,expiryerror:'',}));setError422('');}}/>
        {error.expiryerror && <p className="error-message">{error.expiryerror}</p>}
        </div>
        <div className='col-sm-6 col-12'>
        <label className="docpoplabel">Expiry Date</label>
        <CustomDatePicker value={formvalue.expirydate} open={formvalue.isopen} onClick={handleExpiryClick} onOpenChange={handleOpenChange} disabledDate={disablePastDates(dayjs())} onChange={(date)=>{setFormValue((prev)=>({...prev,expirydate:date,}));setError422('');}}/>
        </div>
      </div>
      {error422 && <p className="error-message">{error422}</p>} 
    </Documentheaderfooter>
  )
}

export default Vechicledocumentedit