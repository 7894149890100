import React, { useState } from 'react';
import user from '../../images/block-user_7542133 1.svg';
import email from '../../images/Group 1171275973.svg';
import phonecall from '../../images/phone-call_5068731 1.svg'
import check from '../../images/tick-circle-svgrepo-com 1.svg';
import Button from '../../component/savebutton';
import open_email from '../../images/open-mail_5005359 1.svg';
import { useNavigate } from 'react-router-dom';
function Banaccount({login_user}) {
  const navigate = useNavigate();  
  const [showscreen,setShowscreen] = useState({show:true,show1:false,show2:false});
  return (
    <>
    {showscreen.show && (
        <div className='pad-5'>
          <div className='bgbackcolor d-flex align-items-center justify-content-center mb-3'>
            <img src={user} alt='circle' />
          </div>
          <h5 className='fw-bold mb-3'>Your Account Has Been Banned</h5>
          <h6 className='fw-bold'>Hello, John!</h6>
          <p className='colordecription'>
            We regret to inform you that your account has been suspended. This action was taken because your account, or activity on it, doesn’t comply with our Community Guidelines regarding account integrity and authentic identity.
          </p>
      
          <div className='d-flex align-items-center gap-3 mb-3'>
            <img src={email} alt='email' className='emailimg' />
            <p className='bluecolur fw-bold mb-0'>johndoe@example.com</p>
          </div>
      
          <div className='d-flex align-items-center gap-3 mb-3'>
            <img src={phonecall} alt='phone' className='emailimg' />
            <p className='redcolur fw-bold mb-0'>(555) 123-4567</p>
          </div>
      
          <p className='colordecription'>
            If you believe this suspension was a mistake, you can take the following actions:
          </p>
      
          <div className='d-flex align-items-center gap-3 mb-4'>
            <Button
              defaultclassname='accountdetailsbutton1 rounded accountdetailsbuttonlogout'
              active={true}
              onClick={() => navigate(`/u/${login_user}/security/contact/support`)}
              label='Contact Support'
            />
            <Button
              defaultclassname='accountdetailsbutton1 rounded accountdetailsbuttonactivate'
              active={true}
              onClick={()=>setShowscreen((prev)=>({...prev,show:false,show1:true}))}
              label='Submit an Appeal'
            />
          </div>
      
          <p>
            For more information, please review our <strong className='bluecolur'>Terms of Service.</strong>
          </p>
        </div>
      )}
      {showscreen.show1 &&(
         <div className='pad-5'>
         <div className='bgbackcolor d-flex align-items-center justify-content-center mb-3'>
          <img src={open_email} alt='circle'></img>
          </div>
          <h5  className='fw-bold mb-3'>Contact Support</h5>
          <h6 className='fw-bold '>Hello, John!</h6>
          <p className='mb-3'>We’re sorry to see you go.</p>
          <p className='colordecription'>Thank you for reaching out to us. We understand how important your account is to you, and we’re here to help resolve the issue. Please provide the information requested below so we can assist you more effectively.</p>
          <h6 className='fw-bold'>Provide Your Account Details:</h6>
          <input type='text' name='name' autoComplete='off' className='form-control mb-3 support_input' placeholder='Enter your full name'></input>
          <input type='email' name='email' autoComplete='off' className='form-control mb-3 support_input' placeholder='Enter your email address'></input>
          <input type='text' name='userame' className='form-control mb-4 support_input' placeholder='Enter your username/ID'></input>
          <h6 className='fw-bold'>Describe the Issue:</h6>
          <p className='colordecription'>Please provide a detailed explanation of the issue you're experiencing. Be sure to mention any specific details or concerns related to the suspension of your account.</p>
          <textarea name='description' className="form-control resize_textarea_suppory mb-2"  placeholder="Enter here"/>
          <p>For further assistance, email us at <strong>support@dummy.com</strong></p>
          <p>Thank you for reaching out. Our team will respond shortly.</p>
          <div className='d-flex align-items-center gap-2'>
          <input type='checkbox' name='terms'></input>
          <p className='mb-0 colordecription fs-6'>I agree to the Terms of Service and Community Guidelines</p>
          </div>
          <div className='d-flex align-items-center gap-3 mt-3 mb-4'>
            <Button
              defaultclassname='accountdetailsbutton1 rounded accountdetailsbuttonlogout'
              active={true}
              onClick={() => navigate(`/u/${login_user}/security/contact/support`)}
              label='Contact Support'
            />
            <Button
              defaultclassname='accountdetailsbutton1 rounded accountdetailsbuttonactivate'
              active={true}
              onClick={()=>setShowscreen((prev)=>({...prev,show:false,show1:false,show2:true}))}
              label='Submit an Appeal'
            />
          </div>
        </div>
      )}
      {showscreen.show2 &&(
         <div className="d-flex justify-content-center align-items-center vh-100">
         <div className="text-center  p-4">
           <img src={check} alt='check' ></img>
           <h4 className="fw-bold mt-3">Request Submitted Successfully</h4>
           <p className="colordecription text-center">
           Your request has been successfully submitted. Our support team will<br></br> review your case and respond within 12 hours.
           </p>
           <Button defaultclassname='buttonexit rounded' onClick={()=>navigate(`/u/${login_user}/security`)} active={true} label='Exit'/>
         </div>
       </div>
      )}
    </>
  )
}

export default Banaccount