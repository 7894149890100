import React from "react";
import back from '../../../images/arrow-left-01.png';
import searchedlocationicon from '../../../images/address-icons/Group 1858.png';
function Updatesearchedlocation({addressComponents,confirmAndProceed,back1}){
    const { street} = addressComponents;
    return(
        <>
        <div className="d-flex flex-column justify-content-between address_divhalf1searched">
        <div>
          <div className="d-flex align-items-center">
            <img src={back} onClick={()=>back1('searchLocation','back1')} className="me-2" style={{cursor:'pointer'}} alt="back" width="17px" height="14px" />
            <h6 className="addaddressh6">Add Address</h6>
          </div>
          <div>
            <p className="addaddressSearchp">Confirm your address</p>
            <div className='combinedaddressdiv'>
              <div className="d-flex align-items-center justify-content-between w-100">
                <div className="combinedaddressdivflex w-100">
                  <img src={searchedlocationicon}  className='searchedlocationimage' alt='searchlocate' ></img>
                  <div>
                    {street && <p className="confirmaddressp">{street},</p>}
                    {/* {(city || state) && <p className="confirmaddressp">{`${city}${state ? ` - ${state}` : ''}`},</p>}
                    {(country || zipcode) && <p className="confirmaddressp">{`${country}${zipcode ? ` - ${zipcode}` : ''}`}</p>} */}
                  </div>
                </div>
                <button onClick={()=>back1('searchLocation','back1')} className="combinedaddressdivflexbuuton">Change</button>
              </div>
            </div>
          </div>
        </div>
        <button onClick={()=>confirmAndProceed('searchedLocationconfirm')} className="combinedaddressdivconfirmButtonprceed">Confirm & Proceed</button>
        </div>
        </>
    );
}
export default Updatesearchedlocation;