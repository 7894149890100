import { createSlice } from '@reduxjs/toolkit';

const documentTypeSlice = createSlice({
  name: 'documenttypes',
  initialState: {
    documentTypeOptions: [],
    loading: false,
    error: null,
  },
  reducers: {
    fetchDocumentTypeListPending: (state) => {
      state.loading = true;
      state.error = null;
    },
    fetchDocumentTypeListFulfilled: (state, action) => {
      state.loading = false;
      state.documentTypeOptions = action.payload;
    },
    fetchDocumentTypeListRejected: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  fetchDocumentTypeListPending,
  fetchDocumentTypeListFulfilled,
  fetchDocumentTypeListRejected,
} = documentTypeSlice.actions;

export default documentTypeSlice.reducer;


