import React, { useEffect, useRef, useState } from 'react';
import Select from 'react-select';
import Documentheaderfooter from '../../component/documentheaderfooter';
import { customStyles1, disableFutureDates, disablePastDates, formatDateForExpiry, formatDateForInput, HandleCalls } from '../../api/utils/utils';
import dayjs from 'dayjs';
import { useAcdamicOptions } from '../../api/hooks/redux-hooks/useReduxActions';
import CustomDatePicker from '../../component/customdatepicker';
import Fileuploader from '../../component/fileuploader';
import { useUpload } from '../../api/hooks/uploadhook';
import { acdamicstore } from '../../api/helper/apiurlhelper';
function Acdamicadd({show,setIsLoading4,acdamichistorydocument,setListvalues,imageviewer,showErrorToast}) {
  const [formvalues,setFormvalues] = useState({schoolname:'',isopen:false,acdamiclevel:null,startdate:null,enddate:null,career:'',achievement:'',isenabled:false,});
  const [error,setError] = useState({frontimageerror:'',expiryerror:'',countryerror:'',stateerror:''}); 
  const [error422,setError422] = useState('');
  const {academicLevelOptions,loading,loadAcdamicLevels} = useAcdamicOptions({showErrorToast});
  const [fileSize, setFileSize] = useState(0);
  const {uploadFile} = useUpload();
  const [format, setFormat] = useState('');
  const fileInputRef = useRef(null);
  const [docname,setDocname] = useState('');
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [progressColor, setProgressColor] = useState("#28a745");
  const [image, setImage] = useState('');  
  useEffect(()=>{
  if(show){
    loadAcdamicLevels();
  }
  },[show]);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormvalues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
    setError422('');
  }   
  const handleSelectChange = (selectedOption) => {
    setFormvalues((prevValues) => ({
      ...prevValues,
      acdamiclevel: selectedOption, 
    }));
  };
  const handleOpenChange = (open) => {
    setFormvalues((prev)=>({...prev,isopen:open}));
  }; 
  const handleExpiryClick = (e) => {
    if (!formvalues.startdate) {
      setError((prevState) => ({
        ...prevState,
        expiryerror:'Please select Date of Issue first'
      }));
      setFormvalues((prev)=>({...prev,isopen:false}));
    } else {
      setError((prevState) => ({
        ...prevState,
        expiryerror:''
      }));
      setFormvalues((prev)=>({...prev,isopen:true}));
    }
  };
   const checkFormacdamicValidity = () => {
          const isValid = (
            formvalues.schoolname !== '' &&
            formvalues.startdate !== null &&
            formvalues.enddate !== null &&
            formvalues.startdate !== '' &&
            formvalues.enddate !== '' &&
            formvalues.acdamiclevel !== '' &&
            image !== ''  
          );
          setFormvalues((prev)=>({...prev,isenabled:isValid}));
        };
        useEffect(() => {
          checkFormacdamicValidity();
        }, [formvalues.schoolname,formvalues.startdate,formvalues.enddate,formvalues.acdamiclevel,image]);
        const handleclose = () => {
          setListvalues((prev)=>({...prev,show:false}));
          setFormvalues((prev)=>({...prev,
            schoolname:'',startdate:null,enddate:null,acdamiclevel:null,
          }))
          setDocname('');
          setProgress(0);
          setUploading(false);
          setFormat('');
        } 
        const handledocumentsave = async() => {
         setIsLoading4(true);   
         const uploadedImage = await uploadFile(image,'acdamichistory',setIsLoading4);
            let data = {
            school: formvalues.schoolname,
            start: formatDateForInput(formvalues.startdate),
            end: formatDateForExpiry(formvalues.enddate),
            education_level_id: formvalues.acdamiclevel.value,
            image: uploadedImage
        }   
        if(formvalues.career){
            data.field_of_study = formvalues.career
        } 
        if(formvalues.achievement){
            data.achievement = formvalues.achievement
        }  
         const after_successcall = async() => {
            handleclose();
            await acdamichistorydocument();
        }
        await HandleCalls({apiCall:()=>acdamicstore(data,true),successCall:after_successcall,showErrorToast:showErrorToast,handle422AsToast:false,setError422:setError422});
        setIsLoading4(false)
        }
        const handleFrontview = (image) => {
        setListvalues((prev) => ({
            ...prev, selectimage: [image],show:false
        }));
        imageviewer('add');
        }
  return (
    <>
   <Documentheaderfooter show={show} title='Add Academic Background' onClose={handleclose} save={handledocumentsave} enabled={formvalues.isenabled}>
   <div className="row docpoprow">
    <div className="col-sm-6 col-12">
        <label className="docpoplabel1">School / College name<span className="docpopstar">*</span></label>
        <input name='schoolname' className={`form-control docform ${formvalues.schoolname ? 'docform-active' : ''}`} placeholder="Enter School/College Name"  value={formvalues.schoolname} onChange={handleChange} />
    </div>
    <div className="col-sm-6 col-12">
        <label className="docpoplabel1">Academic Level<span className="docpopstar">*</span></label>
        <Select options={academicLevelOptions} isLoading={loading}  value={formvalues.acdamiclevel}  isSearchable={true} placeholder="Select Work type" onChange={handleSelectChange} styles={customStyles1(Boolean(formvalues.acdamiclevel))}  />
    </div>
    </div>
    <div className='row docpoprow'>
    <div className='col-sm-6 col-12'>
    <label className="docpoplabel">Date From<span className="docpopstar">*</span></label>
    <CustomDatePicker value={formvalues.startdate} disabledDate={disableFutureDates} onChange={(date)=>{setFormvalues((prev)=>({...prev,startdate:date,enddate:null}));setError((prev)=>({...prev,expiryerror:'',}));setError422('');}}/>
    {error.expiryerror && <p className="error-message">{error.expiryerror}</p>}
    </div>
    <div className='col-sm-6 col-12'>
    <label className="docpoplabel">Date To<span className="docpopstar">*</span></label>
    <CustomDatePicker value={formvalues.enddate} open={formvalues.isopen} onClick={handleExpiryClick} onOpenChange={handleOpenChange} disabledDate={disablePastDates(dayjs())} onChange={(date)=>{setFormvalues((prev)=>({...prev,enddate:date,}));setError422('');}}/>
    </div>
    </div>
    <div className="row docpoprow">
    <div className="col-sm-6 col-12">
        <label className="docpoplabel1">Career</label>
        <input name='career' className={`form-control docform ${formvalues.career ? 'docform-active' : ''}`} placeholder="Enter Career" value={formvalues.career} onChange={handleChange} />
    </div>
    <div className="col-sm-6 col-12">
        <label className="docpoplabel1">Achievement</label>
        <input name='achievement' className={`form-control docform ${formvalues.achievement ? 'docform-active' : ''}`} placeholder="Enter Achievement" value={formvalues.achievement} onChange={handleChange} />
    </div>
    </div>
    <Fileuploader  handleFrontview={handleFrontview}  docname={docname} acceptPDF={false} fileInputRef={fileInputRef} setImage={setImage} format={format} fileSize={fileSize} image={image} progress={progress} setProgress={setProgress} progressColor={progressColor} setProgressColor = {setProgressColor} setFileSize={setFileSize} uploading={uploading} setUploading={setUploading} setFormat={setFormat} setDocname={setDocname} setError422={setError422} setImageerror={(message) =>setError((prev) => ({...prev,frontimageerror: message}))} Acceptformat={['jpg','jpeg','png',]}/>
      {error.frontimageerror && <p className="error-message">{error.frontimageerror}</p>}
      {error422 && <div className="error-message">{error422}</div>}
    </Documentheaderfooter>  
    </>
  )
}

export default Acdamicadd