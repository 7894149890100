import transparent from '../../images/11-01.jpg';
import { toast } from "react-toastify";
import { accountsdomain, publicdomaincore } from '../helper/urlhelper';
import { useDispatch,useSelector } from 'react-redux';
import { fetchUserProfile } from '../redux/userProfiles/actions';
import dayjs from 'dayjs';
import Swal from 'sweetalert2';
import { useState,useEffect } from 'react';
import { useCallback} from 'react';
import { useNavigate } from 'react-router-dom';
const accountsdomainurl = accountsdomain();
const publicDomainurl = publicdomaincore();
export const Error401 = () => {
  const lod4 = extractLoginUser();
  window.location.href = `/refresh?X-Authuser=${encodeURIComponent(lod4)}`;
  return lod4;
}

// maskedEmailUtil.js
export const getMaskedEmail = (email) => {
    if (!email) {
      return null;
    }
    const [username, domain] = email.split('@');
    const maskedUsername = username.charAt(0) + '*'.repeat(username.length - 1);  
    return `${maskedUsername}@${domain}`;
  };

 export const getMaskedPhoneNumber = (phoneNumber) => {
    if (!phoneNumber) {
      return null;
    }
  
    const lastTwoDigits = phoneNumber.slice(-2);
  
    return `**********${lastTwoDigits}`;
  };
  
  // dateUtils.js
export const formatDateForInput = (date) => {
  if (!date) return '';

  const dateObject = new Date(date);
  const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
  const year = dateObject.getFullYear();
  return `${year}-${month}-01`;
};

export const formatDateForExpiry = (date) => {
  if (!date) return '';

  const dateObject = new Date(date);
  dateObject.setMonth(dateObject.getMonth() + 1, 0);

  const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
  const year = dateObject.getFullYear();
  const lastDayOfMonth = dateObject.getDate();

  return `${year}-${month}-${lastDayOfMonth}`;
};
// imageUtils.js

export const convertImageToBase64 = (file, setImage, setFileSize, setFileType) => {
  const reader = new FileReader();

  reader.onloadend = () => {
    setImage([reader.result]);
    setFileSize(file.size);
    const fileNameWithoutExtension = file.name.split('.').slice(0, -1).join('.');
    setFileType(fileNameWithoutExtension);
  };

  if (file) {
    reader.readAsDataURL(file);
  }
};

export const parseFileExtension = (fileName) => {
  const dotIndex = fileName.lastIndexOf('.');
  return dotIndex !== -1 ? fileName.slice(dotIndex + 1) : '';
};

export const formatFileSize = (sizeInBytes) => {
  const sizeInKB = sizeInBytes / 1024;
  const sizeInMB = sizeInKB / 1024;

  if (sizeInMB >= 1) {
    return `${sizeInMB.toFixed(2)} MB`;
  } else {
    return `${sizeInKB.toFixed(2)} KB`;
  }
};
export const handleFileChange = (
  e,
  setImage,
  setFileSize,
  setFileType,
  setProgress,
  setProgressColor,
  setUploading,
  setFormat,
  setDocname
) => {
  const selectedFile = e.target.files[0];
  const fileName = selectedFile.name;
  const fileFormat = fileName.substring(fileName.lastIndexOf('.') + 1);
  setFormat(fileFormat);
  const fileNameWithoutExtension = fileName.replace(/\.[^/.]+$/, "");
  const updatedDocname = `${fileNameWithoutExtension}`;
  setDocname(updatedDocname);
  setImage([selectedFile])
  // convertImageToBase64(
  //   selectedFile,
  //   (image) => {
  //     // Callback function for setting the image state
  //     setImage(image);
  //   },
  //   (size) => setFileSize(size), // Callback function for setting file size
  //   (type) => setFileType(type), // Callback function for setting file type
  // );

  const totalSteps = 100;
  const processingSteps = 70;
  const stepInterval = 100;
  let currentStep = 0;
  setUploading(true);
  const progressInterval = setInterval(() => {
    currentStep += 50;
    const currentProgress = (currentStep / totalSteps) * 100;
    setProgress(currentProgress);
    if (currentStep === totalSteps) {
      clearInterval(progressInterval);
      setTimeout(() => {
        setProgressColor("#28a745");
        setUploading(true);
        // setEditImage(true);
      }, 500);
    } else if (currentStep === processingSteps) {
      setUploading(true);
    }
  }, stepInterval);
};
export const handleAddFileChange = (
  e,
  setImage,
  setFileSize,
  setProgress,
  setProgressColor,
  setUploading,
  setFormat,
  setDocname,
  setImageerror,
  Acceptformat,
  maxSizeMB=2
) => {
  const selectedFile = e.target.files?.[0];
  if(selectedFile){
  const fileName = selectedFile.name;
  const fileFormat = fileName.substring(fileName.lastIndexOf('.') + 1).toLowerCase();
const allowedTypes = Acceptformat;
if (!allowedTypes.includes(fileFormat)) {
  setImageerror(Acceptformat.join(',').toUpperCase() + " files are allowed.");
  return;
}
const sizeError = validateFileSize(selectedFile, maxSizeMB);
if (sizeError) {
  setImageerror(sizeError);
  return;
}
setImageerror("");
  setFormat(fileFormat);
  const fileNameWithoutExtension = fileName.replace(/\.[^/.]+$/, "");
  const updatedDocname = `${fileNameWithoutExtension}`;
  setDocname(updatedDocname);
  setImage(selectedFile)
  setFileSize(selectedFile.size)
  // convertImageToBase64(
  //   selectedFile,
  //   (image) => {
  //     setImage(image);
  //   },
  //   (size) => setFileSize(size), 
  // );

  const totalSteps = 100;
  const processingSteps = 70;
  const stepInterval = 100;
  let currentStep = 0;
  setUploading(true);
  const progressInterval = setInterval(() => {
    currentStep += 50;
    const currentProgress = (currentStep / totalSteps) * 100;
    setProgress(currentProgress);
    if (currentStep === totalSteps) {
      clearInterval(progressInterval);
      setTimeout(() => {
        setProgressColor("#28a745");
        setUploading(true);
        // setEditImage(true);
      }, 500);
    } else if (currentStep === processingSteps) {
      setUploading(true);
    }
  }, stepInterval);
}
};  
export const handleDragOver = (e) => {
  e.preventDefault();
};
export const handleDragOver1 = (e) => {
  e.preventDefault();
};

export const handleDrop = (e, handleFileDrop,handleError, acceptPDF = true) => {
  e.preventDefault();

  const droppedFiles = e.dataTransfer.files;
  if (droppedFiles.length > 0) {
    const selectedFile = droppedFiles[0];
    const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg'];
    
    if (acceptPDF) {
      allowedTypes.push('application/pdf');
    }

    if (allowedTypes.includes(selectedFile.type)) {
      handleFileDrop  (selectedFile);
    } else {
      handleError('Only JPG, JPEG, PNG' + (acceptPDF ? ', and PDF' : '') + ' files are allowed.');
    }
  }
};
export const handleDrop1 = (e, handleEditFileDrop, handleEditError, acceptPDF = true) => {
  e.preventDefault();

  const droppedFiles = e.dataTransfer.files;

  if (droppedFiles.length > 0) {
    const selectedFile = droppedFiles[0];
    const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg'];
    
    if (acceptPDF) {
      allowedTypes.push('application/pdf');
    }

    if (allowedTypes.includes(selectedFile.type)) {
      handleEditFileDrop  (selectedFile);
    } else {
      handleEditError('Only JPG, JPEG, PNG' + (acceptPDF ? ', and PDF' : '') + ' files are allowed.');
    }
  }
};
export const handleEditfilechange = (
  e,
  setEditImage,
  setFileSize,
  setFileType,
  setEditProgress,
  setEditProgressColor,
  setEditImageDetails,
  setFormat,
  setEditDocName
) => {
  const selectedFile1 = e.target.files[0];
  const fileName = selectedFile1.name;
  const fileFormat = fileName.substring(fileName.lastIndexOf('.') + 1);
  setFormat(`.${fileFormat}`);
  const fileNameWithoutExtension = fileName.replace(/\.[^/.]+$/, "");
  const updatedDocname = `${fileNameWithoutExtension}`;
  setEditDocName(updatedDocname);

  convertImageToBase64(
    selectedFile1,
    (image) => {
      setEditImage(image);
    },
    (size) => setFileSize(size),
    (type) => setFileType(type)
  );
  setEditProgressColor('#28a745');
    const totalSteps = 100;
    const processingSteps = 70;
    const stepInterval = 100;
    let currentStep = 0;
    setEditImageDetails(false);
    const progressInterval = setInterval(() => {
      currentStep += 50;
      const currentProgress = (currentStep / totalSteps) * 100;
      setEditProgress(currentProgress);
      if (currentStep === totalSteps) {
        clearInterval(progressInterval);
        setTimeout(() => {
          setEditProgressColor('#28a745');
          setEditImageDetails(false);
        }, 500);
      } else if (currentStep === processingSteps) {
        setEditImageDetails(false);
      }
    }, stepInterval);
};

export function removePrefix(originalString, prefixToRemove) {
  return originalString.replace(prefixToRemove, '');
}

export function getFormattedDate(dateString) {
  const date = new Date(dateString);
  const day = date.getDate();
  const month = date.getMonth() + 1; // Add 1 because months are 0-based
  const year = date.getFullYear();
  return `${month}/${year}`;
}
export function getImageBackgroundColor  (image)  {
  // Assuming image is an object with a property like "url" representing the image URL or data URI
  const imageUrl = image.url || '';

  if (imageUrl.toLowerCase().endsWith('.png')) {
    // Set background color for PNG images with transparency
    return 'transparent';
  } else {
    // Set a default background color for other image formats
    return `url(${transparent})`; // You can change this to any color you prefer
  }
};
export const removeallcookies = async () => {
  // Clear all cookies
  document.cookie.split(";").forEach(cookie => {
    document.cookie = cookie
      .replace(/^ +/, "")
      .replace(/=.*/, `=;expires=${new Date().toUTCString()};path=/`);
  });
}

export const extractLoginUser = () => {
  const routePath = window.location.pathname;
  const regex = /\/u\/([^/]+)/; // Match the last part after the last slash

  const match = routePath.match(regex);
  if (match) {
      return match[1]; // Return the matched value
  }
  return null; // Return null if no match is found
}


export const getQueryParameter = (paramName) => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  return urlParams.get(paramName);
}

export const mergeAllQueryParameters = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  // Extract and store the value of the 'redirecturl' parameter
  const redirectUrl = urlParams.get('redirecturl');

  // Create an array of entries, excluding the 'redirecturl' parameter
  const filteredParams = Array.from(urlParams.entries())
    .filter(([key]) => key !== 'redirecturl') // Filter out the 'redirecturl' parameter
    .map(([key, value]) => `${key}=${value}`) // Format the parameters
    .join('&'); // Join them with '&'
    
  const result = redirectUrl ? `${redirectUrl}${filteredParams}` : filteredParams;
  return result;
};

function redirectTo(url, params) {
  const queryString = Object.keys(params)
    .map(key => `${key}=${params[key]}`)
    .join('&');
  const redirectUrl = `${url}?${queryString}`;
  window.location.href = redirectUrl;
}

// Define the redirectToWithAuthUser function
export const redirectToWithAuthUser = (redirectUrl, X_AuthUser) => {
  const queryParams = {
    X_AuthUser: X_AuthUser
  };
  redirectTo(redirectUrl, queryParams);
}
export const handleKeyPress = (e) => {
  // Allow only numeric characters (0-9) and some specific control keys
  const allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Home', 'End'];

  if (!/^\d$/.test(e.key) && !allowedKeys.includes(e.key)) {
    e.preventDefault();
  }
};
export const handlePaste = (e, callback, maxDigits = 6) => {
  const pastedText = e.clipboardData.getData('text/plain');
  const numericText = pastedText.replace(/\D/g, '');
  const limitedText = numericText.slice(0, maxDigits);
  if (callback && typeof callback === 'function') {
    callback(limitedText);
  }
  e.preventDefault();
};

export const handleKeyDown = (e) => {
  const allowedKeys = ["Backspace", "ArrowLeft", "ArrowRight", "Delete", "/", "Tab"];
  if (!allowedKeys.includes(e.key)) {
    e.preventDefault();
  }
};

export const showErrorToast = (errorMessage, toastId, setIs422ErrorToastActive) => {
  if (!toast.isActive(toastId)) {
    setIs422ErrorToastActive(true); // Set the state indicating the error toast is active
    toast.error(errorMessage, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      toastId: toastId,
      onClose: () => setIs422ErrorToastActive(false) // Reset the state when the toast is closed
    });
  }
}

export const transformString = (str) => {
  if (!str) return '';
  return str.replace(/\s+/g, '-').toLowerCase();
};

export const customStyles1 = (hasValue) => ({
  
  control: (provided,state) => ({
    ...provided,
    borderColor: hasValue ? 'black' : '#DCDCDC',
    height:'40px',
    fontSize:'14px',
    boxShadow: state.isFocused ? 'none' : 'none',
    '&:hover': {
      borderColor: hasValue ? 'black' : '#DCDCDC',
    },
  }),placeholder: (provided) => ({
    ...provided,
    color: '#ABABAB',
    width: '100%',
    fontSize:'13px',
    fontWeight:'500',
  }),
  loadingIndicator: (provided) => ({
    ...provided,
    color: 'red',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: hasValue ? 'black' : '#DCDCDC',
    svg: {
      fill: hasValue ? 'black' : '#B9B9B9',
    },
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
});


export const disableFutureDates = (current) => {
  return current && current > dayjs().endOf('month');
};

// Function to disable past and current dates for the expiry date
// export const disablePastDates = (referenceDate) => (current) => {
//   return current && current <= dayjs(referenceDate).startOf('month');
// };
export const disablePastDates = (referenceDate) => (current) => {
  return current && current <= dayjs(referenceDate).endOf('month');
};

export const CustomSuffixIcon = ({isActive}) => (
  <span className="ant-picker-suffix-icon">
    <svg viewBox="64 64 896 896" fill={isActive ? 'hsl(0, 0%, 20%)' : '#DCDCDC'} focusable="false" data-icon="calendar" width="1em" height="1em"  aria-hidden="true"><path d="M880 184H712v-64c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v64H384v-64c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v64H144c-17.7 0-32 14.3-32 32v664c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V216c0-17.7-14.3-32-32-32zm-40 656H184V460h656v380zM184 392V256h128v48c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8v-48h256v48c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8v-48h128v136H184z"></path></svg>
  </span>
);
export const clearCookiesByNames =(cookieNames = [])=> {
  cookieNames.forEach(cookieName => {
    // Set the cookie expiration date to a past date to effectively delete the cookie
    document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${window.location.hostname};`;
  });
}

export const handleApiError = (error, setIsLoading = null, setError422 = null, handle422AsToast = true, showErrorToast) => {
  if (typeof showErrorToast !== 'function') {
    console.error('showErrorToast must be a function');
    return;
  }
 
  if (error.response) {
    const { status, data } = error.response;

    switch (status) {
      case 401:
        const errorCode = data.error_code;
        if (errorCode === 'token_expired' || errorCode === 'already_logged_out') {
          Error401(); // Handle unauthorized errors
        }else if(errorCode === 'session_error'){
          window.location.href = accountsdomainurl
        }else if(errorCode === 'auth_clear'){
          clearCookiesByNames(['__SID', '__MUID', '__OTID']);
        }else {
          showErrorToast(data.mobile_error || 'Unauthorized access.', '401Error');
        }
        break;

      case 422:
        if (handle422AsToast) {
          showErrorToast(data.mobile_error || 'Validation error.', '422Error');
        } else if (typeof setError422 === 'function') {
          setError422(data.mobile_error || 'Validation error.');
        }
        break;
        case 413:
          if (handle422AsToast) {
            showErrorToast(data.mobile_error || 'Validation error.', '422Error');
          } else if (typeof setError422 === 'function') {
            setError422(data.mobile_error || 'Validation error.');
          }
       break;   
      case 500:
        showErrorToast(data.mobile_error || 'Internal server error.', '500Error');
        break;

      default:
        showErrorToast(data.mobile_error || 'An unexpected error occurred.','UnexpectedError');
        break;
    }
  } else {
    showErrorToast('Network error or server is unreachable.', 'NetworkError');
  }

  if (typeof setIsLoading === 'function') {
    setIsLoading(false);
  }
};

export const generateUniqueKey = (prefix) => {
  return `${prefix}-${new Date().getTime()}-${Math.random().toString(36).substr(2, 9)}`;
};

export const handleInputChangeotp = (e) => {
  const isNumber = /^\d*$/.test(e.key);
  if (!isNumber) {
    e.preventDefault();
  }
};

export const useFetchUserProfile = (showErrorToast) => {
  const dispatch = useDispatch();
  const userProfile = useSelector((state) => state.userProfile.userProfile);
  const fetchProfile = useCallback(() => {
    dispatch(fetchUserProfile(showErrorToast));
  },[dispatch,showErrorToast]);
  return { userProfile, fetchProfile };
}

export const extractLengthValues = (regexString) => {
  if (regexString === null || typeof regexString !== 'string') return null;
  const match = regexString.match(/\\d\{(\d+)(?:,(\d+))?\}/);
  if (match) {
    const minlength = parseInt(match[1], 10);
    const maxlength = match[2] ? parseInt(match[2], 10) : minlength;
    return { minlength, maxlength };
  }
  return null;
};
export const calculateWidth = (label) => {
  const tempDiv = document.createElement('p');
  Object.assign(tempDiv.style, {
    position: 'absolute',
    visibility: 'hidden',
    fontSize: '14px',
    fontWeight: '400',
    whiteSpace: 'nowrap',
    margin: '0',
  });
  tempDiv.innerText = label;
  document.body.appendChild(tempDiv);
  const width = tempDiv.offsetWidth;
  document.body.removeChild(tempDiv);
  return width;
};

export const selectWidth = (dial = '') => calculateWidth(dial) + 82;

export const calculateMenuHeight = (offset = 200, maxHeight = 400) => {
  const availableHeight = window.innerHeight - offset;
  return `${Math.min(availableHeight, maxHeight)}px`;
};
const useMenuHeight = (offset = 200, maxHeight = 400) => {
  const [menuHeight, setMenuHeight] = useState(calculateMenuHeight(offset, maxHeight));

  const updateMenuHeight = useCallback(() => {
    setMenuHeight(calculateMenuHeight(offset, maxHeight));
  }, [offset, maxHeight]);

  useEffect(() => {
    updateMenuHeight(); // Initial height calculation
    window.addEventListener('resize', updateMenuHeight); // Listen for resize events

    return () => {
      window.removeEventListener('resize', updateMenuHeight); // Cleanup
    };
  }, [updateMenuHeight]);

  return menuHeight;
};
export default useMenuHeight;
export const countrystyle = (width,height,) => ({
  control: (provided) => ({
    ...provided,
    width:width,
    height: '100%',
    border: 'none',
    boxShadow: 'none',
    background: 'transparent',
    display: 'flex',
    alignItems: 'center',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: '0 8px',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  singleValue: (provided) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '0',
    display: 'flex',
    alignItems: 'center',
  }),
  input: (provided) => ({
    ...provided,
    margin: '0',
    padding: '0',
  }),
  placeholder: (provided) => ({
    ...provided,
    margin: '0',
    padding: '0',
  }),
  menu: (provided) => ({
    ...provided,
    minWidth: '300px',
  }),
  menuList: (provided) => ({
    ...provided,
    padding: 0,
    height: height,
    overflowY: 'auto',
  }),
  loadingIndicator:(provided) => ({
    ...provided,
    color:'black'
  })
});
export const validateAndSetMobileChange = (
  value,
  { setMobileChange, setError, setNumberInvalid, mobile_length, mobile_starting_digits }
) => {
  const { maxlength = 15, minlength = 15 } = extractLengthValues(mobile_length) || {};
  const startingDigitPattern = mobile_starting_digits ? new RegExp(`^${mobile_starting_digits}`) : null;
  if (value.startsWith('0')) value = value.slice(1);
  if (value.length > maxlength) value = value.slice(0, maxlength);

  const isValid = value.length >= minlength && value.length <= maxlength &&
                  (!startingDigitPattern || startingDigitPattern.test(value));
  setError(isValid || value.length === 0 ? '' : 'Invalid number');

  setNumberInvalid(isValid);
  setMobileChange(value);
};

// export const validateAndSetMobileChange = (
//   value,
//   { setMobileChange, setError, setNumberInvalid, mobile_length, mobile_starting_digits }
// ) => {
//   const { maxlength = 15, minlength = 15 } = extractLengthValues(mobile_length) || {};
//   const startingDigitPattern = mobile_starting_digits ? new RegExp(`^${mobile_starting_digits}`) : null;

//   // Remove leading zero
//   if (value.startsWith('0')) value = value.slice(1);

//   // Trim to effective max length
//   if (value.length > maxlength) value = value.slice(0, maxlength);

//   // Validate based on length and starting digits
//   const isValid = value.length >= minlength && value.length <= maxlength &&
//                   (!startingDigitPattern || startingDigitPattern.test(value));

//   setError(isValid || value.length === 0 ? '' : 'Invalid number');
//   setNumberInvalid(isValid);
//   setMobileChange(value);
// };

export const addressStyles1 = (height,background,radius,bottom) => ({
  control: (provided,state) => ({
    ...provided,
    borderColor: 'none',
    border:'none',
    minHeigt:height,
    backgroundColor:background,
    borderRadius:radius,
    marginBottom:bottom,
    fontSize:'14px',
    boxShadow: state.isFocused ? 'none' : 'none',
    '&:hover': {
      borderColor: 'none',
    },
  }),placeholder: (provided) => ({
    ...provided,
    color: '#ABABAB',
    width: '100%',
    fontSize:'13px',
    fontWeight:'500',
  }),
  loadingIndicator: (provided) => ({
    ...provided,
    color: 'red',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: background,
    svg: {
      fill: 'black' ,
    },
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
});
export const confirmDialog = async (title, text, confirmButtonText = 'Delete', cancelButtonText = 'Cancel') => {
  return await Swal.fire({
      title,
      text,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText,
      cancelButtonText,
  });
};

export const Pagenavigate = (path) => {
  const navigate = useNavigate();
  navigate(path);
} 
export const handleBeforeInput = (e) => {
  if (!/^\d$/.test(e.data)) {
    e.preventDefault();
  }
};
export const handleInputNameValidation = (e,{setError}) => {
  if (!/^[a-zA-Z\s]*$/.test(e.data)) {
    e.preventDefault();
    setError('Only alphabets are allowed');
  } else {
    setError('');
  }
};

export const HandleApiError = (error, options = {}) => {
  const {setError422 = null,handle422AsToast = true,showErrorToast,navigate = null,errorCall = null,setIsLoading=null} = options;
  if (!error.response) return;
  const { status, data } = error.response;
  const { error_code, mobile_error } = data;
  if (status === 401) {
    if (["token_expired", "already_logged_out"].includes(error_code)) {
      Error401();
    } else if (error_code === "session_error") {
      showErrorToast(mobile_error, "401Error");
      window.location.href = "https://accounts.dev.evzone.app/";
    } else {
      showErrorToast(mobile_error, "401Error");
    }
  } else if (status === 422) {
    const error_code = error.response.data.code;
    const error_code1 = error.response.data.error.user_vehicle_id;
    if (error_code === 'otp_retry') {
       errorCall();
    }else if(error_code1){
      errorCall();
    }
    else if(handle422AsToast){
      showErrorToast(mobile_error, "422Error");
    } else {
      setError422?.(mobile_error);
    }
  } else {
    const errorTypes = {
      500: "500Error",
      default: "UnexpectedError",
    };
    showErrorToast(mobile_error, errorTypes[status] || errorTypes.default);
  }
  setIsLoading?.(false);
};

export const handleCalls = async ({ apiCall, successCall, setIsLoading,setInitial,...options }) => {
  try {
    setIsLoading?.(true);
    setInitial?.(true);
    const response = await apiCall();
    if (response.status === 200) {
      await successCall?.(response);
      return response;
    }
  } catch (error) {
    HandleApiError(error, options);
  } finally {
    setIsLoading?.(false);
    setInitial?.(false);
  }
};
export const HandleCalls = async ({ apiCall, successCall,...options }) => {
  try {
    const response = await apiCall();
    if (response.status === 200) {      
      await successCall?.(response);
      return response;
    }
  } catch (error) {
    HandleApiError(error, options);
  } 
};
export const getLocalStorageItem = (key, defaultValue = null) => {
  const item = localStorage.getItem(key);
  return item ? JSON.parse(item) : defaultValue;
};

export const setLocalStorageItem = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const validateEmailValue = (email) => {
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailPattern.test(email);
};
export const getImageSrc = (image) => { 
  const removeDuplicateSubstrings = (url) => {
    const regex = /(https?:\/\/[^\/]+)(\/\1)+/g;
    return url.replace(regex, '$1');
   };
   const removeCore = (url) => {
    return url.replace(/(\/?core\/)+/g, '/core/');
   };
  if (image instanceof File || image instanceof Blob) {
    return {success:true,file:URL.createObjectURL(image)}; 
  }else if(image.startsWith('data:image')){
    return {success:false , file:image}
  } else if (typeof image === "string" && image.trim() !== "") {
    let fullUrl = `${publicDomainurl}/${image}`;
    fullUrl = removeDuplicateSubstrings(fullUrl);
    fullUrl = removeCore(fullUrl);
    return { success: false, file: fullUrl };
  }
  return { success: false, file: "" }; 
};
export const handleImageUploads = (e, options = {}) => {
  const { max = 2 } = options;
  const file = e.target.files[0];
  if (!file) return null;
  const fileSizeError = validateFileSize(file, max);
  if (fileSizeError) return fileSizeError;
  const fileTypeError = isAllowedFileType(file.type);
  if (fileTypeError) return fileTypeError;  
  return file;
};

export const isAllowedFileType = (fileType, allowedTypes) => {
  const defaultAllowedTypes = ['image/jpeg', 'image/jpg', 'image/png'];
  const typesToCheck = allowedTypes || defaultAllowedTypes;
  if (!typesToCheck.includes(fileType)) {
    const readableTypes = typesToCheck.map((type) => type.replace('image/', ''));
    return `Accept only for ${readableTypes.join(', ')}.`;
  }
};
export const validateFileSize = (file, maxSizeMB = 2) => {
  const maxSizeBytes = maxSizeMB * 1024 * 1024;
  if (file.size > maxSizeBytes) {
    return `File exceeds ${maxSizeMB}MB.`;
  }
  return null;
};
export const removecore = (value) => {
  if (value.includes("core/")) {
    return publicDomainurl + "/" + value.replace("core/", "");
  }
  return publicDomainurl + "/" + value;
};
export function extractImageString(filename) {
  return filename.replace(/\.[^.]+$/, "");
}