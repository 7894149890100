import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Navbars from "../navbar";
import leftarrow from '../images/arrow-left-01.png';
// import axios from "axios";
import axiosInstance from "../api/common/axiosinstand";
import { extractLoginUser ,handleApiError,handlePaste} from "../api/common/commonapi";
import { otpupdatesendapi , recoveryemailapi} from "../api/url";
import WithToast from "../api/common/withtoast";
const otpupdatesendapiurl = otpupdatesendapi();
const recoveryemailapiurl = recoveryemailapi();
function Recoveryemail({showErrorToast}) {
  const login_user = extractLoginUser();
  const [showrecoverymail, setShowrecoveryemail] = useState(true);
  const [showrecoverycode, setShowrecoverycode] = useState(false);
  const [otp, setOtp] = useState('');
  const [isSaveButtonEnablednext, setIsSaveButtonEnablednext] = useState(false);
  const [isSaveButtonEnablednextotp, setIsSaveButtonEnablednextotp] = useState(false);
  const [error422, setError422] = useState('');
  const [isLoading4, setIsLoading4] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [is422ErrorToastActive, setIs422ErrorToastActive] = useState(false);
  const navigate = useNavigate();
  const handleInputChangeotp = (e) => {
    const isNumber = /^\d*$/.test(e.key);
    if (!isNumber) {
      e.preventDefault();
    }
  };
  const onPaste = (e) => {
    handlePaste(e, setOtp);  // Reusing the common handlePaste function
    setError422('');
  };
  
  const back = () => {
    navigate(`/u/${login_user}/security`);
  }
  const cancel = () => {
    navigate(`/u/${login_user}/security`);
  }
  const check = () => {
    const valid = (
      email !== ''
    )
    setIsSaveButtonEnablednext(valid);
  }
  useEffect(()=>{
    check();
  },[email]);
  const next = async () => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(email)) {
      setError422('Please enter a valid email address.');
      return;
    }
    // const token = localStorage.getItem('access_token');
    // const headers = {
    //   'Authorization': `Bearer ${token}`,
    //   'Content-Type': 'application/json',
    // };
    const data = {
      email: email,
    }
    try {
      setIsLoading4(true);
      const response = await axiosInstance.post(otpupdatesendapiurl, data,);
      if (response.status === 200) {
        setIsLoading4(false);
        setError422('');
        const recovery_token = response.data.data.token;
        localStorage.setItem('recovery_token', recovery_token);
        // toast.success(response.data.message, {
        //   autoClose: 2000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "colored",
        //   backgroundColor: '#02CD8D'
        // });
        setShowrecoverycode(true);
        setShowrecoveryemail(false);
      }
    }
    catch (error) {
      handleApiError(error, setIsLoading4, setError422, false, showErrorToast);
    }
  }
  const handleChange = (e) => {
    const value = e.target.value;
    setOtp(value);
  };
  // const handleKeyDown = (e) => {
  //   const allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Home', 'End'];
  //   if (!/^\d$/.test(e.key) && !allowedKeys.includes(e.key)) {
  //     e.preventDefault(); // Prevent the default action
  //     setError422('Please enter only numbers'); // Set error message
  //   } else {
  //     setError422(''); // Clear error if valid key is pressed
  //   }
  // };
  const backs = () => {
    setOtp('');
    setShowrecoveryemail(true);
    setShowrecoverycode(false);
  }
  const codecancel = () => {
    navigate(`/u/${login_user}/security`);
  }
  const checkotp = () => {
    const valid = (
      otp !== ''
    )
    setIsSaveButtonEnablednextotp(valid);
  }
  useEffect(()=>{
    checkotp();
  },[otp])
  const confirmcode = async() => {
    // const token = localStorage.getItem('access_token');
    const recovery_token = localStorage.getItem('recovery_token');
    // const headers = {
    //   'Authorization': `Bearer ${token}`,
    //   'Content-Type': 'application/json',
    // };
    const data = {
      email: email,
      otp : otp ,
      token : recovery_token
    }
    try {
      setIsLoading4(true);
      const response = await axiosInstance.post(recoveryemailapiurl, data);
      if (response.status === 200) {
        navigate(`/u/${login_user}/security`);
        setShowrecoverycode(false);
        setIsLoading4(false);
        setError422('');
        setShowrecoveryemail(false);
      }
    }
    catch (error) {
      if(error.response){
        if(error.response.status === 422){
         const error_code = error.response.data.code;
         if (error_code === 'otp_retry') {
           navigate(`/u/${login_user}/security`);
           setIsLoading4(false);
         }else{
           handleApiError(error, setIsLoading4, setError422, false, showErrorToast);
         }
        } else{
         handleApiError(error, setIsLoading4, setError422, false, showErrorToast);
        }
     }else{
     handleApiError(error, setIsLoading4, setError422, false, showErrorToast);
     }
    }
  }
  return (
    <>
      {isLoading4 && (
        <>
          <div className="loaderover">
            <div className="loader "></div>
          </div>
        </>
      )}
      <div>
        <div className="container-fluid" style={{ height: '100px' }}>
          <Navbars />
        </div>
        <div className="name_div">
        {showrecoverymail && (
         <>
           <div className="name_div1">
            <div className="d-flex align-items-center">
              <img src={leftarrow} onClick={back} alt="leftarrow" className="name_arrow "></img>
              <h5 className="nameh5">Recovery Email</h5>
            </div>
            <div className="name-input name-inputimage name-inputimageemail ">
            <div>
                  <label htmlFor="inputField"  className="form-label" style={{fontWeight:'500',fontSize:'16px',lineHeight:'24px',color:'#000000'}}>Email Address</label>
                  <input type="email" value={email} onChange={(e) => {setEmail(e.target.value);setError422('')}} className={`form-control docform ${email ? 'docform-active' : ''}`}  id="inputField" placeholder="Enter Email" />
                </div>
                <div className="error-message error-messageprofile">{error422}</div>
                <div className="name_button_div">
                <button type="button" onClick={cancel} className="me-3  profilecancel">
                  Cancel
                </button>
                <button type="button" onClick={next} style={{ backgroundColor: isSaveButtonEnablednext ? '#02CD8D' : '#EAEAEA', color: isSaveButtonEnablednext ? '#FFF' : '#333' , cursor: isSaveButtonEnablednext ? 'pointer' : 'not-allowed' }} disabled={!isSaveButtonEnablednext} className={`profilesave`}>
                  Next
                </button>
              </div>
              </div>
            </div>
         </>
        )} 
         {showrecoverycode && (
          <>
           <div className="name_div1">
            <div className="d-flex align-items-center">
              <img src={leftarrow} onClick={backs} alt="leftarrow" className="name_arrow "></img>
              <h5 className="nameh5">Verify Email</h5>
            </div>
            <div className="name-input name-inputimage name-inputimageemail ">
            <div>
                  <label htmlFor="inputField"  className="form-label" style={{fontWeight:'500',fontSize:'16px',lineHeight:'24px',color:'#000000'}}>Verification Code</label>
                  <input type="text" value={otp} onChange={handleChange} maxLength={6} onPaste={onPaste} onKeyPress={handleInputChangeotp} className={`form-control docform ${otp ? 'docform-active' : ''}`}  id="inputField" placeholder="Enter Verification Code" />
                </div>
                <div className="error-message error-messageprofile">{error422}</div>
                <div className="name_button_div">
                <button type="button" onClick={codecancel} className="me-3  profilecancel">
                  Cancel
                </button>
                <button type="button" onClick={confirmcode} style={{ backgroundColor: isSaveButtonEnablednextotp ? '#02CD8D' : '#EAEAEA', color: isSaveButtonEnablednextotp ? '#FFF' : '#333' , cursor: isSaveButtonEnablednextotp ? 'pointer' : 'not-allowed' }} disabled={!isSaveButtonEnablednextotp} className={`profilesave`}>
                  Save
                </button>
              </div>
              </div>
            </div>
          </>
         )}
        </div>
        </div>
      {/* {isLoading && (
        <>
          <div className="loading-overlay loading-overlay1 style-2">
            <div className="loading-wheel loading-wheel1">
            </div>
          </div>
        </>
      )} */}
      {/* <Navbars />
      {showrecoverymail && (
        <div className="container-fluid twofactoremail">
          <div className="row justify-content-center align-items-center" style={{ height: '100vh' }}>
            <div className="col-md-7 col-sm-8 col-10">
              <div className="box" style={{ width: '100%', maxWidth: '620px', minHeight: '305px', backgroundColor: 'white', borderRadius: '18px', border: '1px solid #ABA7A7', padding: '40px 35px' }}>
                <div className="d-flex align-items-center mb-3" style={{ marginLeft: '10px' }}>
                  <img onClick={back} src={leftarrow} alt="leftarrow" width='19.5px' height='15.5px' className="mb-3 me-4" style={{ cursor: 'pointer' }}></img>
                  <h5 className="mb-3" style={{ fontWeight: '500', fontSize: '24px', lineHeight: '36px', color: '#232323' }}>Recovery Email</h5>
                </div>
                <div className="mb-3 name-input me-4 mb-4 ">
                  <label className="mb-2" style={{ fontWeight: '500', fontSize: '16px', lineHeight: '24px', color: '#000000' }}>Enter recovery email</label>
                  <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} className="form-control tewofactoremail" placeholder="Enter Email" />
                </div>
                <div className="d-flex justify-content-end mx-4">
                  <button type="button" onClick={cancel} className=" profilecancel me-4">
                    Cancel
                  </button>
                  <button onClick={next} type="button" disabled={is422ErrorToastActive ||!email.trim()} className={`profilesave ${!email.trim() ? 'disabledmobile' : ''}`}>
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {showrecoverycode && (
        <div className="container-fluid twofactoremail">
          <div className="row justify-content-center align-items-center" style={{ height: '100vh' }}>
            <div className="col-md-7 col-sm-8 col-10">
              <div className="box" style={{ width: '100%', maxWidth: '620px', minHeight: '305px', backgroundColor: 'white', borderRadius: '18px', border: '1px solid #ABA7A7', padding: '40px 35px' }}>
                <div className="d-flex align-items-center mb-3" style={{ marginLeft: '10px' }}>
                  <img onClick={backs} src={leftarrow} alt="leftarrow" width='19.5px' height='15.5px' className="mb-3 me-4" style={{ cursor: 'pointer' }}></img>
                  <h5 className="mb-3" style={{ fontWeight: '500', fontSize: '24px', lineHeight: '36px', color: '#232323' }}>Confirmation Code</h5>
                </div>
                <div className="mb-3 name-input me-4 mb-4 ">
                  <label className="mb-2" style={{ fontWeight: '500', fontSize: '16px', lineHeight: '24px', color: '#000000' }}>Enter confirmation code</label>
                  <input type="text" value={otp} onChange={(e) => setOtp(e.target.value)} className="form-control tewofactoremail" placeholder="Enter Confirmation Code" />
                </div>
                <div className="d-flex justify-content-end mx-4">
                  <button type="button" onClick={codecancel} className=" profilecancel me-4">
                    Cancel
                  </button>
                  <button onClick={confirmcode} disabled={is422ErrorToastActive ||!otp.trim()} className={`profilesave ${!otp.trim() ? 'disabledmobile' : ''}`} type="button" >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )} */}
    </>
  );
}
export default WithToast(Recoveryemail);