// import './App.css';

import { BrowserRouter as Router, Routes, Route , useLocation,Navigate} from 'react-router-dom';
import Compile from "./compile";
import { useEffect } from 'react';
import Datechange from "./profilesection/datechange";
import ContentSection from "./dashboard";
import Emailchange from "./profilesection/emailchange";
import Genderchange from "./profilesection/genderchange";
import Homeaddresschange from "./homeaddresschange";
// import Languagechange from "./languagechange";
// import Mobilenumberchange from "./mobilenumberchange";
import Mobilenumberedit from "./mobilenumberedit";
import Mobilenumberedits from "./mobilenumberedits";
import Namechange from "./profilesection/namechange";
import Profilechange from "./profilesection/profilechange";
import Services from "./service";
import Sidebar from "./sidebar";
import Passwordsecurity from "./passwordsecuritysection/passwordsecurity";
import Passworcurrent from "./passwordcurrent";
import Twofactor from "./passwordsecuritysection/twofactor";
import Twofactorauth from "./twofactorauth";
import Recoveryemailcode from "./recoveryemailcode";
import Recoveryphonecode from "./recoveryphonecode";
import Navbars from "./navbar";
import Transactions from "./transactions";
import Emailedit from './emailedit';
import Emailedits from './emailedits';
import Twofactortext from './twofactortext';
import Twofactorpassword from './twofactorpassword';
import Depositfunds from './depositfunds';
import Withdrawalfunds from './withdrawlfunds';
import IncomingPage from './incomeingpage';
import Outgoingpage from './outgoingpage';
import Twofactoranother from './twofactoranother';
import Twofactorconfirmcode from './twofactorconfirm';
import Twofactoremail from './twofactoremail';
import Twofactoremailauth from './twofactoremailauth';
import RequestPage from './requestspage';
import Review from './review';
import Alternativeaddresschange from './alternativeaddresschange';
import Twofactoremailconfirmcode from './twofactoremailconfirm';
import Reviewratings from './reviewratings';
import Jobposting from './jobpostings';
import Personaldocument from './personaldocument';
import Personaldocuments from './pesrsonaldocuments';
import Adddocument from './adddocument';
import Log from './lo';
import Uploaddocument from './uploaddocument';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Emergencyinfo from './profilesection/emergencyinfo';
import Registerservices from './registerservices';
import Cards from './cards';
import Paypal from './paypal';
import Mobilemoney from './mobilemoney';
import Bank from './bank';
import Organization from './organization';
import Systemsidebar from './systemsidebar';
import Purchase from './purchase';
import Tickets from './tickets';
import Subscription from './subscription';
import Contribution from './contribution';
import Payout from './payout';
import Refund from './refund';
import Wallettowallet from './wallettowallet';
import Wallettobank from './wallettobank';
import Wallettomobilemoney from './wallettomobilemoney';
import Wallettopaypal from './wallettopaypal';
import Giftcards from './giftcards';
import Transactionside from './transactionside';
import Agentrecord from './agentrecord';
import Agentpay from './agentpay';
import Manualsidebar from './manualsidebar';
import Collections from './collection';
import Systemrefund from './systemrefund';
import Coupons from './coupons';
import Cardtowallet from './cardtowallet';
import Banktowallet from './banktowallet';
import Mobilemoneytowallet from './mobilemoneytowallet';
import Paypaltowallet from './paypaltowallet';
import Manualgiftcard from './manualgiftcard';
import Orderdetail from './orderdetail';
import Addsources from './addsource';
import Dtransfer from './d-transfer';
import Ctransfer from './c-transfer';
import Qrtransaction from './qr-transaction';
import Forgotpage from './forgotpage';
import Sidapi from './api/common/sidapi';
import Refreshpage from './refresh pages/refreshpage';
import Adddocumentresources from './documentssection/adddocumentsresource';
import Documents from './documentssection/documents';
import Acdamichistorydocument from './documentssection/acdamicdocument';
import Workhistorydocument from './documentssection/workhistorydocument';
import Vechicledocument from './documentssection/vechicledocument';
import Languagechange from './profilesection/languagechange';
import Mobilenumberchange from './profilesection/mobilenumberchange';
import Passworchange from './passwordsecuritysection/passwordchange';
import Recoveryemail from './passwordsecuritysection/recoveryemail';
import Recoveryphone from './passwordsecuritysection/recoveryphone';
// import Loginpage from './authpages/loginpage';
import Updateemergencyinfo from './profilesection/updateemergencyinfo';
import Locat from './profilesection/locat';
import AddAddress from './profilesection/addresssection/addaddress';
import Updateaddress from './profilesection/addresssection/updateaddresssection/updateaddress';
// import Sdsd from './authpages/sdsd';
function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    // makeAjaxCall();
  }, [pathname]);

  return null;
}
function App() {
  return (
    <>

      <ToastContainer/>
      <Router>
        <ScrollToTop/>
          <Routes>
           {/* <Route path='aas' element={<Sdsd/>}/> */}
          <Route exact path='/' element={<Navigate to="/u/0/services" replace />} />
          {/* <Route exact path='/signup' element={<Loginpage/>} /> */}
          <Route path='/compile' element={<Compile/>}/>
          <Route path='/forgotpage' element={<Forgotpage/>}/>
          {/* <Route path='*' element={<Navigate to="/" />}/> */}
          <Route path="/u/:user/profile" element={<ContentSection />} />
          <Route path="/u/:user/profilechange" element={<Profilechange />} />
          <Route path="/u/:user/namechange" element={<Namechange />} />
          <Route path="/u/:user/datechange" element={<Datechange />} />
          <Route path="/u/:user/languagechange" element={<Languagechange/>} />
          <Route path='/Wallet/D-Transfer' element={<Dtransfer/>}/>
          <Route path="/u/:user/genderchange" element={<Genderchange />} />
          <Route path="/emailedit" element={<Emailedit />} />
          <Route path="/emailedits" element={<Emailedits />} />
          {/* <Route path='/email' element={<ContentSection/>}/>  */}
          <Route path="/u/:user/emailchange" element={<Emailchange />} />
          <Route path="/u/:user/mobilenumberchange" element={<Mobilenumberchange />} />
          <Route path="/mobilenumberchange/mobilenumberedit" element={<Mobilenumberedit />} />
          <Route path="/mobilenumberchange/mobilenumberedits" element={<Mobilenumberedits />} />
          <Route path="/homeaddresschange" element={<Homeaddresschange />} />
          <Route path="/u/:user/services" element={<Services />} />
          <Route path='/sidebar' element={<Sidebar/>}/>
          <Route path='/u/:user/documents' element={<Documents/>} />
          <Route path='/u/:user/security' element={<Passwordsecurity />} />
          <Route path='/u/:user/passwordchange' element={<Passworchange />} />
          <Route path='/passwordcurrent' element={<Passworcurrent />} />
          <Route path='/u/:user/twofactortext' element={<Twofactortext />} />
          <Route path='/u/:user/twofactor' element={<Twofactor />} />
          <Route path='/u/:user/twofactorauth' element={<Twofactorauth />} />
          <Route path='/u/:user/recoveryemail' element={<Recoveryemail/>} />
          <Route path='/recoveryemailcode' element={<Recoveryemailcode />} />
          <Route path='/u/:user/recoveryphone' element={<Recoveryphone />} />
          <Route path='/recoveryphonecode' element={<Recoveryphonecode />} />
          <Route path='/navbars'element={<Navbars/>}/>
          <Route path='/Wallet' element={<Transactions />} />
          <Route path='/depositfunds' element={<Depositfunds />} />
          <Route path='/u/:user/twofactorpassword' element={<Twofactorpassword />} />
          <Route path='/withdrawlfunds' element={<Withdrawalfunds />} />
          <Route path='/incomeingpage' element={<IncomingPage />} />
          <Route path='/outgoingpage' element={<Outgoingpage />} />
          <Route path='/u/:user/twofactoranother' element={<Twofactoranother />} />
          <Route path='/u/:user/twofactorconfirm' element={<Twofactorconfirmcode />} />
          <Route path='/u/:user/twofactoremail' element={<Twofactoremail />} />
          <Route path='/u/:user/twofactoremailauth' element={<Twofactoremailauth />} />
          <Route path='/requestspage' element={<RequestPage />} />
          <Route path='/u/:user/reviews' element={<Review />} />
          <Route path='/alternativeaddresschange' element={<Alternativeaddresschange />} />
          <Route path='/u/:user/addaddress' element={<AddAddress />} />
          <Route path='/u/:user/twofactoremailconfirm' element={<Twofactoremailconfirmcode />} />
          <Route path='/u/:user/reviewratings' element={<Reviewratings/>}/>
          <Route path='/jobpostings' element={<Jobposting/>}/>
          <Route path='/personaldocument' element={<Personaldocument/>}/>
          <Route path='/personaldocuments' element={<Personaldocuments/>}/>
          <Route path='/adddocument' element={<Adddocument/>}/>
          <Route path='/lo' element={<Log/>}/>
          <Route path='/uploaddocument' element={<Uploaddocument/>}/>
          <Route path='/u/:user/emergencyinfo' element={<Emergencyinfo/>}/>
          <Route path='/registerservices' element={<Registerservices/>}/>
          <Route path='/cards'element={<Cards/>}/>
          <Route path='/Wallet/Addsources'element={<Addsources/>}/>
          <Route path='/paypal'element={<Paypal/>}/>
          <Route path='/mobilemoney' element={<Mobilemoney/>}/>
          <Route path='/bank' element={<Bank/>}/>
          <Route path='/organization' element={<Organization/>}/>
          <Route path='/systemsidebar' element={<Systemsidebar/>}/>
          <Route path='/purchase' element={<Purchase/>}/>
          <Route path='/tickets' element={<Tickets/>}/>
          <Route path='/subscription' element={<Subscription/>}/>
          <Route path='/contribution' element={<Contribution/>}/>
          <Route path='/payout' element={<Payout/>}/>
          <Route path='/refund' element={<Refund/>}/>
          <Route path='/wallettowallet' element={<Wallettowallet/>}/>
          <Route path='/wallettobank' element={<Wallettobank/>}/>
          <Route path='/wallettomobilemoney' element={<Wallettomobilemoney/>}/>
          <Route path='/wallettopaypal'element={<Wallettopaypal/>}/>
          <Route path='/giftcards'element={<Giftcards/>}/>
          <Route path='/transactionside' element={<Transactionside/>}/>
          <Route path='/agentrecord' element={<Agentrecord/>}/>
          <Route path='/agendpay' element={<Agentpay/>}/>
          <Route path='manualsidebar' element={<Manualsidebar/>}/>
          <Route path='/collection' element={<Collections/>}/>
          <Route path='/systemrefund' element={<Systemrefund/>}/>
          <Route path='/coupons' element={<Coupons/>}/>
          <Route path='/cardtowallet' element={<Cardtowallet/>}/>
          <Route path='/banktowallet' element={<Banktowallet/>}/>
          <Route path='/mobilemoneytowallet' element={<Mobilemoneytowallet/>}/>
          <Route path='/paypaltowallet' element={<Paypaltowallet/>}/>
          <Route path='/manualgiftcard' element={<Manualgiftcard/>}/>
          <Route path='/orderdetail' element={<Orderdetail/>}/>
          <Route path='/Wallet/C-Transfer' element={<Ctransfer/>}/>
          <Route path='/Wallet/QR-Transactions'element={<Qrtransaction/>}/>
          <Route path='/u/:user/documents/workhistory/:userId/document'element={<Workhistorydocument/>}/>
          <Route path='/u/:user/documents/vechicle/:userId/document'element={<Vechicledocument/>}/>
          <Route path='/u/:user/documents/acdamichistory/:userId/document'element={<Acdamichistorydocument/>}/>
          <Route path='/sid' element={<Sidapi/>}/>
          <Route path='/refresh' element={<Refreshpage/>}/>
          <Route path='/u/:user/addso' element={<Adddocumentresources/>}/>
          <Route path='/u/:user/update/:dualdynamic/emergencyinfo' element={<Updateemergencyinfo/>}/>        
          <Route path='/u/:user/update/:dynamic/address' element={<Updateaddress/>}/>
          <Route path='/as'element={<Locat/>}/>
        </Routes>
      </Router>




    </>
  );
}


export default App;

