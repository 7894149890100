import React from 'react'
import SkeletonLoader from '../documentssection/skeletonloader';
import workdocument from '../images/general-id/Work.svg';
import generaldocument from '../images/general-id/add-general-id.png';
import acdamicdocument from '../images/general-id/Acadamic.svg';
import Button from './savebutton';
function Documentsectionnew({isLoading,documentRef,Type,onClick}) {
  const selectswitch = (Type) => {
    switch(Type){
      case 'General Identification':
        return {
            image: generaldocument,
            title: 'General Identification',
            description: 'For legitimacy, add documents confirming your identity in this country. Could be a National ID, driver’s license, worker’s license, etc.',
            buttonLabel: 'Add General ID',
          };
      case 'Add Work History' :
        return {
           image:workdocument,
           title: 'Add Work History',
           description: 'Tell us about current or previous employment, add any additional details like certificates you earned',
           buttonLabel: 'Add  Work Experience'
        }; 
      case 'Add Academic Background' : 
        return {
           image:acdamicdocument,
           title: 'Add Academic Background',
           description: 'Tell us about your academic background. Include the Academic Level you’ve earned, the institutions you’ve attended, any notable achievements, and additional certifications or training programs completed during your studies.',
           buttonLabel:'Add Academic Background'
        }  
    }
  }  
  const { image, title: selectedTitle, description, buttonLabel } = selectswitch(Type);
  const buttonClass = `addnewgeneralbutton ${Type === 'Add Work History' ? 'work200' : Type === 'Add Academic Background' ? 'addacdamic300' : ''}`;
  return (
    <>
    <div className="d-flex mt-5 justify-content-center align-items-center flex-column">
          {!isLoading && (
            <>
              <img
                src={image}
                alt="document"
                className="docorangeimage"
              />
              <h6 className="addnewgeneralh6">{selectedTitle}</h6>
              <p className="generalp">{description}</p>
              <Button label={buttonLabel} defaultclassname={buttonClass} onClick={onClick}/>
            </>
          )}
          {isLoading && (
            <>
              <SkeletonLoader documentRef={documentRef}/>
            </>
          )}
        </div>
    </>
  )
}

export default Documentsectionnew