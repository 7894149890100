import React, { useEffect,useState,useCallback} from "react";
import back from '../../../images/arrow-left-01.png';
import searchedlocationicon from '../../../images/address-icons/Group 1858.png';
import {handleBeforeInput, handleInputNameValidation, validateAndSetMobileChange } from "../../../api/utils/utils";
import PhoneInputWithCountrySelector from "../../../component/phoneinputcountryselector";
function Updatesearchlocationconfirm({addressComponents,back1,confirmAndProceed1,formValues,setFormValues,error,setError,countryData,setMobileChange,mobileChange}){
  const {dialCode,countryCode,countryOptions,mobile_length,mobile_starting_digits,isLoading,setMccId,setDialCode,loadCountryBySelection,setCountryCode,setMobile_length,setMobile_starting_digits,} = countryData;
  const [errormobile, setErrormobile] = useState('');
  const [numberinvalid,setNumberinvalid] = useState(false);
  const [errors,setErrors] = useState({addressLine2error:'',housenumbererror:''});
  useEffect(() => {
     
    if(countryCode && mobileChange ){
      loadCountryBySelection(countryCode);
    }    
    if (['Home', 'Friends', 'Office'].includes(formValues.addressType)) {
      setFormValues((prevState) => ({
        ...prevState,
        addressType: formValues.addressType,
      }));
    } else {
      setFormValues((prevState) => ({
        ...prevState,
        addressType: 'Others',
        otherType: formValues.addressType, 
      }));
  }
  }, [formValues.mobilecountry]);
  useEffect(() => {
    validateAndSetMobileChange(mobileChange, {
      setMobileChange,
      setError: setErrormobile,
      setNumberInvalid: setNumberinvalid,
      mobile_length,
      mobile_starting_digits
    });
  }, [mobileChange, mobile_length, mobile_starting_digits]);
  const handleKeyPress = (e) => {
    handleBeforeInput(e);
  };
  const handlePhoneNumberChange = (value) => {
    validateAndSetMobileChange(value, {
      setMobileChange: setMobileChange,
      setError: setErrormobile,
      setNumberInvalid: setNumberinvalid,
      mobile_length: mobile_length,             
      mobile_starting_digits: mobile_starting_digits 
    });
  };
  
  const handlePaste = (e) => {
      const pastedText = e.clipboardData.getData('text');
      let numericPastedText = pastedText.replace(/[^0-9]/g, '');
      const dialCodeWithoutPlus = dialCode.replace('+', '');
      if (pastedText.startsWith('+') && numericPastedText.startsWith(dialCodeWithoutPlus)) {
          numericPastedText = numericPastedText.slice(dialCodeWithoutPlus.length);
      }
      validateAndSetMobileChange(numericPastedText, {
        setMobileChange: setMobileChange,
        setError: setErrormobile,
        setNumberInvalid: setNumberinvalid,
        mobile_length: mobile_length,             
        mobile_starting_digits: mobile_starting_digits 
      });
      e.preventDefault();
  };

  const handleCountryChange = (selectedOption) => {
      const selectedCountryData = countryOptions.find(
          (country) => country.value === selectedOption.value
      );
      if (selectedCountryData) {
        const { mcc_id, dial_code, country_code, mobile_length, mobile_starting_digits } = selectedCountryData;
    
        setMccId(mcc_id);
        setDialCode(`+${dial_code}`);
        setCountryCode(country_code);
        setMobile_length(mobile_length);
        setMobile_starting_digits(mobile_starting_digits);
        validateAndSetMobileChange(mobileChange, {
          setMobileChange,
          setError: setErrormobile,
          setNumberInvalid: setNumberinvalid,
          mobile_length,
          mobile_starting_digits,
        });
      }
  };
 
    const handleInputChange = (e) => {
      const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value
    }));
    };
  const handleKeyDown = (e) => {
   handleInputNameValidation(e,{setError:setError });
  };
  const { street, city, state, country, zipcode } = addressComponents;
  const [checkbutton,setCheckbutton]=useState(false);
  const checkFormValidity = useCallback(() => {
    const check1 = formValues.city !== '' || city !== '';
    const check2 = formValues.postcode !== '' || zipcode !== '';
  
    let addressTypeIsValid = true;
  
    if (formValues.addressType === 'Others') {
      addressTypeIsValid = formValues.otherType.trim() !== '';
    } else {
      addressTypeIsValid = formValues.addressType !== '';
    }
  
    const valid =
      check1 &&
      check2 &&
      addressTypeIsValid &&
      formValues.mobileNumber !== '' &&
      formValues.houseNumber !== '' && numberinvalid === true;
      // formValues.addressLine2 !== '';
  
    setCheckbutton(valid);
  }, [formValues.city, formValues.postcode, formValues.mobileNumber, formValues.houseNumber, formValues.addressLine2, formValues.addressType, formValues.otherType, city, zipcode,numberinvalid]);
  
  useEffect(() => {
    checkFormValidity();
  }, [checkFormValidity, formValues.city, formValues.postcode, formValues.mobileNumber, formValues.houseNumber, formValues.addressLine2, formValues.addressType, formValues.otherType, city, zipcode,numberinvalid]);
  const confirm = (show) => {
    if(formValues.houseNumber.length > 80){
      setErrors((prev)=>({
        ...prev,housenumbererror:' Blocknumber must not exceed 80 characters.'
      }))
      return false;
  }else{
    setErrors((prev)=>({
      ...prev,housenumbererror:''
    }))
  }
    if(formValues.addressLine2.length > 80){
        setErrors((prev)=>({
          ...prev,addressLine2error:'AddressLine2 must not exceed 80 characters.'
        }))
        return;
    }else{
      setErrors((prev)=>({
        ...prev,addressLine2error:''
      }))
    }
    confirmAndProceed1(show);
  }
    return(
        <>
        <div>
          <div className="d-flex align-items-center">
            <img src={back} onClick={()=>back1('searchedLocation')} style={{cursor:'pointer'}} className="me-2" alt="back" width="17px" height="14px" />
            <h6 className="addaddressh6">Add Address</h6>
          </div>
          <div>
            <p className="addaddressSearchp">Confirm your address</p>
            <div className='combinedaddressdiv mb-3'>
              <div className="d-flex align-items-center justify-content-between w-100">
                <div className="combinedaddressdivflex w-100">
                  <img src={searchedlocationicon} className='searchedlocationimage' alt='searchlocate' ></img>
                  <div>
                  {zipcode ? (
                    <>
                    {street && <p className="confirmaddressp">{street}</p>}
                    </>
                  ) : (
                    <>
                    {street && (() => {
                      const lastCommaIndex = street.lastIndexOf(',');
                      const streetBeforeLastComma = lastCommaIndex !== -1 ? street.substring(0, lastCommaIndex) : street;
                      const streetAfterLastComma = lastCommaIndex !== -1 ? street.substring(lastCommaIndex + 1).trim() : '';
                      return (
                        <p className="confirmaddressp">
                          {streetBeforeLastComma}
                          {streetAfterLastComma && <br />}
                          {streetAfterLastComma}
                          { formValues.postcode ? ` - ${ formValues.postcode}` : ''}
                        </p>
                      );
                      })()}
                     </> 
                   )}  
                  </div>
                </div>
                <button onClick={()=>back1('searchLocation','back1')} className="combinedaddressdivflexbuuton">Change</button>
              </div>
            </div>
            <div className='combinedaddressdiv mb-3'>
            <label className="combinedaddressdivlabel">Name</label>
            <input type="text"  name="Name" className="form-control combinedaddressdivlabelinput" value={formValues.Name} onBeforeInput={handleKeyDown} onChange={handleInputChange}></input>
            {error && <p className="error-message">{error}</p>}
            <label className="combinedaddressdivlabel">Mobile Number</label>
            <div className="position-relative">
            <PhoneInputWithCountrySelector id='mobilemergency' newclassname="combinedaddressdivlabelinput" countryOptions={countryOptions} handleCountryChange={handleCountryChange} dialCode={dialCode} mobileChange={mobileChange} handlePhoneNumberChange={handlePhoneNumberChange}  handlePaste={handlePaste} countryCode={countryCode} minheight='45px'  walletprofileinput = 'walletprofile-input' walletprofileinputactive='walletprofile-input-active' isLoading={isLoading} placeholder=""/>
            </div>
          <div className="error-message">{errormobile}</div>
           {/* {!city && (
            <>  
           <label className="combinedaddressdivlabel">City</label>
           <input type="text"  name="city" className="form-control combinedaddressdivlabelinput" value={formValues.city} onChange={handleInputChange}></input>
           </>
            )} */}
            {!zipcode && (
            <>
           <label className="combinedaddressdivlabel">postal Code</label>
           <input type="text" onBeforeInput={handleKeyPress}  name="postcode" className="form-control combinedaddressdivlabelinput" value={formValues.postcode} onChange={handleInputChange}></input>
           </>
            )}
             <label className="combinedaddressdivlabel">House/Flat/Block No</label>
             <input type="text"  name="houseNumber" className="form-control combinedaddressdivlabelinput" value={formValues.houseNumber} onChange={handleInputChange}></input>
             {errors.housenumbererror && <div className="error-message">{errors.housenumbererror}</div>}
             <label className="combinedaddressdivlabel">Apartment/Road/Area(Optional)</label>
             <input type="text" name="apartment" className="form-control combinedaddressdivlabelinput" value={formValues.apartment} onChange={handleInputChange}></input>
             <label className="combinedaddressdivlabel">Address Line 2</label>
             <input type="text"  name="addressLine2" className="form-control combinedaddressdivlabelinput" value={formValues.addressLine2} onChange={handleInputChange}></input>
             {errors.addressLine2error && <div className="error-message">{errors.addressLine2error}</div>}
             <div className="address-type-container">
      <label className={`address-type ${formValues.addressType === 'Others' ? 'd-none' : ''}`}>
        <input
          type="radio"
          name="addressType"
          value="Home"
          checked={formValues.addressType === 'Home'}
          onChange={handleInputChange}
        />
        Home
      </label>
      <label className={`address-type ${formValues.addressType === 'Others' ? 'd-none' : ''}`}>
        <input
          type="radio"
          name="addressType"
          value="Office"
          checked={formValues.addressType === 'Office'}
          onChange={handleInputChange}
        />
        Office
      </label>
      <label className={`address-type ${formValues.addressType === 'Others' ? 'd-none' : ''}`}>
        <input
          type="radio"
          name="addressType"
          value="Friends"
          checked={formValues.addressType === 'Friends'}
          onChange={handleInputChange}
        />
        Friends
      </label>
      <label className="address-type">
        <input
          type="radio"
          name="addressType"
          value="Others"
          checked={formValues.addressType === 'Others'}
          onChange={handleInputChange}
        />
        Others
      </label>
      {
        formValues.addressType === 'Others' ? <div style={{position:'relative',width:'100%'}}><input type="text" name="otherType" value={formValues.otherType} onChange={handleInputChange} className="form-control combinedaddressdivlabelinput"></input><p onClick={() => setFormValues((prev) => ({ ...prev, otherType: '',addressType:'' }))} style={{position: 'absolute', marginRight: '15px',right:'0px', top: '45%', transform: 'translateY(-50%)', cursor: 'pointer',fontWeight:'400',fontSize:'14px',lineHeight:'18px',color:'#F77F00'}}>Cancel</p></div>: ""
      }
    </div>
            </div>
          </div>
        </div>
        <button onClick={()=>confirm('searchedLocationstore')} className={`combinedaddressdivconfirmButtonprceed ${!checkbutton ? 'combinedaddressdivconfirmButtonprceeddisabled' : 'combinedaddressdivconfirmButtonprceed'}`} disabled={!checkbutton}>Confirm & Proceed</button>
        </>
    )
}
export default Updatesearchlocationconfirm;