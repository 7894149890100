import React from 'react';
import eye2 from '../images/eye-02.png';
import { publicDomain } from '../api/helper/urlhelper';
import { getImageSrc } from '../api/utils/utils';
const publicDomainurl = publicDomain();
const ProfileImage = ({
  containerClass = '', 
  imageSrc = '', 
  imgClass = '', 
  altText = 'Profile Image', 
  imageview = () => {}, 
  replaceimage=()=>{},
  showeye = false,
  eyeclassname = 'profile_eye_ ',
}) => {
  const image = getImageSrc(imageSrc);
  return (
    <div className={containerClass}>
      <img id="profile_image" src={image.file} className={imgClass} alt={altText}/>
      {showeye &&(
        <img src={eye2} onClick={(e) => {e.stopPropagation(); imageview();}} className={`cursor_pointer ${eyeclassname}`} alt="View"/>
      )}
    </div>
  );
};

export default ProfileImage;
