import React from 'react';
import Navbars from '../navbar';
function Commonheader() {
  return (
    <div className="container-fluid" style={{ height: '100px' }}>
    <Navbars />
    </div>
  )
}

export default Commonheader;