import React from 'react';
import eye from '../images/eye (1).svg';
import popdeletes from '../images/trash (1).svg';
import fileopen from '../images/Group 1492.svg';
import filetext from '../images/file-text.svg';
import { formatFileSize, getImageSrc, handleAddFileChange, handleDragOver, handleDrop, transformString } from '../api/utils/utils';
function Fileuploader({image,setImage,handleFrontview,fileSize,setFileSize,docname,format,setDocname,setFormat,setImageerror,Acceptformat,progress,progressColor,uploading,onDelete,fileInputRef,setError422,setProgress,setProgressColor,setUploading,acceptPDF}) {
    const handleFileClick = () => {
        fileInputRef.current.click();
        setError422('');
    }  
    const handleFileDrop = (selectedFile) => {
        handleAddFileChange(
          { target: { files: [selectedFile] } },
          setImage,
          setFileSize,
          setProgress,
          setProgressColor,
          setUploading,
          setFormat,
          setDocname,
          setImageerror,
          Acceptformat
    )} 
    const handleimagedelete = () => {
        setUploading(false);
        setProgress(0);
        setImage('');
        setDocname('');
        if(onDelete){
          onDelete();
        }
    }
    const handleError = (message) => {
        setImageerror(message)
    } 
    const imageSrc = getImageSrc(image);
    
  return (
    <>
    {uploading ? (
    <div className="popprogressbox">
    <div className="d-flex align-items-center">
    <div className="d-flex align-items-center mb-2" style={{width:'75%'}}>
    <img src={format === 'pdf' ? filetext : imageSrc.file} alt="file" className="popuploadfileimg me-3"></img> 
     <div style={{overflow:'hidden'}}>
        <p className="popfilefrontp">{`${transformString(docname)}.${transformString(format)}`}</p>
        {fileSize && fileSize >= 1024 && (
        <p className="popfilekb">{formatFileSize(fileSize)}</p>
         )}
    </div>
    </div>
    <div style={{width:'25%',textAlign:'end'}}>
    {progress === 100 && (
        <img src={eye} alt="eye" onClick={() => handleFrontview(image)} className="popupeye me-3"></img>
    )}
    <img src={popdeletes} alt="delete" className="popupeye" onClick={handleimagedelete}></img>
    </div>    
    </div>
    <div className="progress mt-1 mb-1">
        <div className="progress-bar" role="progressbar" style={{ width: `${progress}%`, backgroundColor: progressColor }} aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100">
        {``}
        </div>
    </div>      
    </div>
    ) : (
        <div className="popinputbox mt-3" onClick={handleFileClick} onDrop={(e) => handleDrop(e, handleFileDrop,handleError,acceptPDF,)} onDragOver={handleDragOver}>
        <div className="d-flex align-items-center justify-content-center flex-column p-4">
          <img src={fileopen} className="popuploadflle" alt="file" width='60px' height='auto'></img>
          <h5 className="popuploadh5"><span className="popspan">Click to Upload</span>or drag and drop</h5>
          <p className="popuploadp">{`File format: ${Acceptformat.map(format => ` .${format}`).join(',')}`}</p>
          <p className="popuploadp">( Max. File size : 20 MB)</p>
        </div>
        <input type="file" ref={fileInputRef}  accept={Acceptformat.map(format => `.${format}`).join(',')} className='d-none' onChange={(e) => handleAddFileChange(e,setImage, setFileSize, setProgress, setProgressColor,setUploading,setFormat,setDocname,setImageerror,Acceptformat)}  />
      </div>
    )}
    </>
  )
}

export default Fileuploader;