import React from 'react';
import { useAuth } from '../api/hooks/checkauthhook';
import { accountsdomain } from '../api/helper/urlhelper';
import Authloader from '../component/authloader';
const PrivateRoute = ({ children }) => {
    const { token, isLoading } = useAuth();
    if (isLoading) return <Authloader/>; 
    
  
    return token && !isLoading ? children : (window.location.href = accountsdomain());
  };
  
  export default PrivateRoute;