import React from 'react';
import { Image } from 'antd';
import { getImageSrc } from '../api/utils/utils';

function ImageViewer({ 
    isPreviewVisible, 
    selectedImage1, 
    onClose, 
    isMultiImage 
}) {
    const handlePreviewClose = () => { 
        if (onClose) {
            onClose(); 
        }
    };
    return (
        <>
        {isPreviewVisible && (
        <Image.PreviewGroup preview={{ visible: isPreviewVisible, onClose: handlePreviewClose }}>
          {isMultiImage ? (
            selectedImage1?.map((image, index) => {
              const { file } = getImageSrc(image); 
              return (
                <Image
                  key={index}
                  src={file}
                  alt={`Image ${index + 1}`}
                  style={{ maxWidth: '100%', maxHeight: '80vh', zIndex: '9999', display: 'none' }}
                />
              );
            })
          ) : (
            <Image
              src={getImageSrc(selectedImage1[0]).file}
              alt="Enlarged Image"
              style={{ maxWidth: '100%', maxHeight: '80vh', zIndex: '9999', display: 'none' }}
            />
          )}
        </Image.PreviewGroup>
      )}
        </>
    );
}

export default ImageViewer;
