import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import edit from '../../images/job_details_icons/edit_1-01.png';
import Documentsectionnew from '../../component/documentsectionnew';
import acdamicdeleteimg from '../../images/trash-red-circle-01.png';
import { confirmDialog, HandleCalls, removecore } from '../../api/utils/utils';
import { workdelete, workHistoryList } from '../../api/helper/apiurlhelper';
import adddocument from '../../images/document_add-01.png';
import Button from '../../component/savebutton';
import Workhistoryadd from './workhistoryadd';
import ImageViewer from '../../component/imageviewer';
import Workhistoryedit from './workhistoryedit';
function Workhistorylist({documentRef,login_user,setIsLoading4,showErrorToast}) {
   const navigate = useNavigate();  
   const [listvalues,setListvalues]= useState({workhistory:[],isLoading:false,show:false,selectimage:[],ispreviewvisible:false,ischeck:null,editvalues:[],editshow:false});
   const workhistorydocumentlist = (acdamic) => {
    const id = acdamic.id;
    navigate(`/u/${login_user}/documents/workhistory/${id}/document`);
  }
   const workhistorysdocument = async() => {
    setListvalues((prev)=>({...prev,isLoading:true}));
     const after_successcall = async(response) => {
      setListvalues((prev)=>({...prev,workhistory:response.data.data}));
     }
     await HandleCalls({apiCall:workHistoryList,successCall:after_successcall,showErrorToast:showErrorToast,});
    setListvalues((prev)=>({...prev,isLoading:false}));
   }
   useEffect(()=>{
    workhistorysdocument();
   },[]);
   const addwork = () => {
    setListvalues((prev)=>({...prev,show:true}));
   }
   
   const handledeleteorkhistory = async(workhistorys) => {
    const id = workhistorys.id;
    const result = await confirmDialog(
        'Are you sure?',
      'You won\'t be able to revert this!',
    );
    if(result.isConfirmed){
        const after_successcall = async() => {
            await workhistorysdocument();
          }
       setIsLoading4(true);
       await HandleCalls({apiCall:()=>workdelete(id),successCall:after_successcall,showErrorToast:showErrorToast}); 
       setIsLoading4(false)
    }
   } 
   const imageviewer = (show) => {
        setListvalues((prev)=>({...prev,ispreviewvisible:true,ischeck:show}));
   }
   const closeimageviewer = () => {
    if(listvalues.ischeck === 'add'){
        setListvalues((prev)=>({...prev,show:true}));
      }else if(listvalues.ischeck === 'edit'){
        setListvalues((prev)=>({...prev,editshow:true}));
      }
      setListvalues((prev)=>({...prev,ispreviewvisible:false}));
   }
   const handleupdatehistory = (doc) => {
     if(doc){
        setListvalues((prev)=>({...prev,editvalues:doc,editshow:true}));
     }
   }
  return (
    <>
     {listvalues.workhistory.length > 0 ? (
      <>  
       {listvalues.workhistory.map((workhistorys, index) => (
        <div key={index} className="docbox">
          <div className="d-flex align-items-center" style={{ width: "100%",gap:'10px' }}>
          <div className="d-flex align-items-center acdamic45" style={{ width: '45%',overflow:'hidden' }}>
            <div className="me-3">
              <img src={removecore(workhistorys.image)} alt="car" className="docvehicleimg"></img>
            </div>
            <div className="workhistorydiv">
                  <h6 className="workhistoryh6">{workhistorys.designation}</h6>
                  <p className="workhistoryp">{workhistorys.company}</p>
                </div>
            </div>
                <div className="acdamic20" style={{ width: '20%',overflow:'hidden' }}>
                <div className="text-start">
                  <h6 className="workhistoryh6">{new Date(workhistorys.start).getFullYear()}-{new Date(workhistorys.end).getFullYear()}</h6>
                 </div>
                </div>
                <div className="d-flex align-items-center justify-content-end acdamic35" style={{ width: '35%' }}>
              {/* <div className="d-flex"> */}
                <div className="docvechilebuttons me-3">
                  <button style={{ position: 'relative' }}  onClick={() => handleupdatehistory(workhistorys)}  className=" docuploadvehicle">
                    <div className="butimag">
                      <div className="__col docvechilespan">
                        <img src={edit} alt="deposits" className="me-2" width='18px' height='auto' />
                      </div>
                      <span className="__col docvechilespan">Update Detail</span>
                    </div>
                  </button>
                  <button style={{ position: 'relative' }} onClick={() => workhistorydocumentlist(workhistorys)} className=" docuploadviewdocument">
                    <div className="butimag">
                      <div className="__col docvechilespan">
                        <img src={adddocument} alt="deposits" className="me-2" width='18px' height='auto' />
                      </div>
                      <span className="__col docvechilespan">Document</span>
                    </div>
                  </button>
                </div>
                <div>
                  <img src={acdamicdeleteimg} alt="delete"  onClick={() => handledeleteorkhistory(workhistorys)} className="docvehicledeletered"></img>
                </div>
              {/* </div> */}
            </div>
          </div>
        </div>
      ))}
      <div className='text-end buttonwidthdoc'>
     <Button label='New' icon={true} active={true} defaultclassname='docaddnew mt-3' onClick={addwork}></Button>
     </div>
     </>
     ) : (
      <Documentsectionnew isLoading={listvalues.isLoading} Type='Add Work History' onClick={addwork} documentRef={documentRef}/>
     )}
     <Workhistoryadd show={listvalues.show} imageviewer={imageviewer} workhistorysdocument={workhistorysdocument} listvalues={listvalues} setListvalues={setListvalues} showErrorToast={showErrorToast} setIsLoading4={setIsLoading4}/>
     <Workhistoryedit show={listvalues.editshow} values={listvalues.editvalues} imageviewer={imageviewer} workhistorysdocument={workhistorysdocument} listvalues={listvalues} setListvalues={setListvalues} showErrorToast={showErrorToast} setIsLoading4={setIsLoading4}/>
     {listvalues.selectimage && 
      <ImageViewer isPreviewVisible={listvalues.ispreviewvisible} selectedImage1={listvalues.selectimage} onClose={closeimageviewer} isMultiImage={false}/>
      }
    </>
  )
}

export default Workhistorylist