import React, { useState,useEffect} from "react";
import Navbars from "../../../navbar";
import back from '../../../images/arrow-left-01.png';
import locationcurrent from '../../../images/address-icons/Group 1488.png';
import { getuserlocationapi} from "../../../api/helper/urlhelper";
import { useIpContext } from "../../../api/hooks/iphook";
import PlaceAutocomplete from "../../addresssection/autocomplete";
import { useNavigate } from "react-router-dom";
import {handleApiError} from "../../../api/utils/utils";
import Updatesearchlocationstore from "./updatesearchlocationstore";
import Updatesearchlocationconfirm from "./updatesearchlocationconfirm";
import Updatesearchedlocation from "./updatesearchedlocation";
import { useParams } from "react-router-dom";
import axiosInstance from "../../../api/utils/axiosutils";
import WithToast from "../../../api/hooks/withtoasthook";
import { useCountryData } from "../../../api/hooks/mobilehook";
import Mapdesign from "../../addresscommon/mapdesign";
import { fetchAddress } from "../../addresscommon/addressfunction";
const getuserlocationapiurl = getuserlocationapi();
function Updateaddress({showErrorToast,login_user}){
  const navigate = useNavigate();
  const {
    mccId, dialCode, countryCode, countryOptions,mobile_length,mobile_starting_digits,isLoading,setMccId,setDialCode,setCountryCode,setMobile_length,setMobile_starting_digits,
    loadCountryBySelection,empty_values,
  } = useCountryData();
  const [addressline_1, setAddressline_1] = useState([]);
  const [divHeight, setDivHeight] = useState(window.innerHeight - 160);
  const [addressComponents, setAddressComponents] = useState({street: "",country: "",city: "",zipcode: "",state: "",latitude: "",longitude: "",code:""});
  const [loading, setLoading] = useState(false);
  const [mobileChange, setMobileChange] = useState('');
  const [formValues, setFormValues] = useState({Name:'',id:'',code:  '',houseNumber: '',apartment:  '',addressLine2: '',location_type: '',addressType: '',otherType: '',default:false, city:'',postcode:'',mobilecountry:false});
  const { getUserIpCountryCode,latitude,longitude} = useIpContext();
  const [locationState, setLocationState] = useState({ searchLocation: false, searchedLocation: false,searchedLocationconfirm :false,searchedLocationstore:true });
  const [cameraProps, setCameraProps] = useState({center: { lat:123.456, lng: 789.012 },zoom: 15});
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [error,setError] = useState('');
  const [markerPosition, setMarkerPosition] = useState({lat:parseFloat(addressline_1.latitude),lng:parseFloat(addressline_1.longitude)});
  useEffect(()=>{
    const path = window.location.pathname;
    const split_path = path.split('/');
    if(split_path[5] === 'address'){
      empty_values()
    } 
  },[]);
    const {dynamic} = useParams();
    useEffect(() => {
      const getuseraddress = async () => {
        try {
          setLoading(true);
          const response = await axiosInstance.get(getuserlocationapiurl);
          if (response.status === 200) {
            const emergency = response.data.data;
            const matchingEntry = emergency.find(entry => entry.id.toString() === dynamic.toString());
            if (matchingEntry) {
              setAddressline_1(matchingEntry);
              setFormValues((prev)=>({
                ...prev,mobilecountry:true
              }));
            } else {
              navigate(`/u/${login_user}/profile`);
            }
          }
        } catch (error) {
          handleApiError(error, null, null, true, showErrorToast);
        }
        finally{
          setLoading(false)
        }
      };
  
      getuseraddress();
    }, [dynamic, login_user, navigate, showErrorToast]);

    const countryData = {
      mccId,
      dialCode,
      countryCode,
      countryOptions,
      mobile_length,
      mobile_starting_digits,
      isLoading,
      setMccId,
      setDialCode,
      setCountryCode,
      setMobile_length,
      loadCountryBySelection,
      setMobile_starting_digits,
      empty_values
    };

    useEffect(() => {
      setAddressComponents({
        street: addressline_1.address_line_1 ? addressline_1.address_line_1 : '',
        country: addressline_1.country && addressline_1.country.name ? addressline_1.country.name : '',
        city: addressline_1.city && addressline_1.city.name ? addressline_1.city.name : '',
        zipcode: addressline_1.postal_code ? addressline_1.postal_code : '',
        state: addressline_1.state && addressline_1.state.name ? addressline_1.state.name : '',
        latitude: addressline_1.latitude ? addressline_1.latitude : '',
        longitude: addressline_1.longitude ? addressline_1.longitude : '',
        code:addressline_1.country && addressline_1.country.country_code ? addressline_1.country.country_code : '' 
      });
      setFormValues({
        Name: addressline_1.first_name ? addressline_1.first_name : '',
        id: addressline_1.id,
        code: addressline_1.mcc && addressline_1.mcc.country_code ? addressline_1.mcc.country_code : '',
        houseNumber: addressline_1.block_number ? addressline_1.block_number : '',
        apartment: addressline_1.apartment_number ? addressline_1.apartment_number : '',
        addressLine2: addressline_1.address_line_2 ? addressline_1.address_line_2 : '',
        location_type: addressline_1.location_type ? addressline_1.location_type : '',
        addressType: addressline_1.location_type ? addressline_1.location_type : '',
        otherType: addressline_1.location_type ? addressline_1.location_type : '',
        default: addressline_1.is_primary ? true : false,
        postcode: '',
      });
      setMobileChange(addressline_1.mobile ? addressline_1.mobile : '');
      setMccId(addressline_1.mcc && addressline_1.mcc.id ? addressline_1.mcc.id : '');
      setCountryCode(addressline_1?.mcc?.country_code);
    }, [addressline_1]);
  useEffect(() => {
    if (addressline_1.latitude && addressline_1.longitude) {
      setCameraProps({
        center: { lat: parseFloat(addressline_1.latitude), lng: parseFloat(addressline_1.longitude) },
        zoom: 15
      });
      setMarkerPosition({lat:parseFloat(addressline_1.latitude),lng:parseFloat(addressline_1.longitude)})
    }
  }, [addressline_1]);
  useEffect(() => {
    if (latitude && longitude) {
      setCameraProps({
        center: { lat: latitude, lng: longitude },
        zoom: 10
      });
    }
  }, [latitude, longitude]);
  const fetchLocation = async () => {
    setLoading(true);
      await getUserIpCountryCode().finally(()=>setLoading(false));
  };
  const handleMarkerClick = (event) => {
    if (event) {
      const clickedLatLng = {
        lat: event.detail.latLng.lat,
        lng: event.detail.latLng.lng
      };
      setCameraProps({ center: clickedLatLng, zoom: 15 });
      setMarkerPosition(clickedLatLng);
      fetchAddress(clickedLatLng,setAddressComponents,setLocationState,setCameraProps,setMarkerPosition);
    }
  };
  const handleUseCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const currentLatLng = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };
        setCameraProps({ center: currentLatLng, zoom: 15 });
        setMarkerPosition(currentLatLng);
        fetchAddress(currentLatLng,setAddressComponents,setLocationState,setCameraProps,setMarkerPosition);
      }, (error) => {
        console.error("Error getting current location:", error);
      });
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  };
  const showstate = (show) => {
    setLocationState((prevstate) => ({
      ...prevstate,
      searchedLocation:'searchedLocation' === show,
      searchLocation:'searchLocation' === show,
      searchedLocationconfirm: 'searchedLocationconfirm' === show,
      searchedLocationstore: 'searchedLocationstore' === show,
    }));
  }
  const handleCameraChange = (ev) => {
    setCameraProps(ev.detail);
  };

  const handlePlaceSelect = (place) => {
    setSelectedPlace(place);
    setCameraProps({ center: place.geometry.location, zoom: 15 });
    setMarkerPosition(place.geometry.location);
    const newlat = {
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng()
    }
    fetchAddress(newlat,setAddressComponents,setLocationState,setCameraProps,setMarkerPosition);
  };
  const emptynull = () => {
    setMarkerPosition(null);
    setAddressComponents((prevstate)=>({
      ...prevstate,street:'', country: "",city: "",zipcode: "",state: "",latitude: "",longitude: ""
    }));

  }
  const back1 = async (show,back) => {
    if(back === 'back1'){
      emptynull();
      await fetchLocation(); 
      if (latitude && longitude) {
        setCameraProps({
          center: { lat: latitude, lng: longitude },
          zoom: 10
        });
      }
    }else if(back === 'profile'){
      navigate(`/u/${login_user}/profile`);
    }
    showstate(show)
  };   
    useEffect(() => {
      const updateDivHeight = () => {
        setDivHeight(window.innerHeight - 160);
      };
      window.addEventListener("resize", updateDivHeight);
      return () => {
        window.removeEventListener("resize", updateDivHeight);
      };
    }, []);
    return(
        <>
        <div>
        <div className="container-fluid" style={{ height: '100px' }}>
          <Navbars/>
        </div>
        <div className="address_div">
          <div className="address_div1" style={{height:divHeight}}>
          <div className="address_divhalf1">
          {locationState.searchLocation && (
              <>

                <div className="d-flex align-items-center">
                  <img src={back} onClick={()=>back1('','profile')} style={{cursor:'pointer'}} className="me-2" alt="back" width="17px" height="14px" />
                  <h6 className="addaddressh6">Add Address</h6>
                </div>
                <div>
                  <p className="addaddressSearchp">Search Location</p>
                  <button onClick={handleUseCurrentLocation} className="addaddresscurrentbutton">
                    <img src={locationcurrent} className="locationcurrent" alt="upload" />
                    Use my current location
                  </button>
                  <PlaceAutocomplete onPlaceSelect={handlePlaceSelect} />
                </div>
                </>
                )}
                  {locationState.searchedLocation && (
            <Updatesearchedlocation
              addressComponents={addressComponents}
              confirmAndProceed={showstate}
              back1={back1}
            />
           )}
           {locationState.searchedLocationconfirm && (
            <Updatesearchlocationconfirm
            addressComponents={addressComponents}
            back1={back1}
            confirmAndProceed1={showstate}
            formValues={formValues}
            setFormValues={setFormValues}
            error={error}
            setError={setError}
            countryData={countryData}
                setMobileChange={setMobileChange}
                mobileChange={mobileChange}
            />
           )}
           {locationState.searchedLocationstore && (
            <Updatesearchlocationstore
            addressComponents={addressComponents}
                back1={back1}
                formValues={formValues}
                setFormValues={setFormValues}
                countryData={countryData}
                mobileChange={mobileChange}
                login_user={login_user}
            />
           )}
                           </div>
              <div className="address_divhalf2">
              {loading ? (
                    <div className="skeleton-loader1"></div>
                  ) : (
                    <Mapdesign handleMarkerClick={locationState.searchLocation ? handleMarkerClick : ''} selectedPlace={selectedPlace}  markerPosition={markerPosition} cameraProps={cameraProps} handleCameraChange={handleCameraChange}/>
                  )}
            </div>
            </div>
          </div>
          </div>
        
        </>
    );
}
export default WithToast(Updateaddress);