import { fetchAcademicLevelListPending, fetchAcademicLevelListFulfilled, fetchAcademicLevelListRejected } from '../slices/acdamicLevelSlice';
import { HandleCalls } from '../../utils/utils'; 
import { acdamicLevelList } from '../../helper/apiurlhelper';

export const fetchAcademicLevels = (showErrorToast) => async (dispatch, getState) => {
    const { academicLevels } = getState();
    if (academicLevels.academicLevelOptions.length > 0) return;

    try {
        dispatch(fetchAcademicLevelListPending());
        const response = await HandleCalls({ apiCall:acdamicLevelList, showErrorToast });

        if (response.status === 200) {
            const mappedRelations = response.data.data;
            const value = mappedRelations.map(company => ({ value: company.id, label: company.name }))
            dispatch(fetchAcademicLevelListFulfilled(value));
        }
    } catch (error) {
        dispatch(fetchAcademicLevelListRejected(error.response?.data || error.message));
    }
};
